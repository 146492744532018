import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { PlayerDto } from '../../../types/dtos.types';
import { Server } from '../../../server/server';
import { GroupContext } from '../../../App';
import PlayerItem from './player-item/player-item.components';
import RowContainer from '../../../container/row-container/row.container';
import Spinner from '../../../components/spinner/spinner-component';
import Pagination from '../../../components/pagination/pagination.component';

const GroupPlayers = () => {
  const { t } = useTranslation();
  const { groupId } = useContext(GroupContext);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [userResponse, setUserResponse] = useState<{
    users: PlayerDto[];
    count: number;
  }>(null as any);
  const [listItems, setListItems] = useState<{ [playerId: string]: boolean }>(
    {}
  );
  const [paginationOffset, setPaginationOffset] = useState<number>(0);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    const pagination = { take: 10, offset: paginationOffset };
    const userFetch = async () => {
      setLoading(true);
      const usersResponse = await Server.user.getUsersFromGroupWithPage(
        groupId,
        pagination
      );
      setUserResponse(usersResponse);
      setLoading(false);
    };

    userFetch();
  }, [groupId, paginationOffset, reload]);

  const onListItem = (playerId: string, isOpen: boolean) => {
    setListItems({ [playerId]: isOpen });
  };

  return (
    <div className="settings-tab-container">
      <h2 className="sidebar-link regular-font text-center pt-2 mt-3">
        {t('titles.all_players_group')}
      </h2>
      <RowContainer>
        {!isLoading ? (
          <div className="mt-3">
            {userResponse?.count > 0 ? (
              userResponse.users.map((x) => {
                return (
                  <PlayerItem
                    key={x.id}
                    player={x}
                    listItems={listItems}
                    onListItem={onListItem}
                    reload={reload}
                    setReload={setReload}
                  />
                );
              })
            ) : (
              <div className="text-center mt-3">
                <h1>
                  <i className="bi bi-emoji-neutral-fill"></i>
                </h1>
                <p>{t('input_fields.no_players')}</p>
              </div>
            )}
            {!isLoading && userResponse?.count >= 10 && (
              <Pagination
                gameCount={userResponse.count}
                paginationOffset={paginationOffset}
                setPaginationOffset={setPaginationOffset}
              />
            )}
          </div>
        ) : (
          <Spinner />
        )}
      </RowContainer>
    </div>
  );
};

export default GroupPlayers;
