import { IsNotEmpty, IsNumber, Min } from 'class-validator';
import { t } from 'i18next';

export class SetScoreValidation {
  @IsNotEmpty({ groups: ['team1Goals'] })
  @IsNumber(undefined, {
    message: () => {
      return t('validation.is_number_string');
    },
    groups: ['team1Goals'],
  })
  @Min(0, {
    message: () => {
      return t('validation.is_positive');
    },
    groups: ['team1Goals'],
  })
  public team1Goals: number | undefined;

  @IsNotEmpty({ groups: ['team2Goals'] })
  @IsNumber(undefined, {
    message: () => {
      return t('validation.is_number_string');
    },
    groups: ['team2Goals'],
  })
  @Min(0, {
    message: () => {
      return t('validation.is_positive');
    },
    groups: ['team2Goals'],
  })
  public team2Goals: number | undefined;

  /*  @IsOptional()
  @IsString()
  public description: string | undefined; */
}
