import { LocationDto } from '../../../../../types/dtos.types';

interface AccordionLocationDataProps {
  location: LocationDto;
}

const AccordionLocationData = (props: AccordionLocationDataProps) => {
  const location = props.location;

  return (
    <div className="container-fluid header-result container-fluid d-flex  align-items-center">
      <div className="p-2 flex-grow-1 breadcrumb-title d-flex align-items-center">
        <span>{location.name}</span>
      </div>
    </div>
  );
};

export default AccordionLocationData;
