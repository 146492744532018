import { useEffect, useState } from 'react';
import { dateFormatter } from '../../../utilities/data/date-formatter/date-formatter';
import FluidContainer from '../../../container/fluid-container/fluid.container';
import { GameWithUserGamesDto } from '../../../types/dtos.types';
import { SetTeamResultColorScheme } from '../../../utilities/data/set-color-scheme/set-team-result-color-scheme';
import { useTranslation } from 'react-i18next';
import './pending-game.component.css';
import * as io from 'socket.io-client';
import '../../../components/spinner/spinner-component.css';
import BettingComponent from './betting.component';
import { Teams } from '../../../types/enums.types';
import Team1List from '../../../components/list-components/team1-list.component';
import Team2List from '../../../components/list-components/team2-list.component';

interface GameComponentProps {
  game: GameWithUserGamesDto;
  gameStatus: string;
}

const GameComponent = (props: GameComponentProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [scoreState, setScoreState] = useState<{
    team1State: string;
    team2State: string;
  }>({ team1State: '', team2State: '' });
  const dateString = dateFormatter(props.game.date);

  const minutesOfPlay =
    (new Date().getTime() - Date.parse(props.game.date)) / 1000 / 60;

  const playersTeam1 = props.game.userGames.filter(
    (player) => player.team === Teams.TEAM1
  );
  const playersTeam2 = props.game.userGames.filter(
    (player) => player.team === Teams.TEAM2
  );
  const team1Goals = props.game.team1Goals;
  const team2Goals = props.game.team2Goals;

  /////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setLoading(true);
    const colorScheme = () => {
      const state = SetTeamResultColorScheme(props.game);
      state && setScoreState(state);
    };
    colorScheme();
    const socket = io.connect('ws://localhost:3000', {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: localStorage.getItem('BearerToken'),
          },
        },
      },
    });
    setLoading(false);
    return () => {
      if (socket.connected) {
        socket.disconnect();
      }
    };
  }, [props.game]);

  return !loading ? (
    <FluidContainer className="g-0 align-items-center justify-content-center bg-component-background my-3 vote-wrapper">
      <div className="col-lg-12 mb-3 px-2">
        <h2 className="fw-bold text-uppercase  fs-5">
          {minutesOfPlay < 0
            ? `${t('dashboard.upcoming')}: ${props.game.type}`
            : `${t('dashboard.result_of_last_game')}: ${props.game.type} `}
        </h2>
        <div className="d-flex justify-content-between align-items-center">
          <div className="game-date px-2 py-1 rounded border border-dark shadow bg-main-backround">
            <span className="date">{`${dateString.dd}.${dateString.mm}.${dateString.yyyy}`}</span>
            <span className="hours">{`${dateString.hh}:${dateString.min}`}</span>
          </div>
          <div
            className={`game-type px-2 py-1 rounded border border-dark shadow game-type game-${props.game.type}`}
          >
            {props.game.type}
          </div>
        </div>
        <div className="location px-2 game-date rounded border border-dark shadow bg-main-backround">
          <i className="bi bi-house px-1"></i>
          <div className="location_text gap-3">
            <h6 className="game-type my-2">{props.game.location.name}</h6>
            <small className="address fw-bold">
              {props.game.location.address}
            </small>
          </div>
        </div>
        <BettingComponent game={props.game} gameStatus={props.gameStatus} />
        <FluidContainer className="mt-4 d-flex align-items-center justify-content-center">
          <Team1List players={playersTeam1}></Team1List>
          <div
            className={`result flex-fill text-center result-${scoreState.team1State}`}
          >
            {team1Goals}
          </div>
          <div className="result-separator">-</div>
          <div
            className={`result flex-fill text-center result-${scoreState.team2State}`}
          >
            {team2Goals}
          </div>
          <Team2List players={playersTeam2}></Team2List>
        </FluidContainer>
      </div>
    </FluidContainer>
  ) : (
    <></>
  );
};

export default GameComponent;
