export enum BiddingChoice {
  FIRST = '1',
  DRAW = 'X',
  SECOND = '2',
}

export enum ResultType {
  TEAM1 = '1',
  DRAW = 'X',
  TEAM2 = '2',
}

export enum OutcomeType {
  WIN = 'WIN',
  DRAW = 'DRAW',
  LOSS = 'LOSS',
}

export enum GameType {
  FUTSAL = 'Futsal',
  CAGEBALL = 'CageBall',
}

export enum PlayerType {
  PLAYER = 'player',
  SUB1 = 'sub1',
  SUB2 = 'sub2',
  SUB3 = 'sub3',
  SUB4 = 'sub4',
  PLAYER1 = 'player1',
  PLAYER2 = 'player2',
  PLAYER3 = 'player3',
  PLAYER4 = 'player4',
  GOALKEEPER = 'goalkeeper',
}

export enum UserType {
  USER = 'user',
  ADMIN = 'admin',
  SUPERADMIN = 'superadmin',
}

export enum Teams {
  TEAM1 = 'team1',
  TEAM2 = 'team2',
  ALL = 'all',
}

export enum Dates {
  COURT_CHANGE = '2023-09-26',
}

export enum ChangeItem {
  TITLE = 'title',
  ADDRESS = 'address',
}

export enum PlayerViewMode {
  COURT = 'court',
  LIST = 'list',
}

export enum GroupActivationType {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
}

export enum CredentialType {
  EMAIL = 'email',
  PASSWORD = 'password',
}
