import FluidContainer from '../../container/fluid-container/fluid.container';
import RowContainer from '../../container/row-container/row.container';
import ButtonMain from '../buttons/button.component';
import './action-message.component.css';

interface ActionMessageProps {
  message?: string;
  type?: string;
  onClick?: () => void;
}

const ActionMessage = (props: ActionMessageProps) => {
  return (
    <FluidContainer>
      <RowContainer>
        <div
          className={`col-md-4 mx-auto text-center bg-component-background my-5 p-3 rounded border shadow border-dark ${props.type}`}
        >
          <div className={`icon-container ${props.type}`}>
            {props.type === 'success' ? (
              <>
                <i className="bi bi-emoji-sunglasses"></i>
              </>
            ) : (
              <>
                <i className="bi bi-emoji-dizzy"></i>
              </>
            )}
          </div>
          <p>{props.message}</p>
          <ButtonMain
            className="btn btn-primary d-flex mx-auto"
            icon="check"
            text="Uredu"
            onClick={props.onClick}
          />
        </div>
      </RowContainer>
    </FluidContainer>
  );
};

export default ActionMessage;
