import { FC } from 'react';

const GameItemWrapper: FC = ({ children }) => {
  return (
    <div className="accordion shadow-sm list-games" id="accordionItem">
      <div className="accordion-item">{children}</div>
    </div>
  );
};

export default GameItemWrapper;
