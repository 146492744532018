import { IsNotEmpty, IsString, MaxLength, MinLength } from 'class-validator';
import { t } from 'i18next';
import { IsEqual } from './decorators/is-equal-to.decorator';

export class ChangePasswordValidation {
  @IsString({
    message: () => {
      return t('validation.is_string');
    },
  })
  @IsNotEmpty({
    message: () => {
      return t('validation.is_not_empty');
    },
    groups: ['newPassword'],
  })
  @MinLength(6, {
    message: () => {
      return t('validation.min_length');
    },
    groups: ['newPassword'],
  })
  @MaxLength(32, {
    message: () => {
      return t('validation.max_length');
    },
    groups: ['newPassword'],
  })
  /*  @Matches(/((?=.*\d)|(?=.*|W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, {
    message: 'Password is too weak...',
  }) */
  public newPassword: string | undefined;

  @IsNotEmpty({
    message: () => {
      return t('validation.is_not_empty');
    },
    groups: ['repeatedNewPassword'],
  })
  @IsEqual('newPassword', {
    message: () => {
      return t('validation.is_equal');
    },
    groups: ['repeatedNewPassword'],
  })
  public repeatedNewPassword: string | undefined;
}
