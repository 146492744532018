import { createContext, useEffect, useMemo, useState } from 'react';
import { Server } from '../../server/server';
import { GameWithUserGamesDto } from '../../types/dtos.types';
import { SelectOptionsProps } from '../../types/props.types';
import EditGameComponent from '../../components/edit-game-component/edit-game-component';
import Games from './games/games.page';

export const EditGameContext = createContext({
  editGame: { displayEditGame: false, editGameId: '' },
  setEditGame: (e: any) => {},
});

const GamePanel = () => {
  const [editGame, setEditGame] = useState<{
    displayEditGame: boolean;
    editGameId: string;
  }>({ displayEditGame: false, editGameId: '' });

  const [editGameOptions, setEditGameOptions] = useState<{
    game: GameWithUserGamesDto | undefined;
    userList: SelectOptionsProps[] | undefined;
  }>();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const editGameCtx = useMemo(() => ({ editGame, setEditGame }), [editGame]);

  /////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const editGameDataFetch = async () => {
      if (editGame.displayEditGame) {
        setIsLoading(true);
        const gameResponse = await Server.game.getGameWithUserGames(
          editGame.editGameId
        );
        const userListResponse = await Server.user.getAll();
        const userListOptions = userListResponse.map((x, i) => {
          return { value: i.toString(), text: x.name, id: x.id };
        });
        setEditGameOptions({
          game: gameResponse,
          userList: [...userListOptions],
        });
        setIsLoading(false);
      }
    };
    editGameDataFetch();
  }, [editGame]);

  const handleEditGame = () => {
    setEditGameOptions({ game: undefined, userList: undefined });
    setEditGame({ displayEditGame: false, editGameId: '' });
    setIsLoading(true);
  };

  return (
    <>
      <EditGameContext.Provider value={editGameCtx}>
        {!editGame.displayEditGame ? (
          <Games />
        ) : (
          !isLoading && (
            <EditGameComponent
              game={editGameOptions?.game!}
              userList={editGameOptions?.userList!}
              handleSubmitEditGame={handleEditGame}
              cancelButton={{ display: true, handleClick: handleEditGame }}
            />
          )
        )}
      </EditGameContext.Provider>
    </>
  );
};

export default GamePanel;
