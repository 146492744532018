import { GroupDto } from '../../../../../../../types/dtos.types';

interface AccordionGroupDataProps {
  group: GroupDto;
}

const AccordionGroupData = (props: AccordionGroupDataProps) => {
  const group = props.group;

  return (
    <div className="container-fluid header-result container-fluid d-flex  align-items-center">
      <div className="p-2 flex-grow-1 breadcrumb-title d-flex align-items-center">
        <span>{group.name}</span>
      </div>
    </div>
  );
};

export default AccordionGroupData;
