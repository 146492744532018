import { useState } from 'react';
import { Server } from '../../../../../server/server';
import { GroupDto, PlayerDto } from '../../../../../types/dtos.types';
import GroupItemWrapper from '../../../../../container/group-item-container/group-item-container';
import AccordionList from './accordion-list/accordion-list.component';
import AccordionGroupData from './accordion-header/group-data/accordion-group-data.component';
import { SelectOptionsProps } from '../../../../../types/props.types';

interface GroupItemProps {
  group: GroupDto;
  isDisabledCollapse?: boolean;
  listItems?: { [groupId: string]: boolean };
  userList: SelectOptionsProps[];
  onListItem?: (groupId: string, isOpen: boolean) => void;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

const GroupItem = (props: GroupItemProps) => {
  const group = props.group;
  const className = props.listItems?.[group.id] ? `show` : `hide`;
  const activeState = (props.listItems && props.listItems?.[group.id]) || false;
  const [isLoading, setLoading] = useState(true);
  const [players, setPlayers] = useState<PlayerDto[]>([]);

  const accordionHandler = () => {
    const getPlayersFromGroup = async () => {
      setLoading(true);
      const players = await Server.user.getUsersFromGroup(group.id);

      setPlayers(players);
      setLoading(false);
    };

    if (!activeState && props.onListItem) {
      props.onListItem(group.id, true);
      getPlayersFromGroup();
    }
    if (activeState && props.onListItem) {
      props.onListItem(group.id, false);
    }
  };

  return (
    <GroupItemWrapper>
      <h2 className="accordion-header" id="headingOne">
        <button
          className={`accordion-button px-2 ${!activeState ? 'collapsed' : ''}`}
          disabled={false}
          onClick={accordionHandler}
        >
          <AccordionGroupData group={group} />
        </button>
      </h2>
      {!isLoading && (
        <>
          <AccordionList
            className={className}
            groupId={group.id}
            groupName={group.name}
            players={players}
            userList={props.userList}
            setPlayers={setPlayers}
            reload={props.reload}
            setReload={props.setReload}
          ></AccordionList>
        </>
      )}
    </GroupItemWrapper>
  );
};

export default GroupItem;
