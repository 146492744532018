import { GameWithUserGamesDto } from '../../../types/dtos.types';
import { GameType, OutcomeType, ResultType } from '../../../types/enums.types';

export const calculateGameStatistics = (
  data: { count: number; games: GameWithUserGamesDto[] },
  players: string[][]
) => {
  const games = data.games;

  let teamLeftPlayers = players[0];
  let teamRightPlayers = players[1];

  const returnData = {
    gamesPlayed: data.count,
    gamesCageBall: games.filter((x) => x.type === GameType.CAGEBALL).length,
    gamesFutsal: games.filter((x) => x.type === GameType.FUTSAL).length,
    gamesWonCageBall: 0,
    gamesLostCageBall: 0,
    gamesDrawCageBall: 0,
    gamesWonFutsal: 0,
    gamesLostFutsal: 0,
    gamesDrawFutsal: 0,
    mvpCountLeft: 0,
    mvpCountRight: 0,
  };

  for (const game of games) {
    if (
      game.type === GameType.CAGEBALL &&
      game.userGames.find((x) => x.userId === teamLeftPlayers[0])?.state ===
        OutcomeType.WIN
    ) {
      returnData.gamesWonCageBall++;
    } else if (
      game.type === GameType.CAGEBALL &&
      game.userGames.find((x) => x.userId === teamLeftPlayers[0])?.state ===
        OutcomeType.LOSS
    ) {
      returnData.gamesLostCageBall++;
    } else if (
      game.type === GameType.CAGEBALL &&
      game.userGames.find((x) => x.userId === teamLeftPlayers[0])?.state ===
        OutcomeType.DRAW
    ) {
      returnData.gamesDrawCageBall++;
    }
    if (
      game.type === GameType.FUTSAL &&
      game.userGames.find((x) => x.userId === teamLeftPlayers[0])?.state ===
        OutcomeType.WIN
    ) {
      returnData.gamesWonFutsal++;
    } else if (
      game.type === GameType.FUTSAL &&
      game.userGames.find((x) => x.userId === teamLeftPlayers[0])?.state ===
        OutcomeType.LOSS
    ) {
      returnData.gamesLostFutsal++;
    } else if (
      game.type === GameType.FUTSAL &&
      game.userGames.find((x) => x.userId === teamLeftPlayers[0])?.state ===
        OutcomeType.DRAW
    ) {
      returnData.gamesDrawFutsal++;
    }
    if (teamLeftPlayers.length && teamLeftPlayers.includes(game.gameMVP)) {
      returnData.mvpCountLeft++;
    } else if (
      teamRightPlayers.length &&
      teamRightPlayers.includes(game.gameMVP)
    ) {
      returnData.mvpCountLeft++;
    }
  }

  return returnData;
};

export const calculateWhoWon = (
  game: GameWithUserGamesDto,
  players: string[][] | undefined
) => {
  let teamLeftPlayers = players?.[0];
  if (
    game.userGames.find((x) => x.userId === teamLeftPlayers?.[0])?.state ===
    OutcomeType.WIN
  ) {
    return ResultType.TEAM1;
  } else if (
    game.userGames.find((x) => x.userId === teamLeftPlayers?.[0])?.state ===
    OutcomeType.LOSS
  ) {
    return ResultType.TEAM2;
  } else return ResultType.DRAW;
};
