import { useContext, useEffect, useState } from 'react';
import InputField from '../../../components/inputs/input-field.component';
import ActionMessage from '../../../components/action-message/action-message.component';
import SelectField from '../../../components/inputs/select.component';
import ButtonMain from '../../../components/buttons/button.component';
import RowContainer from '../../../container/row-container/row.container';
import {
  gameTypeCreateDefault,
  locationCreateDefault,
} from '../../../constants/variables/create-game-defaults.constant';
import { SelectOptionsProps } from '../../../types/props.types';
import { Server } from '../../../server/server';
import { useTranslation } from 'react-i18next';
import { GroupContext } from '../../../App';
import { SelectFieldDto } from '../../../types/dtos.types';
import { PlayerType, Teams } from '../../../types/enums.types';
import './add-new-game.css';
import { validateSelectedPlayers } from '../../../utilities/data/validate-new-game-players/validate-selected-players';
import { useCallbackConfirm } from '../../../hooks/useCallbackConfirm';
import ConfirmNavigationPopup from '../../../components/popup/ConfirmNavigationPopup';
import Team1List from '../../../components/list-components/team1-list.component';
import Team2List from '../../../components/list-components/team2-list.component';
import ChoosePossiblePlayers from '../../../components/possible-players/ChoosePossiblePlayers';

const AddNewGame = () => {
  const { groupId } = useContext(GroupContext);
  const { t } = useTranslation();

  const [date, setDate] = useState<string>('');
  const [statusVoting, setStatusVoting] = useState(false);
  const [gameTypeEvent, setGameTypeEvent] = useState<number>(0);
  const [locationEvent, setLocationEvent] = useState<number>(0);
  const [gameOptions, setGameOptions] = useState<{
    userList: SelectOptionsProps[];
    gameTypes: SelectOptionsProps[];
    locations: SelectOptionsProps[];
  }>();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showConfirm, confirmNavigation, cancelNavigation] =
    useCallbackConfirm(showPopup);

  const [players, setPlayers] = useState<SelectFieldDto[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmited, setIsSubmited] = useState(false);

  const playersTeam1 = players.filter((player) => player.team === Teams.TEAM1);
  const playersTeam2 = players.filter((player) => player.team === Teams.TEAM2);

  //////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const addNewGameData = async () => {
      setIsLoading(true);
      const userListResponse = await Server.user.getUsersFromGroup(groupId);
      const gameTypeResponse = await Server.game.getGameTypes();
      const locationResponse = await Server.location.getLocations(groupId);

      const userListOptions = userListResponse.map((x, i) => {
        return { value: i.toString(), text: x.name, id: x.id };
      });
      const gameTypeOptions = gameTypeResponse.map((x, i) => {
        return { value: i.toString(), text: x.type };
      });
      const locationOptions = locationResponse.map((x, i) => {
        return { value: i.toString(), text: x.name };
      });

      setGameOptions({
        userList: userListOptions,
        gameTypes: [gameTypeCreateDefault, ...gameTypeOptions],
        locations: [locationCreateDefault, ...locationOptions],
      });
      setIsLoading(false);
    };

    addNewGameData();
  }, [groupId]);

  useEffect(() => {
    const storedData = localStorage.getItem('addNewGameData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setDate(parsedData.date || '');
      setStatusVoting(parsedData.statusVoting || false);
      setGameTypeEvent(parsedData.gameTypeEvent || 0);
      setLocationEvent(parsedData.locationEvent || 0);
      setPlayers(parsedData.players || []);
    }
  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////
  const handleVoting = () => {
    setStatusVoting(!statusVoting);
  };

  const removePlayer = (id: string, team: Teams) => {
    const newTeam = players.filter((x) => x.id !== id);
    setPlayers(newTeam);

    const selectField = document.getElementById(team) as HTMLSelectElement;
    selectField?.querySelectorAll('option').forEach((x) => {
      if (x.id === id) {
        x.selected = false;
      }
    });
  };

  const handleSelectTeam = (
    selectedOption: {
      value: string;
      label: string;
      id: string | undefined;
    },
    team: Teams
  ) => {
    let allTeamIds;
    let allOtherIds;
    if (team === Teams.TEAM1) {
      allTeamIds = playersTeam1.map((item) => item.id);
      allOtherIds = playersTeam2.map((item) => item.id);
    } else {
      allTeamIds = playersTeam2.map((item) => item.id);
      allOtherIds = playersTeam1.map((item) => item.id);
    }
    if (!allTeamIds.includes(selectedOption.id)) {
      if (allOtherIds.includes(selectedOption.id)) {
        return;
      }
      const option = {
        name: selectedOption.label,
        id: selectedOption.id,
        team: team,
        role: PlayerType.PLAYER,
      };
      setPlayers([...players, option]);
    } else {
      const newSelectedPlayers = players.filter(
        (p) => p.id !== selectedOption.id
      );
      setPlayers(newSelectedPlayers);
    }
  };

  const isSelectedInTeam = (id?: string, team?: Teams.TEAM1 | Teams.TEAM2) => {
    if (team) {
      let allSelectedIds;
      if (team === Teams.TEAM1) {
        allSelectedIds = playersTeam1.map((item) => item.id);
      } else {
        allSelectedIds = playersTeam2.map((item) => item.id);
      }
      if (id && allSelectedIds.includes(id)) {
        return true;
      } else return false;
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleSubmitNewGame = async () => {
    setIsLoading(true);
    if (!players.length || !gameTypeEvent || !locationEvent || !date) {
      alert(t('alerts.fill_all_fields'));
    } else {
      if (validateSelectedPlayers(players)) {
        const gameType = gameOptions?.gameTypes[gameTypeEvent].text;
        const location = gameOptions?.locations[locationEvent].text;
        const gameDate = date;
        const userGames = players.map((player) => {
          return { userId: player.id, team: player.team, role: player.role };
        });
        await Server.game.createGameWithUserGames(
          {
            date: gameDate,
            groupId: groupId,
            type: gameType!,
            locationName: location!,
            statusVoting,
          },
          userGames
        );
        localStorage.removeItem('addNewGameData');
        setIsLoading(false);
        setIsSubmited(true);
      }
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////
  const saveNewGameData = () => {
    const dataToStore = {
      date,
      statusVoting,
      gameTypeEvent,
      locationEvent,
      players,
    };
    localStorage.setItem('addNewGameData', JSON.stringify(dataToStore));
  };

  return !isSubmited ? (
    <>
      <ConfirmNavigationPopup
        showPopup={showConfirm}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
        saveNewGameData={saveNewGameData}
      />
      <div className="settings-tab-container">
        <h2 className="sidebar-link text-center regular-font text-start pt-2 mb-4">
          {t('titles.create')}
        </h2>
        <RowContainer className="col-md-12">
          <div className="new_game_input">
            <div className="col-md-4">
              <InputField
                label={t('input_fields.date_and_time')}
                type="datetime-local"
                name="date"
                onChange={(e) => {
                  setDate(e.target.value);
                  setShowPopup(true);
                }}
                value={date}
              />
            </div>
            <div className="col-md-4">
              {gameOptions && (
                <SelectField
                  label={t('input_fields.game_type')}
                  options={gameOptions.gameTypes}
                  eventHandler={(e: any) => {
                    setGameTypeEvent(e.target.options.selectedIndex);
                    setShowPopup(true);
                  }}
                  value={gameOptions.gameTypes[gameTypeEvent].value}
                ></SelectField>
              )}
            </div>
            <div className="col-md-4">
              {gameOptions && (
                <SelectField
                  label={t('input_fields.location')}
                  options={gameOptions.locations}
                  eventHandler={(e: any) => {
                    setLocationEvent(e.target.options.selectedIndex);
                    setShowPopup(true);
                  }}
                  value={gameOptions.locations[locationEvent].value}
                ></SelectField>
              )}
            </div>
          </div>
          <div className="player_select my-3">
            <div className="col-md-5 mb-2">
              <ChoosePossiblePlayers
                title={`${t('teams.team')} ${t('teams.team_1_color')}`}
                userList={gameOptions?.userList ? gameOptions.userList : []}
                handleSelectByTeam={handleSelectTeam}
                team={Teams.TEAM1}
                isSelectedInTeam={isSelectedInTeam}
              />
            </div>
            <div className="col-md-5">
              <ChoosePossiblePlayers
                title={`${t('teams.team')} ${t('teams.team_2_color')}`}
                userList={gameOptions?.userList ? gameOptions.userList : []}
                handleSelectByTeam={handleSelectTeam}
                team={Teams.TEAM2}
                isSelectedInTeam={isSelectedInTeam}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-md-5">
              {playersTeam1.length ? (
                <div className="d-flex">
                  <h2 className="sidebar-link regular-font text-center pt-2">
                    {`${t('teams.team')} ${t('teams.team_1_color')}`}
                  </h2>
                </div>
              ) : (
                ''
              )}
              <Team1List
                players={playersTeam1}
                icon={'bi-x-octagon-fill'}
                onClick={(e) => removePlayer(e, Teams.TEAM1)}
              ></Team1List>
            </div>
            <div className="col-md-5">
              {playersTeam2.length ? (
                <div className="d-flex">
                  <h2 className="sidebar-link regular-font text-center pt-2">
                    {`${t('teams.team')} ${t('teams.team_2_color')}`}
                  </h2>
                </div>
              ) : (
                ''
              )}
              <Team2List
                players={playersTeam2}
                icon={'bi-x-octagon-fill'}
                onClick={(e) => removePlayer(e, Teams.TEAM2)}
              ></Team2List>
            </div>
          </div>

          <div className="bottom_row">
            <div className="form-check form-switch mt-3">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={statusVoting}
                onChange={handleVoting}
              />
              <label className="form-check-label">
                {t('input_fields.voting')}
              </label>
            </div>
            <div>
              <ButtonMain
                className="btn btn-primary d-flex mt-3 w-100 h-100"
                icon=""
                text={t('input_fields.button_submit')}
                onClick={handleSubmitNewGame}
                disabled={isLoading}
              />
            </div>
          </div>
        </RowContainer>
      </div>
    </>
  ) : (
    <ActionMessage
      message={t('input_fields.response_success_add_game')}
      type={'success'}
      onClick={() => {
        setPlayers([]);
        setDate('');
        setStatusVoting(false);
        setGameTypeEvent(0);
        setLocationEvent(0);
        setIsSubmited(false);
        setShowPopup(false);
      }}
    />
  );
};

export default AddNewGame;
