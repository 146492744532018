import Dashboard from '../../pages/dashboard/dashboard.page';
import Players from '../../pages/players/players.page';
import Profile from '../../pages/profile/profile.page';
import Administration from '../../pages/admin/admin-panel.page';
import Settings from '../../pages/settings/settings.page';
import AddNewGame from '../../pages/admin/add-new-game/add-new-game';
import AddNewPlayer from '../../pages/admin/add-new-player/add-new-player';
import AddRuleBook from '../../pages/admin/add-rule-book/add-rule-book';
import RuleBook from '../../pages/rulebook/rulebook.page';
import PersonalInfo from '../../pages/settings/personal-info/personal-info';
import EditGame from '../../pages/admin/edit-game/edit-game';
import ChangeCredentials from '../../pages/settings/change-credentials/change-credentials';
import GamePanel from '../../pages/games/game-panel';
import ComparePlayers from '../../pages/compare/compare-players.component';
import Groups from '../../pages/admin/superadmin/groups/Groups';
import Superadmin from '../../pages/admin/superadmin/superadmin-panel.page';
import SuperadminPlayers from '../../pages/admin/superadmin/players/players';
import GroupPlayers from '../../pages/admin/players/players';
import AddNewLocation from '../../pages/admin/add-new-location/add-new-location';
import { CredentialType } from '../../types/enums.types';

export const RoutesDefinitions = [
  {
    path: '/dashboard',
    element: <Dashboard />,
    authorise: false,
    superadmin: false,
  },
  {
    path: '/games',
    element: <GamePanel />,
    authorise: false,
    superadmin: false,
  },
  {
    path: '/players',
    element: <Players />,
    authorise: false,
    superadmin: false,
  },
  {
    title: 'compare',
    path: '/compare',
    icon: 'bi bi-pie-chart-fill',
    element: <ComparePlayers />,
    authorise: false,
    superadmin: false,
  },
  {
    path: '/profile',
    element: <Profile />,
    authorise: false,
    superadmin: false,
  },
  {
    path: '/settings',
    element: <Settings />,
    authorise: false,
    superadmin: false,
    subroutes: [
      {
        path: '',
        element: <PersonalInfo />,
      },
      {
        path: 'change-email',
        element: <ChangeCredentials typeOfChange={CredentialType.EMAIL} />,
      },
      {
        path: 'change-password',
        element: <ChangeCredentials typeOfChange={CredentialType.PASSWORD} />,
      },
    ],
  },
  {
    path: '/rulebook',
    element: <RuleBook />,
    authorise: false,
    superadmin: false,
  },
  {
    path: '/admin',
    element: <Administration />,
    authorise: true,
    superadmin: false,
    subroutes: [
      {
        path: '',
        element: <EditGame />,
      },
      {
        path: 'players',
        element: <GroupPlayers />,
      },
      {
        path: 'locations',
        element: <AddNewLocation />,
      },
      {
        path: 'add-game',
        element: <AddNewGame />,
      },
      {
        path: 'add-player',
        element: <AddNewPlayer />,
      },

      {
        path: 'add-rules',
        element: <AddRuleBook />,
      },
    ],
  },
  {
    path: '/superadmin',
    element: <Superadmin />,
    authorise: false,
    superadmin: true,
    subroutes: [
      {
        path: '',
        element: <Groups />,
      },
      {
        path: 'players',
        element: <SuperadminPlayers />,
      },
    ],
  },
];
export const AdminPanelData = [
  {
    title: 'overview',
    path: '',
  },
  {
    title: 'players',
    path: '/admin/players',
  },
  {
    title: 'locations',
    path: '/admin/locations',
  },
  {
    title: 'add_new_game',
    path: '/admin/add-game',
  },
  {
    title: 'add_new_player',
    path: '/admin/add-player',
  },

  {
    title: 'add_rules',
    path: '/admin/add-rules',
  },
];

export const SuperadminPanelData = [
  {
    title: 'groups',
    path: '',
  },
  {
    title: 'players',
    path: '/superadmin/players',
  },
];

export const PersonalSettingsData = [
  {
    title: 'personal_info',
    path: '',
  },
  {
    title: 'change_email',
    path: '/settings/change-email',
  },
  {
    title: 'change_password',
    path: '/settings/change-password',
  },
];

export const SideBarLinks = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: 'bi bi-grid-3x3-gap-fill',
    element: <Dashboard />,
    authorise: false,
    superadmin: false,
  },
  {
    title: 'games',
    path: '/games',
    icon: 'bi bi-bookmarks-fill',
    element: <GamePanel />,
    authorise: false,
    superadmin: false,
  },
  {
    title: 'players',
    path: '/players',
    icon: 'bi bi-people-fill',
    element: <Players />,
    authorise: false,
    superadmin: false,
  },
  {
    title: 'compare',
    path: '/compare',
    icon: 'bi bi-pie-chart-fill',
    element: <ComparePlayers />,
    authorise: false,
    superadmin: false,
  },
  {
    title: 'admin',
    path: '/admin',
    icon: 'bi bi-gear-fill',
    element: <Administration />,
    authorise: true,
    superadmin: false,
    subroutes: [
      {
        path: '',
        element: <EditGame />,
      },
      {
        path: 'players',
        element: <GroupPlayers />,
      },
      {
        path: 'locations',
        element: <AddNewLocation />,
      },
      {
        path: 'add-game',
        element: <AddNewGame />,
      },
      {
        path: 'add-player',
        element: <AddNewPlayer />,
      },

      {
        path: 'add-rules',
        element: <AddRuleBook />,
      },
    ],
  },
  {
    title: 'superadmin',
    path: '/superadmin',
    icon: 'bi bi-gear-wide-connected',
    element: <Superadmin />,
    authorise: false,
    superadmin: true,
    subroutes: [
      {
        path: '',
        element: <Groups />,
      },
      {
        path: 'players',
        element: <SuperadminPlayers />,
      },
    ],
  },
];
