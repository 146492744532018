import './button-spinner.component.css';

const ButtonSpinner = () => {
  return (
    <div className="lds-ripple-sm">
      <div></div>
      <div></div>
    </div>
  );
};

export default ButtonSpinner;
