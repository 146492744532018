import './players-table.component.css';
import TableBody from './body/table-body.component';
import TableHeader from './header/table-header.component';
import { useContext, useEffect, useState } from 'react';
import { UserWithStatsDto } from '../../../types/dtos.types';
import Spinner from '../../../components/spinner/spinner-component';
import { Server } from '../../../server/server';
import PlayersFilter from '../players-filter/players-filter.component';
import { todayMinusMonth } from '../../../utilities/data/date-formatter/today-minus-month';
import { GroupContext } from '../../../App';
import { useTranslation } from 'react-i18next';
import { getLoggedUser } from '../../../utilities/local-storage/get-logged-user';
import Banned from '../../login/banned.component';

const PlayersTable = () => {
  const { t } = useTranslation();
  const { groupId } = useContext(GroupContext);
  const user = getLoggedUser();
  const [isBannedFromGroup, setIsBannedFromGroup] = useState<boolean>();
  const [banExp, setBanExp] = useState<Date | null | undefined>();
  const [users, setUsers] = useState<UserWithStatsDto[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [playerFilter, setPlayerFilter] = useState<{
    date: string;
    dateTo: string;
  }>({
    date: todayMinusMonth(),
    dateTo: new Date().toISOString().split('T')[0],
  });

  useEffect(() => {
    const fetchingUsersWithStatsData = async () => {
      setIsLoading(true);
      const usersResponse = await Server.user.getUsersWithStatistics(
        playerFilter,
        groupId
      );
      setUsers(usersResponse.sort((a, b) => b.gamesWon - a.gamesWon));
      setIsLoading(false);
    };
    const fetchingBanStatus = async (userId: string, groupId: string) => {
      const userGroup = await Server.userGroup.getUserGroupByGroupId(
        userId,
        groupId
      );
      if (userGroup.isBanned) {
        const now = new Date();
        if (userGroup.banExpiration) {
          const expiration = new Date(userGroup.banExpiration);
          if (now > expiration) {
            await Server.userGroup.handleBanFromGroup(user.id, groupId, false);
            setIsBannedFromGroup(false);
            setBanExp(null);
          }
        }
      }
      setIsBannedFromGroup(userGroup.isBanned);
      setBanExp(userGroup.banExpiration);
    };

    if (groupId) {
      fetchingUsersWithStatsData();
      fetchingBanStatus(user.id, groupId);
    }
  }, [groupId, playerFilter, user.id]);

  return groupId ? (
    !isBannedFromGroup ? (
      !isLoading ? (
        <>
          <div>
            <PlayersFilter
              playerFilter={playerFilter}
              setPlayerFilter={setPlayerFilter}
              buttonDisabled={isLoading}
            />
          </div>
          <div className="table-responsive">
            <table className="table table-dark table-striped rounded-3">
              {users && <TableHeader setUsers={setUsers} users={users} />}
              <tbody>
                {users?.map((user) => {
                  return <TableBody key={user.id} user={user} />;
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <Spinner />
      )
    ) : (
      <Banned banExp={banExp} buttonShow={false} />
    )
  ) : (
    <div className="container-fluid d-flex justify-content-center align-items-center">
      <div className="login-wrapper bg-component-background p-5 px-5 border shadow-sm border-dark rounded position-relative">
        <h1 className="text-center login-title mb-4">
          {t('players.no_groups')}
        </h1>
        <div className="text-center mb-4">
          <h4>{t('players.no_groups_text')}</h4>
        </div>
      </div>
    </div>
  );
};

export default PlayersTable;
