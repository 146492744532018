import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'hr',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          input_fields: {
            Name: 'Name',
            Email: 'E-mail',
            from: 'From',
            to: 'To',
            date: 'Date',
            time: 'Time',
            game_type: 'Game type',
            name: 'Name',
            email: 'Email',
            players: 'Players',
            player_1: 'Player 1',
            player_2: 'Player 2',
            played_as: 'Played',
            team_1: 'Team 1',
            team_2: 'Team 2',
            newEmail: 'New e-mail',
            repeatedNewEmail: 'Repeat e-mail',
            newPassword: 'New password',
            repeatedNewPassword: 'Repeat password',
            button_submit: 'Submit',
            button_cancel: 'Cancel',
            button_save: 'Save',
            button_nosave: 'Do not save',
            button_edit: 'Edit',
            telephone_number: 'Telephone number',
            response_success_change: 'Successfully changed',
            date_and_time: 'Date and time',
            add_game_retroactively: 'Add game retroactively',
            resetBets: 'Reset Bets',
            response_success_add_game: 'Successfully added game',
            response_success_add_group: 'Successfully added group',
            response_success_add_player: 'Successfully added player',
            response_success_add_location: 'Successfully added location',
            response_success_edit_player: 'Successfully edited player',
            response_success_set_score: 'Successfully set score',
            response_success_delete_game: 'Successfully deleted game',
            response_success_edit_game: 'Successfully edited game',
            response_expired_time: 'Bidding time has expired',
            dateOfBirth: 'Date of birth',
            adress: 'Adress',
            city: 'City',
            country: 'Country',
            location: 'Location',
            gender: 'Gender',
            gender_Male: 'Male',
            gender_Female: 'Female',
            voting: 'Voting',
            all: 'ALL',
            no_groups: 'No groups',
            no_players: 'No players',
            add_player: 'Add player',
            choose_admin: 'Choose admin',
            search: 'Search...',
            select: 'Select...',
          },
          actions: {
            created: 'New:',
            updated: 'Update:',
            delete: 'Delete',
            deleted: 'Deleted:',
          },
          login: {
            login_title: 'Login',
            email_label: 'Email',
            password_label: 'Password',
            login_button: 'Login',
            logout_button: 'Logout',
            banned: 'Banned',
            banned_text:
              'Administrator banned you from logging in. You are banned until: ',
          },
          menu_items: {
            dashboard: 'Dashboard',
            games: 'Games',
            players: 'Players',
            profile: 'Profile',
            settings: 'Settings',
            rulebook: 'Rulebook',
            compare: 'Statistics',
            admin: 'Admin',
            superadmin: 'Superadmin',
          },
          dashboard: {
            upcoming: 'Upcoming',
            result_of_last_game: 'Ongoing',
            general_stats: 'General stats',
            played_games: 'Played games',
            average_goals: 'Average goals',
            average_wins: 'Average wins',
            average_score_diff: 'Average score difference',
            average_games_diff: 'Average games difference',
            no_consensus_reached: 'No consensus reached',
            not_enough_votes: 'Not enough votes',
            mvp: 'Most valuable player (MVP)',
            voting_not_started: 'Voting has not started',
            voting_finished: 'Voting is finsihed',
            roster: 'Roster',
            vote_window_open: 'Vote window open',
            result_entered: 'Result entered is',
            are_you_sure: 'Are you sure?',
            watching_you: `Domagoj is watching you...`,
            try_again: 'Try again',
            bet: 'Betting',
            betOption1: 'BLACK',
            betOption2: 'DRAW',
            betOption3: 'WHITE',
            betStats: 'Betting stats',
            yourBet: 'You placed your bet on: ',
            totalBets: 'Total bets: ',
            lastGames: 'Last 7 days: ',
            details: 'Details',
          },
          teams: {
            team_1_color: 'Black',
            team_2_color: 'White',
            team: 'Team',
            replace_player_with: 'Replace player with',
            team_result: 'Result',
            team1: 'Team 1',
            team2: 'Team 2',
          },
          games: {
            no_games_found: 'No games found',
            found: 'Found',
            filter_title: 'Filter',
            filter: 'Filter',
            remove_filters: 'Remove filters',
            game_description: 'Game description',
            together: 'Together',
            against: 'Against',
          },
          players: {
            player: 'Player',
            admin: 'Admin',
            user: 'User',
            all_games: 'All games',
            futsal: 'Futsal',
            cageball: 'Cageball',
            goalkeeper: 'Goalkeeper',
            mvp: 'MVP',
            profile_button: 'Profile',
            betting: 'Betting results',
            ban: 'Ban player',
            cancel_ban: 'Allow',
            ban_until: 'User banned until',
            no_groups: 'No groups',
            no_groups_text: 'You are currently not a member of any group',
            resend: 'Invite',
          },
          locations: {
            no_locations: 'No locations',
            address: 'Address',
            name: 'Name',
          },
          profile: {
            return_button: 'Return',
            sum_games: 'Total games',
            wins: 'Wins',
            losses: 'Losses',
            draws: 'Draws',
            win: 'Win',
            loss: 'Loss',
            draw: 'Draw',
            barrel: 'barrel',
            barrel_and_capacity: 'barrel (50l capacity)',
            won_beer: 'Beer won',
            number_of_wins: 'Number of wins',
            pints_0_5l: 'Pints 0.5l',
            lost_beer: 'Beer lost',
            number_of_losses: 'Number of losses',
            no_data: 'No data',
          },
          tab_items: {
            personal_info: 'Personal info',
            change_password: 'Change password',
            change_email: 'Change email',
            overview: 'Overview',
            add_new_game: 'Add new game',
            edit_player: 'Edit player',
            add_new_player: 'Add new player',
            locations: 'Locations',
            add_new_location: 'Add new location',
            add_rules: 'Add rules',
            groups: 'Groups',
            players: 'Players',
          },
          validation: {
            is_string: 'Please provide valid input!',
            is_not_empty: 'Field is mandatory!',
            is_equal: 'Fields must match!',
            max_length: 'Maximum password length is 32 character',
            min_length: 'Minimum password length is 6 characters',
            is_number_string: 'Please insert valid score',
            is_email: 'Please provide valid email.',
            is_positive: 'Score must be a positive number.',
            user_exists: 'User already exists',
            group_exists: 'Group already exists',
          },
          titles: {
            conclude: 'Conclude game',
            create: 'Create new game',
            edit_player: 'Edit player',
            add_player: 'Add player',
            choose_possible_players: 'Choose possible players',
            add_location: 'Add location',
            edit_rulebook: 'Edit rulebook',
            compare: 'CHOOSE PLAYERS FOR COMPARISON',
            chosen_games: 'CHOSEN GAMES',
            num_of_played: 'GAMES PLAYED:',
            register: 'REGISTER',
            changeEmail: 'Change e-mail',
            changePass: 'Change password',
            add_group: 'Create new group',
            all_groups: 'All groups',
            all_players: 'Players list',
            all_players_group: 'Players from group',
            all_locations: 'Locations',
            general_stats: 'GENERAL STATISTICS',
            player_list: 'PLAYERS',
            save_game: 'Save this game?',
            enter_result: 'Enter game result',
          },
          groups: {
            deactivate: 'Deactivate',
            activate: 'Activate',
            chosen_players: 'Chosen players',
            members: 'Members',
          },
          positions: {
            player1: 'Player 1',
            player2: 'Player 2',
            player3: 'Player 3',
            player4: 'Player 4',
            sub1: 'Sub 1',
            sub2: 'Sub 2',
            sub3: 'Sub 3',
            sub4: 'Sub 4',
            goalkeeper: 'Goalkeeper',
            player: 'Player',
          },
          alerts: {
            fill_all_fields: 'Please fill out all required fields',
            delete_game: 'Are you sure you want to delete the game?',
            delete_location: 'Are you sure you want to delete the location?',
            reset_betting: 'Are you sure you want to reset betting?',
            no_teams_empty:
              'Teams cannot be empty. Please assign players to both teams.',
            remove_from_group:
              'Are you sure you want to remove this user from the group?',
            remove_as_admin:
              'Are you sure you want to remove admin privileges from this user?',
            add_as_admin:
              'Are you sure you want to give admin privileges to this user?',
            add_to_group:
              'Are you sure you want to add this user to the group?',
            user_is_already: 'This user is already',
            in_this_group: 'in this group',
            user_already_here: 'This user is already in this group',
            deactivate_group:
              'Are you sure you want to remove all admins and deactivate this group?',
            cancel_ban: 'Are you sure you want to unban this user?',
            ban: 'Are you sure you want to ban this user for 7 days?',
            fetch_data_error: 'Something went wrong while fetching data',
            bet: 'Are you sure you want to bet on this outcome?',
            vote: 'Are you sure you want to vote for ',
            vote_self: 'You cannot vote for yourself.',
            saved: 'Saved!',
            save_game_text:
              'You are creating a new game. Do you want to save the data before leaving?',
            left_team_empty:
              'You need to select at least one player in Team Left!',
            right_team_empty:
              'You need to select at least one player in Team Right!',
            logout: 'Are you sure you want to log out?',
          },
          errors: {
            login: 'Please log in to continue.',
            check_input: 'Please check your input.',
            no_permission: 'You do not have permission to visit this site.',
            server_timeout: 'Server response timed out',
            something_wrong: 'Something went wrong. Please try again later',
            check_browser: 'Please check your browser.',
            something_died: 'Seems like something died on our side.',
          },
        },
      },
      hr: {
        translation: {
          input_fields: {
            Name: 'Ime',
            Email: 'E-pošta',
            from: 'Od',
            to: 'Do',
            date: 'Datum',
            time: 'Vrijeme',
            game_type: 'Tip igre',
            name: 'Ime',
            email: 'E-pošta',
            players: 'Igrači',
            player_1: 'Igrač 1',
            player_2: 'Igrač 2',
            played_as: 'Igrali su',
            team_1: 'Ekipa 1',
            team_2: 'Ekipa 2',
            newEmail: 'Nova e-pošta',
            repeatedNewEmail: 'Ponovi e-poštu',
            newPassword: 'Nova lozinka',
            repeatedNewPassword: 'Ponovi lozinku',
            button_submit: 'Potvrdi',
            button_cancel: 'Odustani',
            button_save: 'Spremi',
            button_nosave: 'Nemoj spremiti',
            button_edit: 'Uredi',
            phone: 'Broj telefona (nije obavezno)',
            response_success_change: 'Uspješno ste promijenili',
            date_and_time: 'Datum i vrijeme',
            add_game_retroactively: 'Dodaj utakmicu retroaktivno',
            resetBets: 'Resetiraj klađenje',
            response_success_add_game: 'Uspješno ste dodali utakmicu',
            response_success_add_group: 'Uspješno ste dodali grupu',
            response_success_add_player: 'Uspješno ste dodali igrača',
            response_success_add_location: 'Uspješno ste dodali lokaciju',
            response_success_edit_player: 'Uspješno ste izmijenili igrača',
            response_success_set_score: 'Uspješno ste dodali rezultat',
            response_success_delete_game: 'Uspješno ste izbrisali utakmicu',
            response_success_edit_game: 'Uspješno ste uredili utakmicu',
            response_expired_time: 'Vrijeme za glasovanje je isteklo',
            dateOfBirth: 'Datum rođenja',
            adress: 'Adresa',
            city: 'Grad',
            country: 'Država',
            location: 'Lokacija',
            gender: 'Spol',
            gender_male: 'Muškarac',
            gender_female: 'Žena',
            voting: 'Glasanje',
            all: 'SVE',
            no_groups: 'Nema grupa',
            no_players: 'Nema igrača',
            add_player: 'Dodaj novog igrača',
            choose_admin: 'Odaberi admina',
            search: 'Pretraži...',
            select: 'Odaberi...',
          },
          actions: {
            created: 'Novo:',
            updated: 'Promjena:',
            delete: 'Izbriši',
            deleted: 'Izbrisano:',
          },
          login: {
            login_title: 'Prijava',
            email_label: 'E-pošta',
            password_label: 'Šifra',
            login_button: 'Prijavi se',
            logout_button: 'Odjavi se',
            banned: 'Zabrana',
            banned_text:
              'Administrator Vam je zabranio prijavu. Zabrana ističe: ',
          },
          menu_items: {
            dashboard: 'Info',
            games: 'Utakmice',
            players: 'Igrači',
            profile: 'Profil',
            settings: 'Postavke',
            rulebook: 'Pravilnik',
            compare: 'Statistika',
            admin: 'Admin',
            superadmin: 'Superadmin',
          },
          dashboard: {
            upcoming: 'Nadolazeći',
            result_of_last_game: 'U tijeku',
            general_stats: 'Opće statistike',
            played_games: 'Odigrane utakmice',
            average_goals: 'Prosječno golova',
            average_wins: 'Prosječno pobjeda',
            average_score_diff: 'Prosječna razlika golova',
            average_games_diff: 'Prosječna razlika utakmica',
            no_consensus_reached: 'Nije zadovoljen konsenzus',
            not_enough_votes: 'Nema dovoljno glasova',
            mvp: 'Najkorisniji igrač (MVP)',
            voting_not_started: 'Glasanje još nije počelo',
            voting_finished: 'Glasanje je završilo',
            roster: 'Sastav',
            vote_window_open: 'Otvoreno glasanje',
            result_entered: 'Uneseni rezultat je',
            are_you_sure: 'Jesi li siguran?',
            watching_you: `Domagoj te gleda...`,
            try_again: 'Pokušaj ponovo',
            bet: 'Klađenje',
            betOption1: 'CRNO',
            betOption2: 'NERIJEŠENO',
            betOption3: 'BIJELO',
            betStats: 'Analiza klađenja',
            yourBet: 'Kladio si se na: ',
            totalBets: 'Broj oklada: ',
            lastGames: 'Posljednjih 7 dana: ',
            details: 'Detalji',
          },
          teams: {
            team_1_color: 'Crno',
            team_2_color: 'Bijelo',
            team: 'Ekipa',
            replace_player_with: 'Zamijeni igrača sa',
            team_result: 'Rezultat',
            team1: 'Tim 1',
            team2: 'Tim 2',
          },
          games: {
            no_games_found: 'Nema utakmica za prikaz',
            found: 'Pronađeno',
            filter_title: 'Filter',
            filter: 'Filtriraj',
            remove_filters: 'Ukloni',
            game_description: 'Opis utakmice',
            together: 'Zajedno',
            against: 'Jedno protiv drugoga',
          },
          players: {
            player: 'Igrač',
            admin: 'Admin',
            user: 'Korisnik',
            all_games: 'Sve utakmice',
            futsal: 'Futsal',
            cageball: 'Cageball',
            goalkeeper: 'Golman',
            mvp: 'MVP',
            profile_button: 'Profil',
            betting: 'Rezultati klađenja',
            ban: 'Zabrani',
            cancel_ban: 'Poništi zabranu',
            ban_until: 'Zabrana do',
            no_groups: 'Nema grupe',
            no_groups_text: 'Trenutno niste član nijedne grupe',
            resend: 'Pozovi',
          },
          locations: {
            no_locations: 'Nema lokacija',
            address: 'Adresa',
            name: 'Ime',
          },
          profile: {
            return_button: 'Natrag',
            sum_games: 'Ukupno utakmica',
            wins: 'Pobjede',
            losses: 'Porazi',
            draws: 'Neodlučeno',
            win: 'Pobjeda',
            loss: 'Poraza',
            draw: 'Remi',
            barrel: 'bačva',
            barrel_and_capacity: 'bačva (kapacitet:50l)',
            won_beer: 'Osvojene Pive',
            number_of_wins: 'Broj pobjeda',
            pints_0_5l: 'Točena 0.5l',
            lost_beer: 'Izgubljene Pive',
            number_of_losses: 'Broj poraza',
            no_data: 'Nema podataka',
          },
          tab_items: {
            personal_info: 'Osobne informacije',
            change_password: 'Promjena šifre',
            change_email: 'Promjena e-pošte',
            overview: 'Općenito',
            add_new_game: 'Nova utakmica',
            edit_player: 'Izmijeni igrača',
            add_new_player: 'Novi igrač',
            locations: 'Lokacije',
            add_rules: 'Pravila',
            groups: 'Grupe',
            players: 'Igrači',
          },
          validation: {
            is_string: 'Unesite valjanu vrijednost.',
            is_not_empty: 'Polje je obvezno!',
            is_equal: 'Polja se moraju podudarati!',
            max_length: 'Maksimalna veličina šifre je 32 znakova.',
            min_length: 'Minimalna veličina šifre je 6 znakova.',
            is_number_string: 'Unesite valjani rezultat.',
            is_email: 'Unesite valjani email.',
            is_positive: 'Rezultat mora biti pozitivan broj.',
            user_exists: 'Korisnik već postoji',
            group_exists: 'Grupa već postoji',
          },
          titles: {
            conclude: 'Zaključi utakmicu',
            create: 'Kreiraj novu utakmicu',
            edit_player: 'Uredi igrača',
            add_player: 'Dodaj novog igrača',
            choose_possible_players: 'Odaberite moguće igrače',
            add_location: 'Dodaj lokaciju',
            edit_rulebook: 'Uredi knjigu pravila',
            compare: 'ODABERITE IGRAČE ZA USPOREDBU',
            chosen_games: 'LISTA ODABRANIH SUSRETA',
            num_of_played: 'BROJ ODIGRANIH:',
            register: 'REGISTRIRAJ SE',
            changeEmail: 'Promijeni e-poštu',
            changePass: 'Promijeni lozinku',
            add_group: 'Kreiraj novu grupu',
            all_groups: 'Sve grupe',
            all_players: 'Svi igrači',
            all_players_group: 'Igrači u grupi',
            all_locations: 'Lokacije u grupi',
            general_stats: 'GENERALNA STATISTIKA',
            player_list: 'SASTAV',
            save_game: 'Spremiti utakmicu?',
            enter_result: 'Unesite rezultat utakmice',
          },
          groups: {
            deactivate: 'Deaktiviraj',
            activate: 'Aktiviraj',
            chosen_players: 'Odabrani igrači',
            members: 'Članovi',
          },
          positions: {
            player1: 'Igrač 1',
            player2: 'Igrač 2',
            player3: 'Igrač 3',
            player4: 'Igrač 4',
            sub1: 'Zamjena 1',
            sub2: 'Zamjena 2',
            sub3: 'Zamjena 3',
            sub4: 'Zamjena 4',
            goalkeeper: 'Golman',
            player: 'Igrač',
          },
          alerts: {
            fill_all_fields: 'Unesite sva polja',
            delete_game: 'Jeste li sigurni da želite izbrisati igru?',
            delete_location: 'Jeste li sigurni da želite izbrisati lokaciju?',
            reset_betting: 'Jeste li sigurni da želite resetirati klađenje?',
            no_teams_empty:
              'Timovi ne mogu biti prazni. Unesite igrače za oba tima.',
            remove_from_group:
              'Jeste li sigurni da želite ukloniti ovog korisnika iz grupe?',
            remove_as_admin:
              'Jeste li sigurni da želite ukloniti ovog korisnika kao admina u ovoj grupi?',
            add_as_admin:
              'Jeste li sigurni da želite dodati ovog korisnika kao admina u ovoj grupi?',
            add_to_group:
              'Jeste li sigurni da želite dodati ovog korisnika u grupu?',
            user_is_already: 'Ovaj korisnik je već',
            in_this_group: 'u ovoj grupi',
            user_already_here: 'Ovaj korisnik je već u ovoj grupi',
            deactivate_group:
              'Jeste li sigurni da želite ukloniti sve admine i deaktivirati grupu?',
            cancel_ban:
              'Jeste li sigurni da želite poništiti zabranu za ovoga igrača?',
            ban: 'Jeste li sigurni da želite zabraniti ovoga igrača na 7 dana?',
            fetch_data_error: 'Greška pri dohvaćanju podataka',
            bet: 'Jeste li sigurni da želite glasati za ovaj ishod?',
            vote: 'Jeste li sigurni da želite glasati za ',
            vote_self: 'Ne možete glasati za sebe.',
            saved: 'Spremljeno!',
            save_game_text:
              'U tijeku je kreiranje nove utakmice. Želite li spremiti unesene podatke prije izlaska?',
            left_team_empty:
              'Morate unijeti barem jednog igrača u lijevom timu!',
            right_team_empty:
              'Morate unijeti barem jednog igrača u desnom timu!',
            logout: 'Jeste li sigurni da se želite odjaviti?',
          },
          errors: {
            login: 'Prijavite se za nastavak.',
            check_input: 'Provjerite ulazne podatke.',
            no_permission: 'Nemate dopuštenje za ovu stranicu.',
            server_timeout: 'Isteklo vrijeme za odgovor servera.',
            something_wrong:
              'Nešto je pošlo po krivu. Pokušajte ponovo kasnije.',
            check_browser: 'Provjerite svoj pretraživač.',
            something_died: 'Nešto je pošlo po krivu s naše strane.',
          },
        },
      },
    },
  });

export default i18n;
