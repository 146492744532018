import { PlayerType } from '../../types/enums.types';
import { TeamListProps } from '../../types/props.types';

const Team1List = (props: TeamListProps) => {
  return (
    <ul className="list-group flex-fill">
      {props.players.map((player: any, index: number) => {
        //Quick fix of UserGameDto | SelectFieldDto bug where userId and id are same fields named differently
        if (!player.userId) {
          player.userId = player.id;
        }
        return (
          <li
            key={index}
            className="list-group-item bg-black d-flex align-items-center position-relative"
          >
            <div className="d-flex gap-2">
              <div>{player.name}</div>
              {player.role === PlayerType.GOALKEEPER ? (
                <div className="game-type game-Futsal bg-black">{`(G)`}</div>
              ) : (
                <></>
              )}
            </div>
            {props.onClick ? (
              <button
                onClick={() => props.onClick!(player.userId)}
                className="btn reset-btn p-1"
              >
                <i className={`bi ${props.icon}`}></i>
              </button>
            ) : (
              ''
            )}
            {player.numberOfVotes > 0 ? (
              <div className="position-absolute top-0 end-0  vote-count-wrapper bg-white shadow border border-white rounded text-dark my-2 mx-1">
                <div className="d-flex align-items-center">
                  <i className="bi bi-hand-thumbs-up-fill"></i>
                  <span className="votes-number ps-1">
                    {player.numberOfVotes}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Team1List;
