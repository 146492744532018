import React, { FC } from 'react';
import './circle.component.css';

interface CircleProps {
  initials: string;
}

const Circle = (props: CircleProps) => {
  return (
    <div className="circle">
      <p className="text">{props.initials}</p>
    </div>
  );
};

export default Circle;
