import PlayersTable from './players-table/players-table.component';
import './players.page.css';

const Players = () => {
  return (
    <>
      <PlayersTable />
    </>
  );
};

export default Players;
