import { validate } from 'class-validator';
import { t } from 'i18next';
import { BaseSyntheticEvent, FC, useState } from 'react';
import { InputFormProps } from '../../types/props.types';
import ButtonMain from '../buttons/button.component';
import InputField from '../inputs/input-field.component';
import SelectField from '../inputs/select.component';

const InputForm = (props: InputFormProps) => {
  const [inputFormChange, setInputFormChange] = useState<boolean>(false);

  const onChangeHandler = async (
    e: BaseSyntheticEvent | any,
    x: any = { name: '' }
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    props.validation[name] = value;
    const validationResult = await validate(props.validation, {
      groups: [name],
    });
    if (validationResult.length) {
      x.className = `${x.className} error`;
    } else {
      x.className = x.name;
      x.errorMsg = undefined;
    }
    props.setState!((x: any) => {
      return { ...x, [name]: value };
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const validateForm = await validate(props.validation);
    if (validateForm.length) {
      props.inputFields.forEach((x) => {
        validateForm.forEach((y) => {
          if (y.property === x.name) {
            x.className = `${x.className} error`;
            x.errorMsg = Object.values(y.constraints!);
          }
        });
      });
      setInputFormChange(!inputFormChange);
    } else {
      props.onSubmit(e);
    }
  };
  return (
    <>
      {props.inputFields.map((x, i) => {
        return (
          <div key={i} className={props.className || 'col-md-8 mx-auto'}>
            <InputField
              className={x.className}
              label={t(`input_fields.${x.label}`)}
              errorMsg={x.errorMsg}
              type={x.type}
              name={x.name}
              icon={x.icon}
              onChange={(e) => onChangeHandler(e, x)}
              defaultValue={x.defaultValue}
            />
          </div>
        );
      })}
      {props.selectFields?.map((x, i) => {
        return (
          <div key={i} className={props.className || 'col-md-8 mx-auto'}>
            <SelectField
              label={t(`input_fields.${x.name}`)}
              options={x.options}
              defaultValue={x?.defaultValue}
              eventHandler={(e) =>
                onChangeHandler({
                  target: {
                    name: x.label,
                    value: x.options[e.target.value].text,
                  },
                })
              }
            />
          </div>
        );
      })}
      {props.button?.map((x, i) => {
        return (
          <div key={i} className="col-md-8 mx-auto d-flex flex-row-reverse">
            <ButtonMain
              className={x.className}
              icon={x.icon}
              text={t('input_fields.button_submit')}
              onClick={(e) => onSubmit(e)}
              disabled={props.buttonDisabled}
            />
          </div>
        );
      })}
    </>
  );
};

export default InputForm;
