import { UserGameDto, VoteMvpDto } from '../../../../types/dtos.types';
import { getLoggedUser } from '../../../../utilities/local-storage/get-logged-user';
import DisabledButton from '../../../../components/buttons/button-disabled.component';
import { Server } from '../../../../server/server';
import { t } from 'i18next';

interface PlayerComponentProps {
  user: UserGameDto;
  ableToVote: boolean;
  gameId: string;
  team: number;
  setAbleToVote: (e: boolean) => void;
}

const PlayerComponent = (props: PlayerComponentProps) => {
  const loggedUser = getLoggedUser();
  const user = props.user;
  const ableToVote = props.ableToVote;
  const gameId = props.gameId;

  async function vote() {
    let confirmAction = window.confirm(`${t('alerts.vote')} ${user.name}?`);
    if (confirmAction) {
      const voteMvp: VoteMvpDto = {
        gameId: gameId,
        voterId: loggedUser.id,
        voteForId: user.userId,
      };
      if (loggedUser.id === user.userId) {
        alert(t('alerts.vote_self'));
        return;
      }
      await Server.mvp.createVoteMvp(voteMvp);
      alert(t('alerts.saved'));
      props.setAbleToVote(false);
    } else {
      alert(t('dashboard.try_again'));
    }
  }
  return (
    <>
      {props.team === 1 ? (
        <li className="list-group-item bg-black px-1 bg-team-black shadow mb-2">
          <div className="d-flex align-items-center">
            <div className="flex-fill player-name">{user.name}</div>

            {ableToVote ? (
              <>
                <button
                  className="btn btn-vote color-gold"
                  onClick={(e) => vote()}
                >
                  <i className="bi bi-trophy-fill"></i>
                </button>
              </>
            ) : (
              <DisabledButton
                className="btn-vote result-win"
                icon="slash-circle-fill"
                text=""
              />
            )}
          </div>
        </li>
      ) : (
        <li className="list-group-item bg-black px-1 bg-team-white text-dark text-end shadow mb-2">
          <div className="d-flex align-items-center">
            {ableToVote ? (
              <>
                <button
                  className="btn btn-vote color-gold"
                  onClick={(e) => vote()}
                >
                  <i className="bi bi-trophy-fill"></i>
                </button>
              </>
            ) : (
              <DisabledButton
                className="btn-vote result-win"
                icon="slash-circle-fill"
                text=""
              />
            )}

            <div className="flex-fill player-name">{user.name}</div>
          </div>
        </li>
      )}
    </>
  );
};

export default PlayerComponent;
