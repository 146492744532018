import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Min,
} from 'class-validator';
import { EditGameDto } from '../../types/dtos.types';

export class GameValidation {
  constructor(gameEdit: EditGameDto) {
    this.id = gameEdit.id;
    this.description = gameEdit.description;
    this.gameMVP = gameEdit.gameMVP;
    this.groupId = gameEdit.groupId;
    this.date = gameEdit.date;
    this.status = gameEdit.status;
    this.team1Goals = gameEdit.team1Goals;
    this.team2Goals = gameEdit.team2Goals;
    this.type = gameEdit.type;
  }

  @IsOptional()
  @IsUUID()
  id: string | undefined;

  @IsNotEmpty()
  @IsUUID()
  groupId: string | undefined;

  @IsNotEmpty()
  @IsString()
  date: string | undefined;

  @IsOptional()
  @IsNumber()
  @Min(0)
  team1Goals: number | undefined;

  @IsOptional()
  @IsNumber()
  @Min(0)
  team2Goals: number | undefined;

  @IsOptional()
  gameMVP: string | undefined;

  @IsNotEmpty()
  @IsString()
  type: string | undefined;

  @IsOptional()
  @IsString()
  status: string | undefined;

  @IsOptional()
  @IsString()
  description: string | undefined;
}
