export const changeEmailInputFields: {
  label: string;
  errorMsg: string[] | undefined;
  name: string;
  type: string;
  icon: string;
  className: string;
  onChange: () => void;
}[] = [
  {
    label: 'email',
    errorMsg: undefined,
    name: 'newEmail',
    type: 'text',
    icon: 'bi bi-envelope-fill',
    className: 'newEmail',
    onChange: () => {},
  },
  {
    label: 'repeatedNewEmail',
    errorMsg: undefined,
    name: 'repeatedNewEmail',
    type: 'text',
    icon: 'bi bi-envelope-check-fill',
    className: 'repeatedNewEmail',
    onChange: () => {},
  },
];

export const changePassWordInputFields: {
  label: string;
  errorMsg: string[] | undefined;
  name: string;
  type: string;
  icon: string;
  className: string;
  onChange: () => void;
}[] = [
  {
    label: 'newPassword',
    errorMsg: undefined,
    name: 'newPassword',
    type: 'password',
    icon: 'bi bi-shield-fill',
    className: 'newPassword',
    onChange: () => {},
  },
  {
    label: 'repeatedNewPassword',
    errorMsg: undefined,
    name: 'repeatedNewPassword',
    type: 'password',
    icon: 'bi bi-shield-fill-check',
    className: 'repeatedNewPassword',
    onChange: () => {},
  },
];

export const changeCredentialsSubmitButton = [
  {
    text: 'Submit',
    className: 'btn btn-primary w-100',
    icon: '',
    onClick: (e: any) => {},
  },
];
