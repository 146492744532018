import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RowContainer from '../../../container/row-container/row.container';
import InputForm from '../../../components/input-form/input-form';
import {
  addLocationInputFields,
  addLocationSubmitButton,
} from './constants/add-location-input-form.constants';
import { AddLocationValidation } from '../../../utilities/validators/add-location.validator';
import ActionMessage from '../../../components/action-message/action-message.component';
import { Server } from '../../../server/server';
import { GroupContext } from '../../../App';
import { LocationDto } from '../../../types/dtos.types';
import Spinner from '../../../components/spinner/spinner-component';
import Pagination from '../../../components/pagination/pagination.component';
import LocationItem from './location-item/location-item.component';

const AddNewLocation = () => {
  const { groupId } = useContext(GroupContext);
  const { t } = useTranslation();
  const formValidation = new AddLocationValidation();
  const [state, setState] = useState({
    name: '',
    address: '',
  });
  const [responseMsg, setResponseMsg] = useState<{
    responseType: string;
    message?: string;
    showMessage: boolean;
  }>({ responseType: '', message: '', showMessage: false });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listItems, setListItems] = useState<{ [locationId: string]: boolean }>(
    {}
  );
  const [paginationOffset, setPaginationOffset] = useState<number>(0);
  const [locationResponse, setLocationResponse] = useState<{
    locations: LocationDto[];
    count: number;
  }>(null as any);

  useEffect(() => {
    const pagination = { take: 10, offset: paginationOffset };
    const locationFetch = async () => {
      setIsLoading(true);
      const locationsResponse = await Server.location.getLocationsWithPage(
        groupId,
        pagination
      );
      setLocationResponse(locationsResponse);
      setIsLoading(false);
    };

    locationFetch();
  }, [groupId, paginationOffset, responseMsg]);

  const onSubmit = async () => {
    const location = {
      name: state.name,
      address: state.address,
    };
    setIsLoading(true);

    try {
      await Server.location.createLocation(location, groupId);
    } finally {
      setIsLoading(false);
    }

    setResponseMsg({
      responseType: 'Success',
      message: `${t('actions.created')} ${t(
        'input_fields.location'
      ).toLowerCase()} ${location.name}.`,
      showMessage: true,
    });
  };

  const onListItem = (locationId: string, isOpen: boolean) => {
    setListItems({ [locationId]: isOpen });
  };

  return (
    <>
      {responseMsg?.showMessage ? (
        <ActionMessage
          message={responseMsg?.message}
          type={
            responseMsg?.responseType
              ? responseMsg.responseType.toLowerCase()
              : 'success'
          }
          onClick={() =>
            setResponseMsg((prevState) => ({
              ...prevState!,
              showMessage: false,
            }))
          }
        />
      ) : (
        <div className="settings-tab-container pb-5">
          <h2 className="sidebar-link regular-font text-center pt-2">
            {t('titles.add_location')}
          </h2>
          <RowContainer>
            <InputForm
              inputFields={addLocationInputFields}
              validation={formValidation}
              onSubmit={onSubmit}
              setState={setState}
              button={addLocationSubmitButton}
              buttonDisabled={isLoading}
            />
          </RowContainer>
          <RowContainer>
            {!isLoading ? (
              <div className="mt-5">
                <h2 className="sidebar-link regular-font text-center my-3">
                  {t('titles.all_locations')}
                </h2>
                {locationResponse?.count > 0 ? (
                  locationResponse.locations.map((x) => {
                    return (
                      <LocationItem
                        key={x.id}
                        location={x}
                        listItems={listItems}
                        onListItem={onListItem}
                        setResponseMsg={setResponseMsg}
                      />
                    );
                  })
                ) : (
                  <div className="text-center mt-3">
                    <h1>
                      <i className="bi bi-emoji-neutral-fill"></i>
                    </h1>
                    <p>{t('locations.no_locations')}</p>
                  </div>
                )}
                {!isLoading && locationResponse?.count >= 10 && (
                  <Pagination
                    gameCount={locationResponse.count}
                    paginationOffset={paginationOffset}
                    setPaginationOffset={setPaginationOffset}
                  />
                )}
              </div>
            ) : (
              <Spinner />
            )}
          </RowContainer>
        </div>
      )}
    </>
  );
};

export default AddNewLocation;
