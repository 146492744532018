import { FC } from 'react';

const SidebarWrapper: FC = ({ children }) => {
  return (
    <div className="d-none d-md-block  col-md-2  min-vh-100 bg-component-background shadow-sm rounded-end position-relative p-0">
      <div className="sidebar_body sticky-md-top">{children}</div>
    </div>
  );
};

export default SidebarWrapper;
