import { t } from 'i18next';

export const addGroupInputFields: {
  label: string;
  errorMsg: string[] | undefined;
  name: string;
  type: string;
  icon: string;
  className: string;
  onChange: () => void;
}[] = [
  {
    label: 'Name',
    errorMsg: undefined,
    name: 'name',
    type: 'text',
    icon: '',
    className: 'name',
    onChange: () => {},
  },
];

export const addGroupSubmitButton = [
  {
    text: t('input_fields.button_submit'),
    className: 'btn btn-primary w-100',
    icon: '',
    onClick: (e: any) => {},
  },
];
