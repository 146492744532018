export const gameFilterDefault = {
  date: '',
  dateTo: new Date().toISOString().split('T')[0],
  playerRelation: 0,
  player1Id: '',
  player2Id: '',
  typeEvent: 0,
};

export const comparisonFilterDefault = {
  date: new Date(
    new Date().getFullYear() - 3,
    new Date().getMonth(),
    new Date().getDate()
  )
    .toISOString()
    .split('T')[0],
  dateTo: new Date().toISOString().split('T')[0],
  team1: [],
  team2: [],
};
