import { FETCHER_GET } from '../../constants/variables/fetcher.constants';
import { DashBoardDataDto } from '../../types/dtos.types';
import { fetcherMethod } from '../fetch-method';

export class DashboardServer {
  async getDashboardData(groupId: string): Promise<DashBoardDataDto> {
    const path = `dashboard/all?id=${groupId}`;
    return await fetcherMethod(path, FETCHER_GET);
  }
}
