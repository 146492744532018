import { GameDto, GameWithUserGamesDto } from '../../../types/dtos.types';

export const SetTeamResultColorScheme = (
  game: GameDto | GameWithUserGamesDto
) => {
  const scoreState: {
    team1State: string;
    team2State: string;
  } = { team1State: '', team2State: '' };

  if (game.team1Goals > game.team2Goals) {
    scoreState.team1State = 'win';
    scoreState.team2State = 'loss';
    return scoreState;
  }
  if (game.team1Goals < game.team2Goals) {
    scoreState.team1State = 'loss';
    scoreState.team2State = 'win';
    return scoreState;
  }
  if (game.team1Goals === game.team2Goals) {
    scoreState.team1State = 'draw';
    scoreState.team2State = 'draw';
    return scoreState;
  }
};
