import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Server } from '../../../server/server';
import { PlayerDto } from '../../../types/dtos.types';
import { SelectOptionsProps } from '../../../types/props.types';
import ChangeCredentials from '../../settings/change-credentials/change-credentials';
import PersonalInfo from '../../settings/personal-info/personal-info';
import ChoosePossiblePlayers from '../../../components/possible-players/ChoosePossiblePlayers';
import { CredentialType } from '../../../types/enums.types';

const EditPlayer = () => {
  const { t } = useTranslation();
  const [userList, setUserList] = useState<SelectOptionsProps[]>([]);
  const [user, setUser] = useState<PlayerDto>();

  useEffect(() => {
    const userListFetch = async () => {
      const userListResponse = await Server.user.getAll();
      const userListOptions = userListResponse.map((x, i) => {
        return { value: i.toString(), text: x.name, id: x.id };
      });
      setUserList([...userListOptions]);
    };
    userListFetch();
  }, []);

  const selectAndGetPlayer = async (selectedOptions: {
    value: string;
    label: string;
    id: string | undefined;
  }) => {
    const userId = selectedOptions.id;
    const userResponse = await Server.user.getUserById(userId!);
    setUser(userResponse);
  };

  const isSelected = (id: string | undefined) => {
    if (user?.id === id) {
      return true;
    } else return false;
  };

  return (
    <>
      <div className="settings-tab-container">
        <h2 className="sidebar-link regular-font text-center pt-2">
          {t('titles.edit_player')}
        </h2>
        <div className="mt-3">
          <ChoosePossiblePlayers
            title={''}
            userList={userList}
            handleSelectPossiblePlayers={selectAndGetPlayer}
            isSelectedAsPossible={isSelected}
          />
        </div>
        {user && (
          <>
            <PersonalInfo user={user} />
            <ChangeCredentials
              typeOfChange={CredentialType.EMAIL}
              id={user.id}
            />
            <ChangeCredentials
              typeOfChange={CredentialType.PASSWORD}
              id={user.id}
            />
          </>
        )}
      </div>
    </>
  );
};

export default EditPlayer;
