import './spinner-component.css';

const Spinner = () => {
  return (
    <div className="lds-ripple position-absolute start-50 translate-middle top-50 ">
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
