export const todayMinusMonth = () => {
  const today = new Date();
  const month = today.getMonth();
  let newMonth;
  if (month === 1) {
    newMonth = 12;
    today.setFullYear(today.getFullYear() - 1);
    today.setMonth(newMonth);
  } else {
    newMonth = month - 1;
    today.setMonth(newMonth);
  }
  return today.toISOString().split('T')[0];
};
