import { PlayerDto } from '../../types/dtos.types';

export const setLoggedUser = (user: PlayerDto): boolean => {
  if (user.id) {
    window.localStorage.setItem('LoggedUser', JSON.stringify(user));
    if (user.userGroups.length > 0) {
      window.localStorage.setItem(
        'selectedGroup',
        JSON.stringify(user?.userGroups?.[0].groupId)
      );
    }
    return true;
  } else {
    return false;
  }
};
