import {
  FETCHER_DELETE,
  FETCHER_GET,
  FETCHER_PATCH,
  FETCHER_POST,
} from '../../constants/variables/fetcher.constants';
import {
  EditGameDto,
  GameDto,
  GameFilterDto,
  GameFilterTestDto,
  GameTypeDto,
  GameWithUserGamesDto,
  PaginationDto,
  ResetBettingDto,
} from '../../types/dtos.types';
import { PlayerType, Teams } from '../../types/enums.types';
import { gameFilterPathCalculation } from '../../utilities/data/game-filter-path/game-filter-path-calculation';
import { fetcherMethod } from '../fetch-method';

export class GameServer {
  private readonly basePath = 'game';

  async getGame(gameFilter: GameFilterDto): Promise<GameDto> {
    const path = `${this.basePath}/one`;
    const response = fetcherMethod(path, FETCHER_GET);
    return response;
  }

  async getAllWhere(
    groupId: string,
    status: string,
    relations: boolean = false,
    pagination: PaginationDto = { take: 10, offset: 0 }
  ): Promise<GameWithUserGamesDto[]> {
    const pathWithPagination = `${this.basePath}?status=${status}&relations=${relations}&take=${pagination?.take}&offset=${pagination?.offset}&groupId=${groupId}`;
    return await fetcherMethod(pathWithPagination, FETCHER_GET);
  }

  async getGamesWithFilter(
    groupId: string,
    gameFilter: GameFilterDto,
    pagination: { take: number; offset: number },
    status?: string
  ) {
    const paginationPath = `take=${pagination?.take}&offset=${pagination?.offset}`;
    const groupIdPath = `&groupId=${groupId}`;
    let filterPath = '';
    const reqUrlBase = `${this.basePath}/gamewithfilters?`;
    if (Object.keys(gameFilter).length) {
      filterPath = gameFilterPathCalculation(gameFilter);
    }
    const path = reqUrlBase + paginationPath + filterPath + groupIdPath;
    return await fetcherMethod(path, FETCHER_GET);
  }

  async getGamesWithMultiplePlayers(gameFilter: GameFilterTestDto) {
    const reqUrlBase = `${this.basePath}/multiple-comparison`;
    const body = JSON.stringify(gameFilter);
    const path = reqUrlBase;
    return await fetcherMethod(path, FETCHER_POST, body);
  }

  async getGameTypes(): Promise<GameTypeDto[]> {
    const path = 'game-types?groupId=1db38596-afe4-45cd-926b-a7cedb9f6dad';
    return await fetcherMethod(path, FETCHER_GET);
  }

  async getGameWithUserGames(
    gameId: string | undefined | null
  ): Promise<GameWithUserGamesDto> {
    const path = `${this.basePath}/gamewithusergames?id=${gameId}`;

    return await fetcherMethod(path, FETCHER_GET);
  }

  async createGameWithUserGames(
    game: {
      date: string;
      groupId: string | undefined | null;
      type: string;
      locationName: string;
      statusVoting: boolean;
    },
    userGames: {
      userId?: string;
      team?: number | Teams;
      role?: PlayerType;
    }[]
  ) {
    const path = `${this.basePath}/new`;
    const body = JSON.stringify({ game, userGames });
    try {
      await fetcherMethod(path, FETCHER_POST, body);
      return {
        message: 'You have successfuly created game',
        status: 'Success',
      };
    } catch (err) {
      return err;
    }
  }

  async editGame(gameEdit: EditGameDto) {
    const basePath = `${this.basePath}/update`;
    let pathAddition = '';

    const body = JSON.stringify(gameEdit);

    const path = basePath + pathAddition;
    try {
      await fetcherMethod(path, FETCHER_PATCH, body);
      return { message: 'You have successfuly edited game', status: 'Success' };
    } catch (err) {
      return err;
    }
  }

  async resetBetting(resetEdit: ResetBettingDto) {
    const basePath = `${this.basePath}/reset-betting`;
    let pathAddition = '';

    const body = JSON.stringify(resetEdit);
    const path = basePath + pathAddition;

    try {
      await fetcherMethod(path, FETCHER_PATCH, body);
      return {
        message: 'You have successfuly reset the betting',
        status: 'Success',
      };
    } catch (err) {
      return err;
    }
  }

  async setScore(gameEdit: EditGameDto) {
    const basePath = `${this.basePath}/set-score`;
    let pathAddition = '';

    const body = JSON.stringify(gameEdit);

    const path = basePath + pathAddition;
    try {
      await fetcherMethod(path, FETCHER_PATCH, body);
      return { message: 'You have successfuly edited game', status: 'Success' };
    } catch (err) {
      return err;
    }
  }

  async deleteGame(id: string) {
    const basePath = `${this.basePath}/delete-game`;

    const pathAddition = `?id=${id}`;
    const path = basePath + pathAddition;
    try {
      await fetcherMethod(path, FETCHER_DELETE);
      return true;
    } catch (err) {
      return err;
    }
  }
}
