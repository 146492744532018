import {
  FETCHER_GET,
  FETCHER_PATCH,
  FETCHER_POST,
} from '../../constants/variables/fetcher.constants';
import { LocationDto } from '../../types/dtos.types';
import { fetcherMethod } from '../fetch-method';

export class LocationServer {
  async createLocation(
    location: { name: string; address: string },
    groupId: string
  ) {
    const path = 'location/new';
    const bodyObj = {
      name: location.name,
      address: location.address,
      groupId: groupId,
    };
    const body = JSON.stringify(bodyObj);
    const response = await fetcherMethod(path, FETCHER_POST, body);
    return response;
  }

  async getLocations(groupId: string): Promise<LocationDto[]> {
    const path = `location?groupId=${groupId}`;
    const response = await fetcherMethod(path, FETCHER_GET);
    return response;
  }

  async getLocationsWithPage(
    groupId: string | undefined | null,
    pagination: { take: number; offset: number }
  ) {
    const path = `location/locationswithpage?groupId=${groupId}`;
    const paginationPath = `&take=${pagination?.take}&offset=${pagination?.offset}`;
    const response = await fetcherMethod(path + paginationPath, FETCHER_GET);
    return response;
  }

  async updateLocationName(locationId: string, groupId: string, name: string) {
    const path = `location/updatename`;
    const bodyObj = {
      locationId: locationId,
      groupId: groupId,
      name: name,
    };

    const body = JSON.stringify(bodyObj);
    const response = await fetcherMethod(path, FETCHER_PATCH, body);
    return response;
  }

  async updateAddress(locationId: string, groupId: string, address: string) {
    const path = `location/updateaddress`;
    const bodyObj = {
      locationId: locationId,
      groupId: groupId,
      address: address,
    };

    const body = JSON.stringify(bodyObj);
    const response = await fetcherMethod(path, FETCHER_PATCH, body);
    return response;
  }

  async deleteLocation(groupId: string, locationId: string) {
    const path = `location/delete`;
    const bodyObj = {
      groupId: groupId,
      locationId: locationId,
    };
    const body = JSON.stringify(bodyObj);

    const response = await fetcherMethod(path, FETCHER_PATCH, body);
    return response;
  }
}
