import { BrowserRouter } from 'react-router-dom';
import { createContext, useEffect, useMemo, useState } from 'react';
import { setLoggedUser } from './utilities/local-storage/set-logged-user';
import { getGroupId } from './utilities/local-storage/player-group-id';
import { checkIfLoggedUserExist } from './utilities/local-storage/get-logged-user';
import { Server } from './server/server';
import { parseJwt } from './utilities/authentication/login';
import { getToken } from './utilities/local-storage/get-authorisation-token';
import { removeLoggedUser } from './utilities/local-storage/remove-logged-user';
import { removeAuthorisationToken } from './utilities/local-storage/remove-authorisation-token';
import LoginRouter from './router/login-router';
import i18n from './i18next';
import Banned from './pages/login/banned.component';
import ApplicationContainer from './container/application-container/application.container';
import Sidebar from './components/sidebar/sidebar.component';
import MobileNavigation from './components/mobile/mobile-navigation.component';
import MainWindow from './main-window-content/main-window.content';
import './App.css';

export const LoggedInContext = createContext({
  loggedIn: false,
  setLoggedIn: (e: any) => {},
});

export const GroupContext = createContext({
  groupId: getGroupId(),
  setGroupId: (e: string | undefined) => {},
});

function App() {
  const [loggedIn, setLoggedIn] = useState<boolean>(checkIfLoggedUserExist());
  const [groupId, setGroupId] = useState<string | undefined | null>(
    getGroupId()
  );
  const [banned, setBanned] = useState(false);
  const [userBannedData, setUserBannedData] = useState<{
    banExp: Date | undefined | null;
    buttonShow: boolean;
  }>();

  const value = useMemo(() => ({ loggedIn, setLoggedIn }), [loggedIn]);
  const group = useMemo(() => ({ groupId, setGroupId }), [groupId]);
  i18n.changeLanguage('hr');

  useEffect(() => {
    const verifyLoggedIn = async () => {
      const token = getToken();
      if (token) {
        const parsedToken = parseJwt(token);
        const userResponse = await Server.user.getUserById(parsedToken.id);
        if (userResponse.isBanned) {
          const now = new Date();
          if (userResponse.banExpiration) {
            const expiration = new Date(userResponse.banExpiration);
            if (now > expiration) {
              await Server.user.handleBan(userResponse.id, false);
              setLoggedUser(userResponse);
            } else {
              setLoggedIn(false);
              removeLoggedUser();
              removeAuthorisationToken();
              setBanned(true);
              setUserBannedData({
                banExp: userResponse.banExpiration,
                buttonShow: true,
              });
            }
          }
        } else {
          setLoggedUser(userResponse);
        }
      } else {
        setLoggedIn(false);
        removeLoggedUser();
        removeAuthorisationToken();
      }
      setLoggedIn(checkIfLoggedUserExist());
      setGroupId(getGroupId());
    };
    verifyLoggedIn();
  }, [loggedIn]);

  return (
    <ApplicationContainer>
      <BrowserRouter>
        <GroupContext.Provider value={group}>
          <LoggedInContext.Provider value={value}>
            {banned ? (
              <Banned {...userBannedData} />
            ) : !loggedIn ? (
              <LoginRouter />
            ) : (
              <>
                <Sidebar />
                <MainWindow />
                <MobileNavigation />
              </>
            )}
          </LoggedInContext.Provider>
        </GroupContext.Provider>
      </BrowserRouter>
    </ApplicationContainer>
  );
}

export default App;
