import { usePagination } from '../../hooks/usePagination';
import './pagination.component.css';

interface PaginationProps {
  gameCount: number;
  paginationOffset: number;
  setPaginationOffset: (e: any) => void;
}

const Pagination = (props: PaginationProps) => {
  const currentPage = props.paginationOffset;
  const paginationRange = usePagination({
    currentPage: currentPage + 1,
    totalCount: props.gameCount,
    siblingCount: 1,
    pageSize: 10,
  });

  const pageNumberButtonHandler = (x: number) => {
    props.setPaginationOffset(x - 1);
  };

  const onNext = () => {
    props.setPaginationOffset(currentPage + 1);
  };

  const onPrevious = () => {
    props.setPaginationOffset(currentPage - 1);
  };
  return (
    <div className="mt-2">
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          {currentPage < 1 ? (
            <></>
          ) : (
            <li className="page-item">
              <button
                onClick={(e) => onPrevious()}
                className="page-link"
                aria-label="Previous"
              >
                <span className="sr-only">
                  <i className="bi bi-arrow-left-circle-fill"></i>
                </span>
              </button>
            </li>
          )}

          {paginationRange?.map((x) => {
            let pageLink = 'page-link';
            if (x === currentPage + 1) {
              pageLink = 'page-link active';
            }

            return (
              <li key={x} className="page-item">
                <button
                  onClick={(e) => {
                    pageNumberButtonHandler(x);
                  }}
                  className={pageLink}
                >
                  {x}
                </button>
              </li>
            );
          })}

          {currentPage + 1 === Math.ceil(props.gameCount / 10) ? (
            <></>
          ) : (
            <li className="page-item">
              <button
                onClick={(e) => onNext()}
                className="page-link"
                aria-label="Next"
              >
                <span className="sr-only">
                  <i className="bi bi-arrow-right-circle-fill"></i>
                </span>
              </button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
