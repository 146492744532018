export const modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
};
