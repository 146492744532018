import { useEffect, useState } from 'react';
import { GameWithUserGamesDto } from '../../../../../types/dtos.types';
import { t } from 'i18next';
import { calculateWhoWon } from '../../../../../utilities/data/calculate-statistics/comparison-statistics.utility';
import { ResultType } from '../../../../../types/enums.types';

interface AccordionGameCompareDataProps {
  game: GameWithUserGamesDto;
  team1Color: string;
  team2Color: string;
  players: string[][] | undefined;
}

const AccordionGameCompareData = (props: AccordionGameCompareDataProps) => {
  const [resultPosition, setResultPosition] = useState('');
  const game = props.game;
  const whoWon = calculateWhoWon(game, props.players);
  const { team1Color, team2Color } = props;

  useEffect(() => {
    if (whoWon === ResultType.TEAM1) {
      setResultPosition('justify-content-start');
    } else if (whoWon === ResultType.TEAM2) {
      setResultPosition('justify-content-end');
    } else {
      setResultPosition('justify-content-center');
    }
  }, [whoWon]);

  return (
    <div className="container-fluid header-result container-fluid d-flex align-items-center">
      <div className={`p-2 flex-grow-1 breadcrumb-title`}>
        <div className={`d-flex ${resultPosition} gap-1`}>
          <span className="text-dark">{t('teams.team_1_color')}</span>{' '}
          <span className={`result-${team1Color}`}>{game.team1Goals}</span> -{' '}
          <span className={`result-${team2Color}`}>{game.team2Goals}</span>{' '}
          <span className="text-white game-item-white">
            {t('teams.team_2_color')}
          </span>
        </div>
      </div>
      <div className="d-flex gap-3 align-items-center">
        <div className="sport-type">{game.type}</div>
      </div>
    </div>
  );
};

export default AccordionGameCompareData;
