import { NavLink } from 'react-router-dom';
import './tabs-navigation.component.css';
import { useTranslation } from 'react-i18next';

interface TabsNavigationProps {
  tabs: {
    path: string;
    title: string;
  }[];
}

const TabsNavigation = (props: TabsNavigationProps) => {
  const { t } = useTranslation();
  return (
    <>
      <ul className="nav nav-tabs">
        {props.tabs.map((item, index) => {
          return (
            <li className="nav-item" key={index}>
              <NavLink to={item.path} className="settings-tab nav-link" end>
                {t(`tab_items.${item.title}`)}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default TabsNavigation;
