import { PlayerDto } from '../../types/dtos.types';

export const getLoggedUser = (): PlayerDto => {
  const userJSON = window.localStorage.getItem('LoggedUser');
  const user = userJSON && JSON.parse(userJSON);
  return user;
};

export const checkIfLoggedUserExist = (): boolean => {
  const user = getLoggedUser();
  if (user && user.id) {
    return true;
  }
  return false;
};
