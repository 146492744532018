import MainWindowContainer from '../container/main-window-container/pages.container';
import PagesRouter from '../router/pages-router';
import HeaderMenu from '../components/header-menu/header-menu.component';
import FluidContainer from '../container/fluid-container/fluid.container';
import { useCallback, useContext, useState } from 'react';
import { LoggedInContext } from '../App';
import { removeAuthorisationToken } from '../utilities/local-storage/remove-authorisation-token';
import GlobalErrorMessage from '../components/global-error-message/global-error-message.component';
import { GlobalErrorDto } from '../types/dtos.types';
import { ErrorInterceptor } from '../utilities/data/error-interceptor/error-interceptor.utility';
import ScrollToTop from './scroll-to-top/scroll-to-top.component';
import ActionMessage from '../components/action-message/action-message.component';
import { removeLoggedUser } from '../utilities/local-storage/remove-logged-user';

const { fetch: originalFetch } = window;
const globalErrorDefault = {
  display: false,
  className: '',
  title: 'Error',
  content: 'ErrorMsg',
};
const MainWindow = () => {
  const { setLoggedIn } = useContext(LoggedInContext);

  const [error, setError] = useState<GlobalErrorDto>(globalErrorDefault);

  const [displayMessage, setDisplayMessage] = useState<boolean>(false);

  ///////////////////////////////////////////////////////////////////////////
  window.fetch = useCallback(
    async (...args) => {
      let [resource, config] = args;
      try {
        const response = await originalFetch(resource, config);
        if (
          response.status >= 300 &&
          response.status !== 409 &&
          response.status !== 404
        ) {
          const err = ErrorInterceptor(response.status);
          errorHandler(err?.title, err?.message, err?.className);
        }
        if (response.status === 401) {
          removeAuthorisationToken();
          removeLoggedUser();
          setLoggedIn(false);
        }
        return response;
      } catch (err) {
        setError({
          display: true,
          className: 'notification-window-critical slide-in',
          title: 'Fatal error',
          content: 'Something went wrong.',
        });
        setDisplayMessage(true);
        return new Response();
      }
    },
    [setLoggedIn]
  );

  ///////////////////////////////////////////////////////////////////////////
  const errorHandler = (
    errorTitle: string,
    errorContent: string,
    className: string
  ) => {
    setError({
      display: true,
      className: className,
      title: errorTitle,
      content: errorContent,
    });
    setDisplayMessage(true);
  };

  return (
    <MainWindowContainer>
      {error.display && (
        <GlobalErrorMessage setState={setError} errorState={error} />
      )}
      <HeaderMenu />
      <FluidContainer className="min-vh-100 py-2 g-1">
        <ScrollToTop>
          {!displayMessage ? (
            <PagesRouter />
          ) : (
            <ActionMessage
              message={`Something went wrong`}
              type={'error'}
              onClick={() => window.location.reload()}
            />
          )}
        </ScrollToTop>
      </FluidContainer>
    </MainWindowContainer>
  );
};

export default MainWindow;
