import { OPTIONS_COLLAB } from '../../../constants/variables/options_collab.constant';
import { GameFilterDto, gameFilterStateDto } from '../../../types/dtos.types';
import { SelectOptionsProps } from '../../../types/props.types';

export const gameFilterCalc = (
  gameFilterState: gameFilterStateDto,
  filteringOptions?: {
    userList: SelectOptionsProps[];
    gameTypes: SelectOptionsProps[];
  }
) => {
  const playerSelectedIds: (string | undefined)[] = [
    gameFilterState?.player1Id,
    gameFilterState?.player2Id,
  ];
  const gameFilter: GameFilterDto = {};
  if (gameFilterState?.date) {
    gameFilter.date = gameFilterState?.date;
    gameFilter.dateTo = gameFilterState?.dateTo;
  }
  if (gameFilterState?.typeEvent) {
    gameFilter.gameType =
      filteringOptions?.gameTypes?.[gameFilterState?.typeEvent].text;
  }
  if (
    gameFilterState?.playerRelation === 1 ||
    gameFilterState?.playerRelation === 2
  ) {
    gameFilter.playerRelation =
      OPTIONS_COLLAB[gameFilterState?.playerRelation].id;
  }
  if (playerSelectedIds.length) {
    gameFilter.playerIds = playerSelectedIds;
  }
  return gameFilter;
};
