import { PlayerDto } from '../../../../../../../types/dtos.types';

interface AccordionPlayerDataProps {
  player: PlayerDto;
}

const AccordionPlayerData = (props: AccordionPlayerDataProps) => {
  const player = props.player;
  return (
    <div className="container-fluid header-result container-fluid d-flex  align-items-center">
      <div className="p-2 flex-grow-1 breadcrumb-title">
        <span>{player.name}</span>
        {player.isBanned ? (
          <span className="mx-2">
            <i className={`bi bi-slash-circle text-danger`}></i>
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AccordionPlayerData;
