import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LoggedInContext } from '../App';
import Login from '../pages/login/login.component';
import Register from '../pages/register/register.page';

const LoginRouter = () => {
  const { loggedIn } = useContext(LoggedInContext);
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register/:token" element={<Register />} />
      {!loggedIn && <Route path="*" element={<Navigate to="/login" />} />}
    </Routes>
  );
};

export default LoginRouter;
