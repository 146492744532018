import { FC } from 'react';
import { t } from 'i18next';
import ButtonMain from '../buttons/button.component';

interface CNPProps {
  showPopup: boolean | (() => void);
  cancelNavigation: any;
  confirmNavigation: any;
  saveNewGameData: () => void;
}

const ConfirmNavigationPopup: FC<CNPProps> = ({
  showPopup,
  cancelNavigation,
  confirmNavigation,
  saveNewGameData,
}) => {
  const noSaveAndConfirm = () => {
    localStorage.removeItem('addNewGameData');
    confirmNavigation();
  };

  const saveAndConfirm = () => {
    saveNewGameData();
    confirmNavigation();
  };

  return (
    <>
      <div
        className={`modal fade ${showPopup ? 'show' : ''}`}
        tabIndex={-1}
        role="dialog"
        style={{ display: showPopup ? 'block' : 'none' }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content custom_modal">
            <div className="modal-header">
              <div className="modal-title">
                <h4 className="game-type italic text-uppercase">
                  {t('titles.save_game')}
                </h4>
              </div>
              <button
                type="button"
                className="bg-component-background border-0 mb-2"
                data-dismiss="modal"
                aria-label="Close"
                onClick={cancelNavigation}
              >
                <span aria-hidden="true">
                  <i className="bi bi-x-lg text-danger"></i>
                </span>
              </button>
            </div>
            <div className="modal-body">
              <p>{t('alerts.save_game_text')}</p>
            </div>
            <div className="modal-footer">
              <ButtonMain
                className="game-type bg-main-backround text-white p-2 rounded border"
                onClick={noSaveAndConfirm}
                text={t('input_fields.button_nosave')}
              ></ButtonMain>
              <ButtonMain
                className="game-type bg-main-backround text-white p-2 rounded border"
                onClick={saveAndConfirm}
                text={t('input_fields.button_save')}
              ></ButtonMain>
            </div>
          </div>
        </div>
      </div>
      {showPopup && <div className="overlay"></div>}
    </>
  );
};

export default ConfirmNavigationPopup;
