import { useEffect, useState } from 'react';
import { SelectOptionsProps } from '../../../../../../types/props.types';
import { Server } from '../../../../../../server/server';
import { PlayerDto } from '../../../../../../types/dtos.types';
import { useTranslation } from 'react-i18next';
import GroupPlayerItem from './player-in-group-item.component';
import SelectField from '../../../../../../components/inputs/select.component';
import ButtonMain from '../../../../../../components/buttons/button.component';
import ChoosePossiblePlayers from '../../../../../../components/possible-players/ChoosePossiblePlayers';
import {
  GroupActivationType,
  UserType,
} from '../../../../../../types/enums.types';

interface GroupAccordionListProps {
  groupId: string;
  className: string;
  groupName: string;
  players: PlayerDto[];
  userList: SelectOptionsProps[];
  setPlayers: React.Dispatch<React.SetStateAction<PlayerDto[]>>;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccordionList = (props: GroupAccordionListProps) => {
  const { t } = useTranslation();
  const [roles, setRoles] = useState<SelectOptionsProps[]>([]);
  const [showChooseAdmin, setShowChooseAdmin] = useState(false);
  const [adminCandidateList, setAdminCandidateList] = useState<
    SelectOptionsProps[]
  >([]);
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const changeEditStatus = (e: any, status: boolean) => {
    e.stopPropagation();
    setEditTitle(status);
  };

  useEffect(() => {
    const getRoleOptions = () => {
      const roleTypes = [{ type: UserType.ADMIN }, { type: UserType.USER }];
      const roleOptions = roleTypes.map((x, i) => {
        return { value: i.toString(), text: t(`players.${x.type}`) };
      });
      setRoles(roleOptions);
    };
    const adminCandidates = props.players.map((x, i) => {
      return { value: i.toString(), text: x.name, id: x.id };
    });
    setAdminCandidateList(adminCandidates);
    getRoleOptions();
  }, [props.players, t]);

  const handleRemoveUser = async (userId: string, groupId: string) => {
    const confirmAction = window.confirm(t('alerts.remove_from_group'));

    if (confirmAction) {
      const body = {
        userId: userId,
        groupId: groupId,
      };
      setIsLoading(true);
      try {
        await Server.user.removeUserFromGroup(body);
      } finally {
        props.setReload(!props.reload);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      return;
    }
  };

  const isAdmin = (player: PlayerDto, groupId: string) => {
    const userGroup = player.userGroups?.find(
      (group) => group.groupId === groupId
    );
    return userGroup?.role === UserType.ADMIN ? true : false;
  };

  const handleAdmin = async (player: PlayerDto, role: string) => {
    setIsLoading(true);
    if (isAdmin(player, props.groupId) && role === UserType.USER) {
      const confirmAction = window.confirm(t('alerts.remove_as_admin'));

      if (confirmAction) {
        const body = {
          userId: player.id,
          groupId: props.groupId,
          role: UserType.ADMIN,
        };
        try {
          await Server.userGroup.changeRole(body);
        } finally {
          props.setReload(!props.reload);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } else if (!isAdmin(player, props.groupId) && role === UserType.ADMIN) {
      const confirmAction = window.confirm(t('alerts.add_as_admin'));
      if (confirmAction) {
        const body = {
          userId: player.id,
          groupId: props.groupId,
          role: UserType.USER,
        };
        try {
          await Server.userGroup.changeRole(body);
        } finally {
          props.setReload(!props.reload);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } else {
      alert(
        `${t('alerts.user_is_already')} ${role} ${t('alerts.in_this_group')}`
      );
      setIsLoading(false);
    }
  };

  const addPlayers = async (selectedOptions: {
    value: string;
    label: string;
    id: string | undefined;
  }) => {
    let user;
    const id = selectedOptions.id;
    setIsLoading(true);
    if (id) {
      user = await Server.user.getUserById(id);
    }
    const playerAlreadyInGroup = props.players.some((x) => x.id === id);
    playerAlreadyInGroup && alert(t('alerts.user_already_here'));

    if (!playerAlreadyInGroup && user) {
      if (window.confirm(t('alerts.add_to_group'))) {
        await Server.group.addNewPlayer(props.groupId, user);
        setIsLoading(false);
        props.setReload(!props.reload);
      }
    }
  };

  const activateWithAdmin = async (e: number) => {
    const user = props.players[e];
    const body = {
      userId: user.id,
      groupId: props.groupId,
      role: UserType.USER,
    };
    setIsLoading(true);
    try {
      await Server.userGroup.changeRole(body);
    } finally {
      props.setReload(!props.reload);
      setIsLoading(false);
    }
  };

  const checkIfActive = (players: PlayerDto[]) => {
    return players.some((player) => isAdmin(player, props.groupId));
  };

  const handleGroupActivationStatus = async (action: GroupActivationType) => {
    setIsLoading(true);
    if (action === GroupActivationType.DEACTIVATE) {
      const confirmAction = window.confirm(t('alerts.deactivate_group'));
      if (confirmAction) {
        props.players.forEach(async (player) => {
          const body = {
            userId: player.id,
            groupId: props.groupId,
            role: UserType.ADMIN,
          };
          try {
            await Server.userGroup.changeRole(body);
          } finally {
            props.setReload(!props.reload);
            setIsLoading(false);
          }
        });
      } else {
        setIsLoading(false);
      }
    } else {
      setShowChooseAdmin(true);
      setIsLoading(false);
    }
  };

  const onSubmit = async (e: any) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
      await Server.group.updateGroupName(props.groupId, title);
    } finally {
      props.setReload(!props.reload);
      setIsLoading(false);
    }
  };

  const getOptions = () => {
    const playerIds = [...props.players.map((x) => x.id)];
    const options = props.userList.filter(
      (option) => option.id && !playerIds.includes(option.id)
    );

    return options;
  };

  return (
    <div
      id="collapseOne"
      className={`accordion-collapse collapse ${props.className}`}
    >
      <div className="accordion-body">
        <div className="container-fluid py-small-1 d-flex flex-column justify-content-center">
          <div className="container-fluid bg-main-backround rounded py-1 px-2 d-flex align-items-center mb-3">
            <div className="game-type location_label">
              {t('locations.name')}:
            </div>
            {!editTitle ? (
              <>
                <span>{props.groupName}</span>
                <button
                  onClick={(e) => changeEditStatus(e, true)}
                  className="btn reset-btn"
                >
                  <i className={`bi bi-pencil-square`}></i>
                </button>
              </>
            ) : (
              <form className="p-2 gap-2 d-flex">
                <input
                  onChange={(e) => setTitle(e.target.value)}
                  className="form-control"
                ></input>
                <ButtonMain
                  className="btn btn-outline-light d-flex gap-2"
                  text={t('input_fields.button_submit')}
                  onClick={(e) => onSubmit(e)}
                  icon="check-circle"
                  disabled={isLoading}
                />
                <ButtonMain
                  className="btn btn-outline-light d-flex gap-2"
                  text={t('input_fields.button_cancel')}
                  onClick={(e) => changeEditStatus(e, false)}
                  icon="x-circle"
                />
              </form>
            )}
          </div>
          <div className="px-2 d-flex flex-column">
            <div className="mb-3">
              <ChoosePossiblePlayers
                title={t('input_fields.add_player')}
                userList={getOptions()}
                handleSelectPossiblePlayers={addPlayers}
              />
            </div>
            <div className=" d-flex justify-content-between align-items-center mb-3">
              <h4 className="game-type italic mt-3">{t('groups.members')}</h4>
              {checkIfActive(props.players) ? (
                <ButtonMain
                  className="btn btn-warning d-flex gap-2 mt-2"
                  icon="slash-circle"
                  text={t('groups.deactivate')}
                  onClick={() =>
                    handleGroupActivationStatus(GroupActivationType.DEACTIVATE)
                  }
                  disabled={isLoading}
                />
              ) : showChooseAdmin ? (
                <div className="d-flex gap-2 align-items-center">
                  <SelectField
                    label={t('input_fields.choose_admin')}
                    options={adminCandidateList}
                    id="adminCandidates"
                    eventHandler={(e) =>
                      activateWithAdmin(e.target.options.selectedIndex)
                    }
                    multiple={false}
                  ></SelectField>
                  <ButtonMain
                    className="btn btn-outline-light d-flex mt-2"
                    text={t('input_fields.button_cancel')}
                    onClick={() => setShowChooseAdmin(false)}
                  />
                </div>
              ) : (
                <ButtonMain
                  className="btn btn-info d-flex gap-2 mt-2"
                  icon="arrow-counterclockwise"
                  text={t('groups.activate')}
                  onClick={() =>
                    handleGroupActivationStatus(GroupActivationType.ACTIVATE)
                  }
                  disabled={isLoading}
                />
              )}
            </div>
          </div>
          {props.players?.map((player) => {
            return (
              <GroupPlayerItem
                player={player}
                roles={roles}
                groupId={props.groupId}
                isAdmin={isAdmin}
                handleAdmin={handleAdmin}
                handleRemoveUser={handleRemoveUser}
                buttonDisabled={isLoading}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default AccordionList;
