import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../location-item.component.css';
import { GroupContext } from '../../../../../App';
import ButtonMain from '../../../../../components/buttons/button.component';
import { Server } from '../../../../../server/server';
import { ChangeItem } from '../../../../../types/enums.types';
import { LocationDto } from '../../../../../types/dtos.types';

interface LocationAccordionListProps {
  location: LocationDto;
  className: string;
  setResponseMsg?: React.Dispatch<
    React.SetStateAction<{
      responseType: string;
      message?: string;
      showMessage: boolean;
    }>
  >;
}

const AccordionList = (props: LocationAccordionListProps) => {
  const { groupId } = useContext(GroupContext);
  const { t } = useTranslation();
  const [editAddress, setEditAddress] = useState<boolean>(false);
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [address, setAddress] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (item: ChangeItem) => {
    if (item === ChangeItem.TITLE) {
      await Server.location.updateLocationName(
        props.location.id,
        groupId,
        title
      );
      props.setResponseMsg &&
        props.setResponseMsg({
          responseType: 'Success',
          message: `${t('actions.updated')} ${t(
            'locations.name'
          ).toLowerCase()}`,
          showMessage: true,
        });
    } else {
      await Server.location.updateAddress(props.location.id, groupId, address);
      props.setResponseMsg &&
        props.setResponseMsg({
          responseType: 'Success',
          message: `${t('actions.updated')} ${t(
            'locations.address'
          ).toLowerCase()}`,
          showMessage: true,
        });
    }
  };

  const changeEditStatus = (status: boolean, item: ChangeItem) => {
    if (item === ChangeItem.TITLE) {
      setEditTitle(status);
    } else {
      setEditAddress(status);
    }
  };

  const handleDeleteLocation = async () => {
    setIsLoading(true);
    const confirmAction = window.confirm(t('alerts.delete_location'));
    if (confirmAction) {
      const locationId = props.location.id;
      const response = await Server.location.deleteLocation(
        groupId,
        locationId
      );
      setIsLoading(false);
      props.setResponseMsg &&
        props.setResponseMsg({
          responseType: 'Success',
          message: `${t('actions.deleted')} ${props.location.name}`,
          showMessage: true,
        });
      return response;
    } else {
      setIsLoading(false);
      return;
    }
  };

  return (
    <div
      id="collapseOne"
      className={`accordion-collapse collapse ${props.className}`}
    >
      <div className="accordion-body d-flex flex-column gap-2">
        <div className="container-fluid bg-main-backround py-1 px-2 location_item_component">
          <div className="game-type location_label">{t('locations.name')}:</div>
          {!editTitle ? (
            <div className="location_text">
              <div>{props.location.name}</div>
              <button
                onClick={() => changeEditStatus(true, ChangeItem.TITLE)}
                className="btn reset-btn p-1"
              >
                <i className={`bi bi-pencil-square`}></i>
              </button>
            </div>
          ) : (
            <form className="p-2 gap-2 d-flex">
              <input
                onChange={(e) => setTitle(e.target.value)}
                className="form-control"
              ></input>
              <ButtonMain
                className="btn btn-outline-light d-flex gap-2"
                text={t('input_fields.button_submit')}
                onClick={() => onSubmit(ChangeItem.TITLE)}
                icon="check-circle"
              />
              <ButtonMain
                className="btn btn-outline-light d-flex gap-2"
                text={t('input_fields.button_cancel')}
                onClick={() => changeEditStatus(false, ChangeItem.TITLE)}
                icon="x-circle"
              />
            </form>
          )}
        </div>
        <div className="container-fluid bg-main-backround py-1 px-2 location_item_component">
          <div className="game-type location_label">
            {t('locations.address')}:
          </div>
          {!editAddress ? (
            <div className="location_text">
              <div>{props.location.address}</div>
              <button
                onClick={() => changeEditStatus(true, ChangeItem.ADDRESS)}
                className="btn reset-btn p-1"
              >
                <i className={`bi bi-pencil-square`}></i>
              </button>
            </div>
          ) : (
            <form className="p-2 gap-2 d-flex">
              <input
                onChange={(e) => setAddress(e.target.value)}
                className="form-control"
              ></input>
              <ButtonMain
                className="btn btn-outline-light d-flex gap-2"
                text={t('input_fields.button_submit')}
                onClick={() => onSubmit(ChangeItem.ADDRESS)}
                icon="check-circle"
              />
              <ButtonMain
                className="btn btn-outline-light d-flex gap-2"
                text={t('input_fields.button_cancel')}
                onClick={() => changeEditStatus(false, ChangeItem.ADDRESS)}
                icon="x-circle"
              />
            </form>
          )}
        </div>
        <div className="d-flex justify-content-end">
          <ButtonMain
            className="btn btn-primary bg-danger text-white d-flex gap-1"
            icon="bi bi-trash-fill"
            text={t('actions.delete')}
            onClick={() => handleDeleteLocation()}
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AccordionList;
