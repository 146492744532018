interface UserNameProps {
  name?: string;
  isMvpInLastGames: [{ type: string; status: boolean }];
}

const UserName = (props: UserNameProps) => {
  return (
    <h1 className="fst-italic profile-name">
      {props.name}{' '}
      {props.isMvpInLastGames.map((x, i) => {
        return (
          x.status && (
            <span key={i} className={`banner ${'mvp'}`}>
              <i
                className={`bi bi-trophy${x.type === 'Futsal' ? '-fill' : ''}`}
              ></i>
            </span>
          )
        );
      })}
    </h1>
  );
};

export default UserName;
