import { BidGameServer } from './server-subclasses/bidGame.server';
import { DashboardServer } from './server-subclasses/dashboard.server';
import { EmailServer } from './server-subclasses/email.server';
import { GameServer } from './server-subclasses/game.server';
import { GroupServer } from './server-subclasses/group.server';
import { LocationServer } from './server-subclasses/loaction.server';
import { MvpServer } from './server-subclasses/mvp.server';
import { UserServer } from './server-subclasses/user.server';
import { UserGamesServer } from './server-subclasses/userGames.server';
import { UserGroupServer } from './server-subclasses/userGroup.server';

export class Server {
  static user: UserServer = new UserServer();
  static game: GameServer = new GameServer();
  static group: GroupServer = new GroupServer();
  static mvp: MvpServer = new MvpServer();
  static dashboard: DashboardServer = new DashboardServer();
  static email: EmailServer = new EmailServer();
  static bidGame: BidGameServer = new BidGameServer();
  static userGroup: UserGroupServer = new UserGroupServer();
  static location: LocationServer = new LocationServer();
  static userGames: UserGamesServer = new UserGamesServer();
}
