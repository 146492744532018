import { t } from 'i18next';

export const gameTypeCreateDefault = {
  value: '-1',
  text: t('input_fields.select'),
};

export const locationCreateDefault = {
  value: '-1',
  text: t('input_fields.select'),
};
