import { SelectFieldProps } from '../../types/props.types';
import { t } from 'i18next';

const SelectField = (props: SelectFieldProps) => {
  const {
    label,
    options,
    className,
    multiple,
    id,
    defaultValue,
    eventHandler,
    value,
  } = props;

  return (
    <div className={`mb-3 ${className}`}>
      <label className="form-label">
        <small>{label}</small>
      </label>
      <div className="input-group">
        <select
          placeholder={t('input_fields.select')}
          className={`form-select`}
          id={id}
          multiple={multiple}
          onChange={eventHandler}
          defaultValue={defaultValue}
          value={value}
        >
          {options.map((option, index) => {
            return (
              <option key={index} value={option.value} id={option.id}>
                {option.text}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default SelectField;
