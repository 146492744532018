import ReactDOM from 'react-dom';
import './backdrop.component.css';

interface BackdropProps {
  onClick: (e: boolean) => void;
}

const Backdrop = (props: BackdropProps) => {
  const element = document.getElementById('backdrop-hook');

  return ReactDOM.createPortal(
    <div className="backdrop" onClick={() => props.onClick(false)}></div>,
    element!
  );
};

export default Backdrop;
