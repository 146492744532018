import AccordionDate from './accordion-header/date/accordion-date.component';
import AccordionList from './accordion-list/accordion-list.component';
import { useCallback, useEffect, useState } from 'react';
import GameItemWrapper from '../../../container/game-item-container/game-item.container';
import {
  BidGameDtoRes,
  GameWithUserGamesDto,
  UserGameDto,
} from '../../../types/dtos.types';
import { SetTeamResultColorScheme } from '../../../utilities/data/set-color-scheme/set-team-result-color-scheme';
import { GameItemProps } from '../../../types/props.types';
import { Server } from '../../../server/server';
import BettingResults from '../../../components/card/past-game-element/past-game-bidding-result.component';
import AccordionGameCompareData from './accordion-header/game-data/accordion-game-compare-data.component';

const GameCompareItem = (props: GameItemProps) => {
  const game = props.game;
  const className = props.listItems?.[game.id] ? `show` : `hide`;
  const activeState = (props.listItems && props.listItems?.[game.id]) || false;
  const [isLoading, setLoading] = useState(true);
  const [players, setPlayers] = useState<UserGameDto[]>();
  const [scoreState, setScoreState] = useState<{
    team1State: string;
    team2State: string;
  }>({ team1State: '', team2State: '' });
  const [bettingResults, setBettingResults] = useState<BidGameDtoRes>();
  const [totalBets, setTotalBets] = useState(0);

  const getResults = useCallback(async () => {
    const res = await Server.bidGame.getBidGame(game.id);
    if (!res) {
      return;
    }
    const sum = res.firstTeam + res.secondTeam + res.draw;
    setTotalBets(sum);
    if (sum === 0) return;
    setBettingResults((prevState) => ({
      ...prevState,
      firstTeam: parseFloat(((100 * res.firstTeam) / sum).toFixed(1)),
      secondTeam: parseFloat(((100 * res.secondTeam) / sum).toFixed(1)),
      draw: parseFloat(((100 * res.draw) / sum).toFixed(1)),
    }));
  }, [game.id]);

  /////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const colorScheme = () => {
      const state = SetTeamResultColorScheme(game);
      state && setScoreState(state);
    };
    getResults();
    colorScheme();
  }, [game, getResults]);

  /////////////////////////////////////////////////////////////////////////////////////////
  const accordionHandler = () => {
    const getGameWithUserGames = async () => {
      setLoading(true);
      const gameResponse: GameWithUserGamesDto =
        await Server.game.getGameWithUserGames(game.id);
      const players = gameResponse.userGames;
      setPlayers(players);
      setLoading(false);
    };

    if (!activeState && props.onListItem) {
      props.onListItem(game.id, true);
      getGameWithUserGames();
    }
    if (activeState && props.onListItem) {
      props.onListItem(game.id, false);
    }
  };

  return (
    <GameItemWrapper>
      <h2 className="accordion-header" id="headingOne">
        <button
          className={`accordion-button ${!activeState ? 'collapsed' : ''}`}
          disabled={false}
          onClick={accordionHandler}
        >
          <AccordionDate date={game.date} />
          <AccordionGameCompareData
            game={game}
            team1Color={scoreState.team1State}
            team2Color={scoreState.team2State}
            players={props.filteredPlayers}
          />
        </button>
      </h2>
      {!isLoading && (
        <>
          <AccordionList
            team1Color={scoreState.team1State}
            team2Color={scoreState.team2State}
            className={className}
            score={[game.team1Goals, game.team2Goals]}
            players={players}
            gameId={game.id}
            gameMvp={game.gameMVP}
            editOption={true}
            gameDate={game.date}
          >
            {bettingResults && (
              <BettingResults
                game={game}
                results={bettingResults}
                sum={totalBets}
              />
            )}
          </AccordionList>
        </>
      )}
    </GameItemWrapper>
  );
};

export default GameCompareItem;
