import ButtonMain from '../../components/buttons/button.component';
import { useTranslation } from 'react-i18next';
import { getFormattedDate } from '../../utilities/data/date-formatter/get-formatted-date';

interface BannedProps {
  banExp?: Date | null;
  buttonShow?: boolean;
}

const Banned = (props: BannedProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="container-fluid min-vh-100 d-flex justify-content-center align-items-center"
      style={{ backgroundImage: 'url(/images/pattern.png)' }}
    >
      <div className="login-wrapper bg-component-background p-5 px-5 border shadow-sm border-dark rounded position-relative">
        <h1 className="text-center login-title mb-4">{t('login.banned')}</h1>
        <div className="text-center mb-4">
          {t('login.banned_text')}
          <h3>
            <b>{getFormattedDate(props.banExp)}</b>
          </h3>
        </div>
        {props.buttonShow ? (
          <ButtonMain
            text={'Natrag'}
            icon=""
            className="btn btn-primary w-100"
            onClick={() => {
              window.location.reload();
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Banned;
