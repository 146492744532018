import { FC } from 'react';
import { ButtonProps } from '../../types/props.types';
import ButtonSpinner from '../spinner/button-spinner/button-spinner.component';

const ButtonMain: FC<ButtonProps> = ({
  className,
  text,
  icon,
  onClick,
  type,
  disabled,
}) => {
  return (
    <button
      className={`btn d-flex justify-content-center ${className}`}
      onClick={onClick}
      type={type}
      disabled={disabled ? disabled : false}
    >
      {disabled === true ? (
        <div className="d-flex align-items-center justify-content-center">
          <div>
            <ButtonSpinner />{' '}
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          {icon ? <i className={`bi bi-${icon}`}></i> : <></>}
        </div>
      )}
      {text ? (
        <div className={` ${icon ? 'd-none' : ''} d-md-block`}>{text}</div>
      ) : (
        <></>
      )}
    </button>
  );
};

export default ButtonMain;
