import TabsNavigation from '../../components/tabs-navigation/tabs-navigation.component';
import { PersonalSettingsData } from '../../constants/routes/routes.constants';
import { Outlet } from 'react-router-dom';
import './settings.page.css';

const Settings = () => {
  return (
    <>
      <TabsNavigation tabs={PersonalSettingsData} />
      <Outlet />
    </>
  );
};

export default Settings;
