import { useState, useEffect, useCallback } from 'react';
import { TrophyCardProps } from '../../../types/props.types';
import './trophy-card.component.css';
import { useTranslation } from 'react-i18next';
import { dateFormatter } from '../../../utilities/data/date-formatter/date-formatter';
import { NavLink } from 'react-router-dom';
import { SetTeamResultColorScheme } from '../../../utilities/data/set-color-scheme/set-team-result-color-scheme';
import AccordionList from '../../../pages/games/game-item/accordion-list/accordion-list.component';
import BettingResults from '../past-game-element/past-game-bidding-result.component';
import { BidGameDtoRes } from '../../../types/dtos.types';
import { Server } from '../../../server/server';

const TrophyCard = (props: TrophyCardProps) => {
  const { t } = useTranslation();
  const [accordionState, setAccordionState] = useState(false);
  const { userGames, type, gameMVP, date } = props.game;
  const dateFormatted = dateFormatter(date);
  const [scoreState, setScoreState] = useState<{
    team1State: string;
    team2State: string;
  }>({ team1State: '', team2State: '' });
  const [bettingResults, setBettingResults] = useState<BidGameDtoRes>();
  const [totalBets, setTotalBets] = useState(0);
  const playerName = userGames.find((x) => x.userId === gameMVP)?.name;
  const icon = props.game.type === 'Futsal' ? '-f' : '-c';

  const getResults = useCallback(async () => {
    const res = await Server.bidGame.getBidGame(props.game.id);
    if (!res) {
      return;
    }
    const sum = res.firstTeam + res.secondTeam + res.draw;
    setTotalBets(sum);
    if (sum === 0) return;
    setBettingResults((prevState) => ({
      ...prevState,
      firstTeam: parseFloat(((100 * res.firstTeam) / sum).toFixed(1)),
      secondTeam: parseFloat(((100 * res.secondTeam) / sum).toFixed(1)),
      draw: parseFloat(((100 * res.draw) / sum).toFixed(1)),
    }));
  }, [props.game.id]);

  useEffect(() => {
    const colorScheme = () => {
      const state = SetTeamResultColorScheme(props.game);
      state && setScoreState(state);
    };
    getResults();
    colorScheme();
  }, [props.game, getResults]);
  const toggleAccordion = () => {
    setAccordionState(!accordionState);
  };

  return (
    <>
      <div
        className={`col-12 py-small my-2 ${
          accordionState ? 'bg-component-background' : ''
        }`}
      >
        <div className="container p-0 trophy-container py-2">
          <div className="d-flex  align-items-center p-1">
            {playerName ? (
              <>
                <div className="trophy-icon flex-shrink-1 pe-1">
                  <img
                    src={`/images/trophy${icon}.png`}
                    className="mx-auto img-fluid award"
                    alt="logo"
                  ></img>
                </div>
                <div className="player-info flex-grow-1">
                  <p className="p-0 m-0 mvp-tag">{t('dashboard.mvp')}</p>
                  <NavLink to={`/players/${gameMVP}`} className="gold-name" end>
                    {playerName}
                  </NavLink>
                  <p className="p-0 m-0 game-type-tag">
                    {type} -{' '}
                    {`${dateFormatted.dd}.${dateFormatted.mm}.${dateFormatted.yyyy} - ${dateFormatted.hh}:${dateFormatted.min}`}
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="trophy-icon flex-shrink-1 pe-1">
                  <img
                    src={`/images/trophy-none.png`}
                    className="mx-auto img-fluid award"
                    alt="logo"
                  ></img>
                </div>
                <div className="flex-grow-1">
                  <p className="m-0 fw-bold no-votes">
                    {t(`dashboard.${gameMVP}`)}
                  </p>
                  <p className="p-0 m-0 game-type-tag">
                    {type} -{' '}
                    {`${dateFormatted.dd}.${dateFormatted.mm}.${dateFormatted.yyyy} - ${dateFormatted.hh}:${dateFormatted.min}`}
                  </p>
                </div>
              </>
            )}
            <div className="collapse-button ">
              <button className="btn btn-primary" onClick={toggleAccordion}>
                {t('dashboard.roster')}
              </button>
            </div>
          </div>

          <div className="col-12">
            <div className="accordion shadow-sm" id="accordionItem">
              <div className="accordion-item">
                <AccordionList
                  team1Color={scoreState.team1State}
                  team2Color={scoreState.team2State}
                  className={`mvp-card-accordion ${
                    accordionState ? 'show' : ''
                  }`}
                  score={[props.game.team1Goals, props.game.team2Goals]}
                  players={userGames}
                  gameId={props.game.id}
                >
                  {bettingResults && (
                    <BettingResults
                      game={props.game}
                      results={bettingResults}
                      sum={totalBets}
                    />
                  )}
                </AccordionList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrophyCard;
