import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import ButtonMain from '../../../../../../components/buttons/button.component';
import { Server } from '../../../../../../server/server';
import { getFormattedDate } from '../../../../../../utilities/data/date-formatter/get-formatted-date';
import { UserType } from '../../../../../../types/enums.types';
import { UserGroup } from '../../../../../../types/dtos.types';

interface PlayerAccordionListProps {
  playerId: string;
  className: string;
  userGroups: [UserGroup];
  isBanned: boolean;
  banExpiration: Date | null;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccordionList = (props: PlayerAccordionListProps) => {
  const isBanned = props.isBanned;
  const banExpiration = props.banExpiration;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleBanPlayer = useCallback(async () => {
    setIsLoading(true);
    if (isBanned) {
      const confirmAction = window.confirm(t('alerts.cancel_ban'));

      if (confirmAction) {
        await Server.user.handleBan(props.playerId, false);
        setIsLoading(false);
        props.setReload(!props.reload);
      } else {
        setIsLoading(false);
        return;
      }
    } else {
      const confirmAction = window.confirm(t('alerts.ban'));

      if (confirmAction) {
        await Server.user.handleBan(props.playerId, true);
        setIsLoading(false);
        props.setReload(!props.reload);
      } else {
        setIsLoading(false);
        return;
      }
    }
  }, [isBanned, props, t]);

  useEffect(() => {}, [isBanned]);

  return (
    <div
      id="collapseOne"
      className={`accordion-collapse collapse ${props.className}`}
    >
      <div className="accordion-body">
        <div className="container-fluid py-small-1 d-flex flex-column justify-content-center">
          {props.userGroups.length > 0 && (
            <div className="game-type mx-1 mb-1">{t('tab_items.groups')}</div>
          )}
          {props.userGroups.map((group) => {
            return (
              <div className="col-md-8 p-2 border rounded border-dark bg-main-backround d-flex justify-content-between align-items-center">
                <div>{group.group?.name}</div>
                {group.role === UserType.ADMIN ? (
                  <div className="game-type px-2">{t('players.admin')}</div>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
        <div className="mt-4 mb-2 mx-1 d-flex gap-3">
          <NavLink
            to={`/players/${props.playerId}`}
            className="sidebar-anchor /players"
          >
            <ButtonMain
              className="btn btn-primary d-flex gap-2"
              icon="eye-fill"
              text={t('players.profile_button')}
            ></ButtonMain>
          </NavLink>
          {isBanned ? (
            <div className="col-md-5 p-2 mx-1 border rounded border-danger bg-main-backround">
              {t('players.ban_until')}: {getFormattedDate(banExpiration)}
            </div>
          ) : (
            <></>
          )}
          {isBanned ? (
            <ButtonMain
              className="btn btn-info d-flex gap-2"
              icon="arrow-counterclockwise"
              text={t('players.cancel_ban')}
              onClick={() => handleBanPlayer()}
              disabled={isLoading}
            ></ButtonMain>
          ) : (
            <ButtonMain
              className="btn btn-warning d-flex gap-2"
              icon="slash-circle"
              text={t('players.ban')}
              onClick={() => handleBanPlayer()}
              disabled={isLoading}
            ></ButtonMain>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccordionList;
