import { FETCHER_POST } from '../../constants/variables/fetcher.constants';
import { fetcherMethod } from '../../server/fetch-method';
import { Server } from '../../server/server';
import { setAuthorisationToken } from '../local-storage/set-autorisation-token';
import { setLoggedUser } from '../local-storage/set-logged-user';

export const loginMethod = async (email: string, password: string) => {
  const body = { email: email, password: password };
  const response = await fetcherMethod(
    'auth/signin/',
    FETCHER_POST,
    JSON.stringify(body)
  );
  if (response.accessToken) {
    setAuthorisationToken(response.accessToken);
    const token = parseJwt(response.accessToken);
    const userResponse = await Server.user.getUserById(token.id);
    setLoggedUser(userResponse);

    return { token: response.accessToken, user: userResponse };
  }
  return response;
};

export function parseJwt(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
