export const registerInputFields: {
  label: string;
  errorMsg: string[] | undefined;
  name: string;
  type: string;
  icon: string;
  className: string;
  onChange: () => void;
}[] = [
  {
    label: 'newPassword',
    errorMsg: undefined,
    name: 'password',
    type: 'password',
    icon: 'bi bi-shield-fill',
    className: 'password',
    onChange: () => {},
  },
  {
    label: 'repeatedNewPassword',
    errorMsg: undefined,
    name: 'repeatPassword',
    type: 'password',
    icon: 'bi bi-shield-fill-check',
    className: 'repeatPassword',
    onChange: () => {},
  },
];

export const registerSubmitButton = [
  {
    text: 'Submit',
    className: 'btn btn-primary w-100',
    icon: '',
    onClick: (e: any) => {},
  },
];
