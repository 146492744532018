import ReactQuill from 'react-quill';
import {
  FETCHER_GET,
  FETCHER_PATCH,
  FETCHER_POST,
} from '../../constants/variables/fetcher.constants';
import { GroupDto, PlayerDto } from '../../types/dtos.types';
import { fetcherMethod } from '../fetch-method';

export class GroupServer {
  async getGroupStatistics(id: string) {
    const path = `group/groupanalytics?id=${id}`;
    return await fetcherMethod(path, FETCHER_GET);
  }

  async getGroup(id: string | undefined | null): Promise<GroupDto> {
    const path = `group?id=${id}`;
    const response = await fetcherMethod(path, FETCHER_GET);
    return response;
  }

  async getAllGroups(): Promise<any> {
    const path = `group/all`;
    return await fetcherMethod(path, FETCHER_GET);
  }

  async createGroup(bodyObj: {
    group: { name: string; groupRules: string };
    players: {
      name: string;
      id?: string;
    }[];
  }): Promise<any> {
    const path = `group/new`;
    const body = JSON.stringify(bodyObj);
    const response = await fetcherMethod(path, FETCHER_POST, body);
    return response;
  }

  async addNewPlayer(groupId: string | undefined | null, player: PlayerDto) {
    const path = 'group/addnewplayer';
    const { name, id } = player;
    const bodyObj = {
      groupId: groupId,
      player: {
        name,
        id,
      },
    };
    const body = JSON.stringify(bodyObj);
    const response = await fetcherMethod(path, FETCHER_POST, body);
    return response;
  }

  async setGroupRules(
    groupId: string | undefined | null,
    groupRules: ReactQuill.Value
  ) {
    const path = 'group/setrules';
    const setGroupRules = {
      groupId: groupId,
      groupRules: groupRules,
    };
    const body = JSON.stringify(setGroupRules);
    const response = await fetcherMethod(path, FETCHER_PATCH, body);
    return response;
  }

  async updateGroupName(groupId: string, name: string) {
    const path = `group/updatename`;
    const bodyObj = {
      id: groupId,
      name: name,
    };

    const body = JSON.stringify(bodyObj);
    const response = await fetcherMethod(path, FETCHER_PATCH, body);
    return response;
  }
}
