import { t } from 'i18next';
import { selectInputDefaultValue } from './game-filter-defaults.constant';

const OPTIONS_COLLAB_TOGETHER = t('games.together');
const OPTIONS_COLLAB_AGAINST = t('games.against');

export const OPTIONS_COLLAB = [
  { ...selectInputDefaultValue, id: '' },
  { value: '1', text: OPTIONS_COLLAB_TOGETHER, id: '=' },
  { value: '2', text: OPTIONS_COLLAB_AGAINST, id: '<>' },
];
