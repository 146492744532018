import { FC } from 'react';
import RowContainer from '../../../container/row-container/row.container';
import { ComparisonStats } from '../../../types/dtos.types';
import { t } from 'i18next';
import ButtonMain from '../../../components/buttons/button.component';
import { GameType } from '../../../types/enums.types';

const BaseComparisonTable: FC<{
  baseStats: {
    data: ComparisonStats;
    team1: string[];
    team2: string[];
  };
  dates: string[] | undefined;
  filterGamesByType: (type: string) => void;
}> = ({ baseStats, dates, filterGamesByType }) => {
  const gamesWon =
    baseStats.data.gamesWonCageBall + baseStats.data.gamesWonFutsal;
  const gamesLost =
    baseStats.data.gamesLostCageBall + baseStats.data.gamesLostFutsal;
  const gamesDraw =
    baseStats.data.gamesDrawCageBall + baseStats.data.gamesDrawFutsal;

  return (
    <>
      <RowContainer className="mt-5 m-1">
        <h2 className="text-center">
          {t('titles.num_of_played')} {baseStats.data.gamesPlayed}
        </h2>
        <h4 className="text-center sport-types-found d-flex flex-column">
          <span className="game-Futsal">
            {`${GameType.FUTSAL}`}: {baseStats.data.gamesFutsal}
          </span>{' '}
          <span className="game-CageBall">
            {`${GameType.CAGEBALL}`}: {baseStats.data.gamesCageBall}
          </span>
        </h4>
        <h6 className="text-center text-uppercase d-flex flex-column">
          <span>{`${t('profile.draws')}: ${gamesDraw}`}</span>
          <small>
            {`(${baseStats.data.gamesDrawFutsal} ${GameType.FUTSAL},
            ${baseStats.data.gamesDrawCageBall} ${GameType.CAGEBALL})`}
          </small>
        </h6>
      </RowContainer>
      <RowContainer className="mt-1 ms-auto w-100">
        {baseStats.data.gamesPlayed !== 0 ? (
          <div className="row p-0 text-center py-2 mb-1 bg-component-background d-flex justify-content-center align-items-center">
            {`${dates?.[0]} - ${dates?.[1]}`}
          </div>
        ) : (
          <></>
        )}
        <div className="row p-0">
          <div className="col-5 col-md-4 text-center bg-component-background py-3 d-flex justify-content-center align-items-center player-compare text-uppercase">
            {baseStats.team1.toString()}
          </div>
          <div className="col-2 col-md-4 text-center py-3"></div>
          <div className="col-5 col-md-4 text-center bg-component-background py-3 d-flex justify-content-center align-items-center player-compare text-uppercase">
            {baseStats.team2.toString()}
          </div>
        </div>
        <div className="row border-top border-2 p-0 border-dark-custom">
          <div className="col-5 col-md-4 text-center bg-component-background py-3 d-flex justify-content-center align-items-center">
            {gamesWon}{' '}
            {gamesWon !== gamesLost ? (
              <span
                className={`result-${
                  gamesWon > gamesLost ? 'win' : 'loss'
                } ps-1`}
              >
                <i
                  className={`bi bi-arrow-${
                    gamesWon > gamesLost ? 'up' : 'down'
                  }-circle-fill`}
                ></i>
              </span>
            ) : (
              <span className={`result-draw ps-1`}>
                <i className={`bi bi-circle-fill`}></i>
              </span>
            )}
          </div>
          <div className="col-2 col-md-4 text-center py-3">
            <ButtonMain
              className="text-white border game-type mx-auto"
              text={'S'}
              onClick={() => filterGamesByType('all')}
            />
          </div>
          <div className="col-5 col-md-4 text-center bg-component-background py-3 d-flex justify-content-center align-items-center">
            {gamesLost}
            {gamesWon !== gamesLost ? (
              <span
                className={`result-${
                  gamesLost > gamesWon ? 'win' : 'loss'
                } ps-1`}
              >
                <i
                  className={`bi bi-arrow-${
                    gamesWon < gamesLost ? 'up' : 'down'
                  }-circle-fill`}
                ></i>
              </span>
            ) : (
              <span className={`result-draw ps-1`}>
                <i className={`bi bi-circle-fill`}></i>
              </span>
            )}
          </div>
        </div>
        <div className="row border-top border-2  p-0 border-dark-custom">
          <div className="col-5 col-md-4 text-center bg-component-background py-3 d-flex justify-content-center align-items-center">
            {baseStats.data.gamesWonFutsal}
            {baseStats.data.gamesWonFutsal !==
            baseStats.data.gamesLostFutsal ? (
              <span
                className={`result-${
                  baseStats.data.gamesWonFutsal > baseStats.data.gamesLostFutsal
                    ? 'win'
                    : 'loss'
                } ps-1`}
              >
                <i
                  className={`bi bi-arrow-${
                    baseStats.data.gamesWonFutsal >
                    baseStats.data.gamesLostFutsal
                      ? 'up'
                      : 'down'
                  }-circle-fill`}
                ></i>
              </span>
            ) : (
              <span className={`result-draw ps-1`}>
                <i className={`bi bi-circle-fill`}></i>
              </span>
            )}
          </div>
          <div className="col-2 col-md-4 text-center py-3 text-white ">
            <ButtonMain
              className="text-white border game-type mx-auto"
              text={'F'}
              onClick={() => filterGamesByType(GameType.FUTSAL)}
            />
          </div>
          <div className="col-5 col-md-4 text-center bg-component-background py-3 d-flex justify-content-center align-items-center">
            {baseStats.data.gamesLostFutsal}{' '}
            {baseStats.data.gamesWonFutsal !==
            baseStats.data.gamesLostFutsal ? (
              <span
                className={`result-${
                  baseStats.data.gamesWonFutsal < baseStats.data.gamesLostFutsal
                    ? 'win'
                    : 'loss'
                } ps-1`}
              >
                <i
                  className={`bi bi-arrow-${
                    baseStats.data.gamesWonFutsal <
                    baseStats.data.gamesLostFutsal
                      ? 'up'
                      : 'down'
                  }-circle-fill`}
                ></i>
              </span>
            ) : (
              <span className={`result-draw ps-1`}>
                <i className={`bi bi-circle-fill`}></i>
              </span>
            )}
          </div>
        </div>
        <div className="row border-top border-2  p-0 border-dark-custom">
          <div className="col-5 col-md-4 text-center bg-component-background py-3 d-flex justify-content-center align-items-center">
            {baseStats.data.gamesWonCageBall}{' '}
            {baseStats.data.gamesWonCageBall !==
            baseStats.data.gamesLostCageBall ? (
              <span
                className={`result-${
                  baseStats.data.gamesWonCageBall >
                  baseStats.data.gamesLostCageBall
                    ? 'win'
                    : 'loss'
                } ps-1`}
              >
                <i
                  className={`bi bi-arrow-${
                    baseStats.data.gamesWonCageBall >
                    baseStats.data.gamesLostCageBall
                      ? 'up'
                      : 'down'
                  }-circle-fill`}
                ></i>
              </span>
            ) : (
              <span className={`result-draw ps-1`}>
                <i className={`bi bi-circle-fill`}></i>
              </span>
            )}
          </div>
          <div className="col-2 col-md-4 text-center py-3 text-white">
            <ButtonMain
              className="text-white border game-type mx-auto"
              text={'C'}
              onClick={() => filterGamesByType(GameType.CAGEBALL)}
            />
          </div>
          <div className="col-5 col-md-4 text-center bg-component-background py-3 d-flex justify-content-center align-items-center">
            {baseStats.data.gamesLostCageBall}{' '}
            {baseStats.data.gamesWonCageBall !==
            baseStats.data.gamesLostCageBall ? (
              <span
                className={`result-${
                  baseStats.data.gamesWonCageBall <
                  baseStats.data.gamesLostCageBall
                    ? 'win'
                    : 'loss'
                } ps-1`}
              >
                <i
                  className={`bi bi-arrow-${
                    baseStats.data.gamesWonCageBall <
                    baseStats.data.gamesLostCageBall
                      ? 'up'
                      : 'down'
                  }-circle-fill`}
                ></i>
              </span>
            ) : (
              <span className={`result-draw ps-1`}>
                <i className={`bi bi-circle-fill`}></i>
              </span>
            )}
          </div>
        </div>
      </RowContainer>
    </>
  );
};

export default BaseComparisonTable;
