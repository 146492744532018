import { NavLink } from 'react-router-dom';
import { UserWithStatsDto } from '../../../../types/dtos.types';
import ButtonMain from '../../../../components/buttons/button.component';
import { useTranslation } from 'react-i18next';

interface TableBodyProps {
  user: UserWithStatsDto;
}

const TableBody = (props: TableBodyProps) => {
  const { t } = useTranslation();
  const user = props.user;
  return (
    <tr>
      <td className="align-middle">{user.name} </td>
      <td className="align-middle">
        {user.gamesWon}% ({user.gamesPlayed})
      </td>
      <td className="align-middle">
        {user.gamesFutsal}% ({user.gamesPlayedFutsal})
      </td>
      <td className="align-middle">
        {user.gamesCageBall}% ({user.gamesPlayedCageBall})
      </td>
      <td className="align-middle">
        {user.bettingWon}% ({user.betting})
      </td>
      <td className="align-middle">
        <NavLink to={`/players/${user.id}`} className="sidebar-anchor /players">
          <ButtonMain
            className="btn btn-primary d-flex gap-2"
            icon="eye-fill"
            text={t('players.profile_button')}
          ></ButtonMain>
        </NavLink>
      </td>
    </tr>
  );
};

export default TableBody;
