import { useContext, useEffect, useState } from 'react';
import RowContainer from '../../../../container/row-container/row.container';
import { SelectOptionsProps } from '../../../../types/props.types';
import { Server } from '../../../../server/server';
import InputField from '../../../../components/inputs/input-field.component';
import ButtonMain from '../../../../components/buttons/button.component';
import { t } from 'i18next';
import { comparisonFilterDto } from '../../../../types/dtos.types';
import { comparisonFilterDefault } from '../../../../constants/variables/game-filter-default';
import { GroupContext } from '../../../../App';
import { getLoggedUser } from '../../../../utilities/local-storage/get-logged-user';
import ChoosePossiblePlayers from '../../../../components/possible-players/ChoosePossiblePlayers';
import { Teams } from '../../../../types/enums.types';

interface BaseComparisonFilterProps {
  gameFetch: (gameFilterState: comparisonFilterDto) => void;
  isLoading: boolean;
  setLoading: (e: boolean) => void;
}

const BaseComparisonFilter = (props: BaseComparisonFilterProps) => {
  const { groupId } = useContext(GroupContext);
  const user = getLoggedUser();
  const [isBannedFromGroup, setIsBannedFromGroup] = useState<boolean>();
  const [filteringOptions, setFilteringOptions] = useState<{
    userList: SelectOptionsProps[];
  }>();
  const [gameFilterState, setGameFilterState] = useState<comparisonFilterDto>(
    comparisonFilterDefault
  );
  const { setLoading } = props;

  useEffect(() => {
    const displayFilter = async () => {
      setLoading(true);
      try {
        const userListResponse = await Server.user.getUsersFromGroup(groupId);
        const userListOptions = userListResponse.map((x, i) => {
          return { value: i.toString(), text: x.name, id: x.id };
        });
        setFilteringOptions({
          userList: [...userListOptions],
        });
        setLoading(false);
      } catch (err) {
        alert(t('alerts.fetch_data_error'));
      }
    };

    const fetchingBanStatus = async (userId: string, groupId: string) => {
      const userGroup = await Server.userGroup.getUserGroupByGroupId(
        userId,
        groupId
      );
      if (userGroup.isBanned) {
        const now = new Date();
        if (userGroup.banExpiration) {
          const expiration = new Date(userGroup.banExpiration);
          if (now > expiration) {
            await Server.userGroup.handleBanFromGroup(user.id, groupId, false);
            setIsBannedFromGroup(false);
          }
        }
      }
      setIsBannedFromGroup(userGroup.isBanned);
    };
    fetchingBanStatus(user.id, groupId);
    displayFilter();
  }, [groupId, setLoading, user.id]);

  const handleSelectTeam = (
    selectedOption: { value: string; label: string; id?: string },
    team?: Teams.TEAM1 | Teams.TEAM2
  ) => {
    if (team)
      setGameFilterState((prevVal) => {
        const currentTeamArray = prevVal[team];
        const optionExists = currentTeamArray.some(
          (option) => option.id === selectedOption.id
        );
        let updatedTeamArray;
        if (optionExists) {
          updatedTeamArray = currentTeamArray.filter(
            (option) => option.id !== selectedOption.id
          );
        } else {
          updatedTeamArray = [
            ...currentTeamArray,
            { name: selectedOption.label, id: selectedOption.id },
          ];
        }

        return { ...prevVal, [team]: updatedTeamArray };
      });
  };

  const isSelectedInTeam = (id?: string, team?: Teams.TEAM1 | Teams.TEAM2) => {
    if (team) {
      let allSelectedIds: string[] = [];
      if (team === Teams.TEAM1) {
        allSelectedIds = gameFilterState.team1.map((item) => item.id);
      } else {
        allSelectedIds = gameFilterState.team2.map((item) => item.id);
      }
      if (id && allSelectedIds.includes(id)) {
        return true;
      } else return false;
    }
  };

  return groupId ? (
    !isBannedFromGroup ? (
      !props.isLoading ? (
        <>
          <RowContainer className="justify-content-center bg-component-background rounded border border-dark p-3">
            {filteringOptions?.userList && (
              <div className="player_select my-3">
                <div className="col-md-5 mb-2">
                  <ChoosePossiblePlayers
                    title={`${t('teams.team')} 1:`}
                    userList={filteringOptions.userList}
                    handleSelectByTeam={handleSelectTeam}
                    team={Teams.TEAM1}
                    isSelectedInTeam={isSelectedInTeam}
                  />
                </div>
                <div className="col-md-5">
                  <ChoosePossiblePlayers
                    title={`${t('teams.team')} 2:`}
                    userList={filteringOptions.userList}
                    handleSelectByTeam={handleSelectTeam}
                    team={Teams.TEAM2}
                    isSelectedInTeam={isSelectedInTeam}
                  />
                </div>
              </div>
            )}
            <div className="d-flex flex-column mx-2 bg-main-backround rounded border-dark shadow col-md-6 py-2 justify-content-center gap-1">
              <div className="col-md-12">
                <InputField
                  label={t('input_fields.from')}
                  type="date"
                  name="date-from"
                  defaultValue={
                    new Date(
                      new Date().getFullYear() - 3,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                      .toISOString()
                      .split('T')[0]
                  }
                  onChange={(e) =>
                    setGameFilterState((prevState) => ({
                      ...prevState,
                      date: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="col-md-12">
                <InputField
                  label={t('input_fields.to')}
                  type="date"
                  name="date-to"
                  defaultValue={new Date().toISOString().split('T')[0]}
                  onChange={(e) =>
                    setGameFilterState((prevState) => ({
                      ...prevState,
                      dateTo: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="col-md-12 mt-3 text-center d-flex justify-content-center">
              <ButtonMain
                text={t('games.filter')}
                className="btn-primary"
                type="submit"
                onClick={() => props.gameFetch(gameFilterState)}
                disabled={props.isLoading}
              />
            </div>
          </RowContainer>
        </>
      ) : (
        <></>
      )
    ) : (
      <></>
    )
  ) : (
    <></>
  );
};

export default BaseComparisonFilter;
