import { useContext, useEffect, useState } from 'react';
import { Server } from '../../server/server';
import { GroupContext } from '../../App';
import { useLocalizer } from '../../localization/Localization';
import Spinner from '../../components/spinner/spinner-component';
import ReactQuill from 'react-quill';

const RuleBook = () => {
  const localizer = useLocalizer();
  const [isLoading, setLoading] = useState(true);
  const [rules, setRules] = useState<ReactQuill.Value>('');
  const { groupId } = useContext(GroupContext);
  useEffect(() => {
    const ruleBookFetch = async () => {
      setLoading(true);
      const groupResponse = await Server.group.getGroup(groupId);
      if (groupResponse.groupRules) {
        setRules(groupResponse.groupRules);
      } else {
        setRules('');
      }

      setLoading(false);
    };
    ruleBookFetch();
  }, [groupId]);

  return (
    <>
      {!isLoading ? (
        <div>
          <h1>{localizer.rulebook}</h1>
          <div dangerouslySetInnerHTML={{ __html: rules.toString() }}></div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default RuleBook;
