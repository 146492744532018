import GameItem from '../game-item/game-item.component';
import './games.page.css';
import { useContext, useEffect, useState } from 'react';
import GameFilter from '../game-filter/game-filter.component';
import { GameFilterDto, GameWithUserGamesDto } from '../../../types/dtos.types';
import Spinner from '../../../components/spinner/spinner-component';
import Pagination from '../../../components/pagination/pagination.component';
import { Server } from '../../../server/server';
import { gameStatus } from '../../../constants/variables/game-status';
import { useTranslation } from 'react-i18next';
import { GroupContext } from '../../../App';
import { getLoggedUser } from '../../../utilities/local-storage/get-logged-user';
import Banned from '../../login/banned.component';

const Games = () => {
  const { t } = useTranslation();
  const { groupId } = useContext(GroupContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const user = getLoggedUser();
  const [isBannedFromGroup, setIsBannedFromGroup] = useState<boolean>();
  const [banExp, setBanExp] = useState<Date | null | undefined>();

  const [gameResponse, setGameResponse] = useState<{
    games: GameWithUserGamesDto[];
    count: number;
  }>(null as any);

  const [listItems, setListItems] = useState<{ [gameId: string]: boolean }>({});

  const [paginationOffset, setPaginationOffset] = useState<number>(0);

  const [gameFilter, setGameFilter] = useState<GameFilterDto>({});

  /////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const pagination = { take: 10, offset: paginationOffset };
    const gameFetch = async () => {
      setIsLoading(true);
      const gamesResponse = await Server.game.getGamesWithFilter(
        groupId,
        gameFilter,
        pagination,
        gameStatus.FINISHED
      );
      const fetchingBanStatus = async (userId: string, groupId: string) => {
        const userGroup = await Server.userGroup.getUserGroupByGroupId(
          userId,
          groupId
        );
        if (userGroup.isBanned) {
          const now = new Date();
          if (userGroup.banExpiration) {
            const expiration = new Date(userGroup.banExpiration);
            if (now > expiration) {
              await Server.userGroup.handleBanFromGroup(
                user.id,
                groupId,
                false
              );
              setIsBannedFromGroup(false);
              setBanExp(null);
            }
          }
        }
        setIsBannedFromGroup(userGroup.isBanned);
        setBanExp(userGroup.banExpiration);
      };
      fetchingBanStatus(user.id, groupId);
      setGameResponse(gamesResponse);
      setIsLoading(false);
    };
    if (groupId) {
      gameFetch();
    }
  }, [paginationOffset, gameFilter, groupId, user.id]);

  /////////////////////////////////////////////////////////////////////////////////////////
  const onListItem = (gameId: string, isOpen: boolean) => {
    setListItems({ [gameId]: isOpen });
  };

  return (
    <>
      {groupId ? (
        !isBannedFromGroup ? (
          <>
            <GameFilter
              gameFilter={gameFilter}
              setGameFilter={setGameFilter}
              gamesLoading={isLoading}
              setGamesLoading={setIsLoading}
            />
            {!isLoading ? (
              <>
                <h2 className="sidebar-link regular-font text-center pt-2">
                  {t('games.found')}: {gameResponse.count}
                </h2>
                {gameResponse?.count > 0 ? (
                  gameResponse?.games.map((x) => {
                    return (
                      <GameItem
                        key={x.id}
                        game={x}
                        listItems={listItems}
                        onListItem={onListItem}
                      />
                    );
                  })
                ) : (
                  <div className="text-center">
                    <h1>
                      <i className="bi bi-emoji-neutral-fill"></i>
                    </h1>
                    <p>{t('games.no_games_found')}!</p>
                  </div>
                )}
                {!isLoading && gameResponse?.count >= 10 && (
                  <Pagination
                    gameCount={gameResponse.count}
                    paginationOffset={paginationOffset}
                    setPaginationOffset={setPaginationOffset}
                  />
                )}
              </>
            ) : (
              <Spinner />
            )}
          </>
        ) : (
          <Banned banExp={banExp} buttonShow={false} />
        )
      ) : (
        <div className="container-fluid d-flex justify-content-center align-items-center">
          <div className="login-wrapper bg-component-background p-5 px-5 border shadow-sm border-dark rounded position-relative">
            <h1 className="text-center login-title mb-4">
              {t('players.no_groups')}
            </h1>
            <div className="text-center mb-4">
              <h4>{t('players.no_groups_text')}</h4>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Games;
