import { FETCHER_POST } from '../../constants/variables/fetcher.constants';
import { VoteMvpDto } from '../../types/dtos.types';
import { fetcherMethod } from '../fetch-method';

export class MvpServer {
  async createVoteMvp(voteMvp: VoteMvpDto) {
    const path = 'vote-mvp/create';
    const body = JSON.stringify(voteMvp);
    const response = await fetcherMethod(path, FETCHER_POST, body);
    return response;
  }

  async calculateMvp(gameId: string) {
    const path = `vote-mvp/calculate?id=${gameId}`;
    const response = await fetcherMethod(path, FETCHER_POST);
    return response;
  }
}
