import { useState } from 'react';
import Spinner from '../../components/spinner/spinner-component';
import {
  comparisonFilterDto,
  ComparisonStats,
  GameWithUserGamesDto,
} from '../../types/dtos.types';
import BaseComparisonFilter from './player-comparison-components/base-comparison-filter/base-comparison-filter.component';
import BaseComparisonTable from './player-comparison-components/base-comparison.component';
import './compare-players.component.css';
import { Server } from '../../server/server';
import { calculateGameStatistics } from '../../utilities/data/calculate-statistics/comparison-statistics.utility';
import GeneralGroupStatistics from './group-statistics/general-group-statistics.component';
import { t } from 'i18next';
import GameCompareItem from './game-compare-item/game-compare-item.component';
import { GameType } from '../../types/enums.types';

const ComparePlayers = () => {
  //this state determines if table is either being loaded or just not displayed
  const [isLoading, setLoading] = useState<boolean>(false);

  const [isLoadingStats, setLoadingStats] = useState<boolean>(true);
  const [isLoadingFilter, setLoadingFilter] = useState<boolean>(true);
  const [isLoadingTable, setLoadingTable] = useState<boolean>(true);

  const [baseStats, setBaseStats] = useState<{
    data: ComparisonStats;
    team1: string[];
    team2: string[];
  }>();
  const [games, setGames] = useState<GameWithUserGamesDto[]>();
  const [filteredGames, setFilteredGames] = useState<GameWithUserGamesDto[]>();
  const [dates, setDates] = useState<string[]>();
  const [listItems, setListItems] = useState<{ [gameId: string]: boolean }>({});
  const [filteredPlayers, setFilteredPlayers] = useState<string[][]>();

  const onListItem = (gameId: string, isOpen: boolean) => {
    setListItems({ [gameId]: isOpen });
  };

  const calculateDates = (games: GameWithUserGamesDto[]) => {
    const dates = games.map((game) => new Date(game.date).getTime());
    const firstDate = new Date(Math.min(...dates));
    const lastDate = new Date(Math.max(...dates));

    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    const firstDateString = firstDate
      .toLocaleDateString('en-GB', options)
      .replace(/\//g, '.');
    const lastDateString = lastDate
      .toLocaleDateString('en-GB', options)
      .replace(/\//g, '.');

    return [firstDateString, lastDateString];
  };

  const gameFetch = async (gameFilterState: comparisonFilterDto) => {
    setLoading(true);
    setLoadingTable(true);

    if (gameFilterState.team1.length === 0) {
      alert(t('alerts.left_team_empty'));
      setLoadingTable(true);
      setLoading(false);
      return;
    }

    const gameFilter = {
      date: gameFilterState?.date,
      dateTo: gameFilterState?.dateTo,
      players: [
        gameFilterState.team1.map((x) => x.id),
        gameFilterState.team2.map((x) => x.id),
      ],
    };
    setFilteredPlayers(gameFilter.players);

    const gamesResponse = await Server.game.getGamesWithMultiplePlayers(
      gameFilter
    );
    const comparisonData = calculateGameStatistics(
      gamesResponse,
      gameFilter.players
    );
    setGames(gamesResponse.games);
    setFilteredGames(gamesResponse.games);
    setDates(calculateDates(gamesResponse.games));
    setBaseStats({
      data: comparisonData,
      team1: gameFilterState.team1.map((x) => x.name),
      team2: gameFilterState.team2.map((x) => x.name),
    });
    setLoading(false);
    setLoadingTable(false);
  };

  const filterGamesByType = (type: string) => {
    if (type === GameType.FUTSAL) {
      setFilteredGames(games?.filter((x) => x.type === GameType.FUTSAL));
    } else if (type === GameType.CAGEBALL) {
      setFilteredGames(games?.filter((x) => x.type === GameType.CAGEBALL));
    } else {
      setFilteredGames(games);
    }
  };

  return (
    <>
      <div>
        <div className="container">
          <GeneralGroupStatistics
            isLoading={isLoadingStats}
            setLoading={setLoadingStats}
          />
        </div>
        {!isLoadingStats && (
          <div className="container mt-4">
            <h5 className="text-center">{t('titles.compare')}</h5>
            <BaseComparisonFilter
              gameFetch={gameFetch}
              isLoading={isLoadingFilter}
              setLoading={setLoadingFilter}
            />
          </div>
        )}
      </div>
      {!isLoadingTable ? (
        <div>
          <BaseComparisonTable
            baseStats={baseStats!}
            dates={dates}
            filterGamesByType={filterGamesByType}
          />
          {filteredGames?.length !== 0 ? (
            <div className="mt-5 mb-3">
              <h5 className="text-center">{t('titles.chosen_games')}</h5>
            </div>
          ) : (
            <></>
          )}
          <div style={{ height: '400px', overflow: 'auto' }}>
            {filteredGames?.map((x) => {
              return (
                <GameCompareItem
                  key={x.id}
                  game={x}
                  listItems={listItems}
                  onListItem={onListItem}
                  filteredPlayers={filteredPlayers}
                />
              );
            })}
          </div>
        </div>
      ) : isLoading ? (
        <Spinner />
      ) : (
        <></>
      )}
    </>
  );
};

export default ComparePlayers;
