import {
  FETCHER_PATCH,
  FETCHER_POST,
} from '../../constants/variables/fetcher.constants';
import { CredentialType } from '../../types/enums.types';
import { getLoggedUser } from '../../utilities/local-storage/get-logged-user';
import { fetcherMethod } from '../fetch-method';

export class EmailServer {
  private readonly basePath = 'email';

  async changeUserCredentials(
    typeOfChange: CredentialType,
    value?: string,
    id?: string
  ) {
    let path = '';
    let method: string;
    if (typeOfChange === CredentialType.EMAIL) {
      path = `${this.basePath}/change-email`;
      method = FETCHER_POST;
    } else if (typeOfChange === CredentialType.PASSWORD) {
      path = `user/update`;
      method = FETCHER_PATCH;
    }

    const changeCredentials = async (value?: string) => {
      const body = JSON.stringify({ [typeOfChange]: value, id: id });
      try {
        await fetcherMethod(path, method, body);
        return {
          message: `You have successfuly changed ${typeOfChange}`,
          status: 'Success',
        };
      } catch (err) {
        return err;
      }
    };

    return await changeCredentials(value);
  }

  async inviteUserInGroup(user: {
    name: string;
    email: string;
    groupId: string | undefined | null;
  }) {
    const path = `${this.basePath}/resend-invitation-to-group`;
    try {
      const bodyObj = {
        name: user.name,
        email: user.email,
        adminName: getLoggedUser().name,
        groupId: user.groupId,
      };
      const body = JSON.stringify(bodyObj);
      await fetcherMethod(path, FETCHER_POST, body);
      return {
        message: `You have invited ${user.name} to join Balunko!`,
        status: 'Success',
      };
    } catch (err) {
      return err;
    }
  }

  async inviteUser(user: { name: string; email: string }) {
    const path = `${this.basePath}/resend-invitation`;
    try {
      const bodyObj = {
        username: user.name,
        email: user.email,
        admin_name: getLoggedUser().name,
      };
      const body = JSON.stringify(bodyObj);
      await fetcherMethod(path, FETCHER_POST, body);
      return {
        message: `You have invited ${user.name} to join Balunko!`,
        status: 'Success',
      };
    } catch (err) {
      return err;
    }
  }
}
