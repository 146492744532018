import { IsOptional, IsString } from 'class-validator';
import { t } from 'i18next';

export class EditUserInfoFormValidation {
  @IsOptional()
  @IsString({
    message: () => {
      return t('validation.is_string');
    },
    groups: ['phone'],
  })
  public phone: string | undefined;

  @IsOptional()
  @IsString({
    message: () => {
      return t('validation.is_string');
    },
    groups: ['dateOfBirth'],
  })
  public dateOfBirth: string | undefined;

  @IsOptional()
  @IsString({
    message: () => {
      return t('validation.is_string');
    },
    groups: ['adress'],
  })
  public adress: string | undefined;

  @IsOptional()
  @IsString({
    message: () => {
      return t('validation.is_string');
    },
    groups: ['city'],
  })
  public city: string | undefined;

  @IsOptional()
  @IsString({
    message: () => {
      return t('validation.is_string');
    },
    groups: ['country'],
  })
  public country: string | undefined;

  @IsOptional()
  @IsString({
    message: () => {
      return t('validation.is_string');
    },
    groups: ['gender'],
  })
  public gender: string | undefined;
}
