import './superadmin-panel.page.css';
import 'react-quill/dist/quill.snow.css'; // ES6
import { Outlet } from 'react-router-dom';
import FluidContainer from '../../../container/fluid-container/fluid.container';
import TabsNavigation from '../../../components/tabs-navigation/tabs-navigation.component';
import RowContainer from '../../../container/row-container/row.container';
import { SuperadminPanelData } from '../../../constants/routes/routes.constants';

const Superadmin = () => {
  return (
    <>
      <FluidContainer>
        <TabsNavigation tabs={SuperadminPanelData} />
        <RowContainer>
          <Outlet />
        </RowContainer>
      </FluidContainer>
    </>
  );
};

export default Superadmin;
