import { t } from 'i18next';

export const ErrorInterceptor = (errorCode: number) => {
  if (errorCode === 401) {
    return {
      title: `${errorCode} Unauthorized`,
      message: t('errors.login'),
      className: 'slide-in',
    };
  }
  if (errorCode === 400) {
    return {
      title: `${errorCode} Bad request`,
      message: t('errors.check_input'),
      className: 'slide-in',
    };
  }
  if (errorCode === 403) {
    return {
      title: `${errorCode} Forbidden`,
      message: t('errors.no_permission'),
      className: 'slide-in',
    };
  }
  if (errorCode === 408) {
    return {
      title: `${errorCode} Request Time-Out`,
      message: t('errors.server_timeout'),
      className: 'slide-in',
    };
  }

  if (errorCode === 500) {
    return {
      title: `${errorCode} Fatal error`,
      message: t('errors.something_wrong'),
      className: 'notification-window-critical slide-in',
    };
  }
  if (errorCode === 505) {
    return {
      title: `${errorCode} HTTP Version Not supported`,
      message: t('errors.check_browser'),
      className: 'slide-in',
    };
  }
  return {
    title: `${errorCode} Something went wrong`,
    message: t('errors.something_died'),
    className: 'slide-in',
  };
};
