import { useContext, useState } from 'react';
import ActionMessage from '../../../components/action-message/action-message.component';
import RowContainer from '../../../container/row-container/row.container';
import InputForm from '../../../components/input-form/input-form';
import EditPlayer from '../edit-player/edit-player';
import { Server } from '../../../server/server';
import { useTranslation } from 'react-i18next';
import {
  addPlayerInputFields,
  addPlayerSubmitButton,
} from './constants/add-player-input-form.constants';
import { AddPlayerValidation } from '../../../utilities/validators/add-player.validator';
import { GroupContext } from '../../../App';
import { getLoggedUser } from '../../../utilities/local-storage/get-logged-user';

const formValidation = new AddPlayerValidation();
const AddNewPlayer = () => {
  const { groupId } = useContext(GroupContext);
  const { t } = useTranslation();
  const [state, setState] = useState({
    name: '',
    email: '',
  });
  const [msg, setMsg] = useState('');
  const [responseMsg, setResponseMsg] = useState<{
    responseType: string;
    message: (string | undefined)[] | undefined;
    showMessage: boolean;
  }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /////////////////////////////////////////////////////////////////////////////////////////
  const onSubmit = async () => {
    const user = {
      name: state.name,
      email: state.email,
      groupId: groupId,
      adminName: getLoggedUser().name,
    };

    setIsLoading(true);
    const response = await Server.user.createUserAdmin(user);
    if (response.statusCode === 409) {
      setMsg(t('validation.user_exists'));
      return false;
    }
    setIsLoading(false);

    setResponseMsg({
      responseType: 'Success',
      message: [`User ${user.name}(${user.email}) created.`],
      showMessage: true,
    });
  };

  return (
    <>
      {responseMsg?.showMessage ? (
        <ActionMessage
          message={t('input_fields.response_success_add_player')}
          type={
            responseMsg?.responseType
              ? responseMsg.responseType.toLowerCase()
              : 'success'
          }
          onClick={() =>
            setResponseMsg((prevState) => ({
              ...prevState!,
              showMessage: false,
            }))
          }
        />
      ) : (
        <div className="settings-tab-container">
          <h2 className="sidebar-link regular-font text-center pt-2">
            {t('titles.add_player')}
          </h2>
          <RowContainer>
            <InputForm
              inputFields={addPlayerInputFields}
              validation={formValidation}
              onSubmit={onSubmit}
              setState={setState}
              button={addPlayerSubmitButton}
              buttonDisabled={isLoading}
            />
            <div className="error-message text-center mt-4">{msg}</div>
            {responseMsg?.responseType === 'Error' && (
              <div className="col-md-12">
                {responseMsg?.message?.map((x, i) => (
                  <p key={i} className="text-danger">
                    {x}
                  </p>
                ))}
              </div>
            )}
          </RowContainer>
          <RowContainer className="mt-5">
            <EditPlayer />
          </RowContainer>
        </div>
      )}
    </>
  );
};

export default AddNewPlayer;
