import { BaseSyntheticEvent, useContext, useState } from 'react';
import ButtonMain from '../../components/buttons/button.component';
import { loginMethod } from '../../utilities/authentication/login';
import LoginForm from './form/login-form';
import LoginHeader from './header/login-header';
import './login.component.css';
import { useTranslation } from 'react-i18next';
import { LoggedInContext } from '../../App';
const Login = () => {
  const { t } = useTranslation();
  const { setLoggedIn } = useContext(LoggedInContext);
  const [response, setResponse] = useState<string[]>([]);

  const [state, setState] = useState<{
    email: string;
    password: string;
  }>({
    email: '',
    password: '',
  });

  /////////////////////////////////////////////////////////////////////////////////////////
  const submitLogin = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const { email, password } = state;
    const response = await loginMethod(email.toLowerCase(), password);
    if (response.statusCode) {
      setResponse(response.message);
      return false;
    } else {
      setResponse(response.token);
      setLoggedIn(response.token);
    }
  };

  return (
    <>
      <div
        className="container-fluid min-vh-100 d-flex justify-content-center align-items-center"
        style={{ backgroundImage: 'url(/images/pattern.png)' }}
      >
        <div className="login-wrapper bg-component-background p-5 px-3 border shadow-sm border-dark rounded position-relative">
          <LoginHeader />

          <h1 className="text-center login-title">{t('login.login_title')}</h1>
          <div className="error-message">{response}</div>
          <form>
            <LoginForm setState={setState} />
            <div className="mt-3 pt-3">
              <ButtonMain
                text={t('login.login_button')}
                icon=""
                className="btn btn-primary w-100"
                onClick={(e) => submitLogin(e)}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
