import {
  IsEmail,
  IsNotEmpty,
  IsOptional,
  IsString,
  MaxLength,
  MinLength,
} from 'class-validator';
import { t } from 'i18next';
import { IsEqual } from './decorators/is-equal-to.decorator';

export class RegistrationFormValidation {
  @IsOptional()
  @IsString({
    message: () => {
      return t('validation.is_string');
    },
    groups: ['name'],
  })
  public name: string | undefined;

  @IsOptional()
  @IsEmail(undefined, {
    message: () => {
      return t('validation.is_email');
    },
    groups: ['email'],
  })
  public email: string | undefined;

  @IsOptional()
  @IsEqual('email', {
    message: () => {
      return t('validation.is_equal');
    },
    groups: ['repeatEmail'],
  })
  public repeatEmail: string | undefined;

  @IsString()
  @IsNotEmpty({
    message: () => {
      return t('validation.is_not_empty');
    },
    groups: ['password'],
  })
  @MinLength(6, {
    message: () => {
      return t('validation.max_length');
    },
    groups: ['password'],
  })
  @MaxLength(32, {
    message: () => {
      return t('validation.max_length');
    },
    groups: ['password'],
  })
  /*  @Matches(/((?=.*\d)|(?=.*|W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, {
    message: 'Password is too weak...',
  }) */
  public password: string | undefined;

  @IsNotEmpty({
    message: () => {
      return t('validation.is_not_empty');
    },
    groups: ['repeatPassword'],
  })
  @IsEqual('password', {
    message: () => {
      return t('validation.is_equal');
    },
    groups: ['repeatPassword'],
  })
  public repeatPassword: string | undefined;

  @IsOptional()
  @IsString({
    message: () => {
      return t('validation.is_string');
    },
    groups: ['phone'],
  })
  public phone: string | undefined;

  @IsOptional()
  @IsString({
    message: () => {
      return t('validation.is_string');
    },
    groups: ['dateOfBirth'],
  })
  public dateOfBirth: string | undefined;

  @IsOptional()
  @IsString({
    message: () => {
      return t('validation.is_string');
    },
    groups: ['adress'],
  })
  public adress: string | undefined;

  @IsOptional()
  @IsString({
    message: () => {
      return t('validation.is_string');
    },
    groups: ['city'],
  })
  public city: string | undefined;

  @IsOptional()
  @IsString({
    message: () => {
      return t('validation.is_string');
    },
    groups: ['country'],
  })
  public country: string | undefined;

  @IsOptional()
  @IsString({
    message: () => {
      return t('validation.is_string');
    },
    groups: ['gender'],
  })
  public gender: string | undefined;
}
