import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  addPlayerInputFields,
  addPlayerSubmitButton,
} from '../../add-new-player/constants/add-player-input-form.constants';
import { PlayerDto } from '../../../../types/dtos.types';
import { AddPlayerValidation } from '../../../../utilities/validators/add-player.validator';
import { Server } from '../../../../server/server';
import Spinner from '../../../../components/spinner/spinner-component';
import PlayerItem from './player-item/player-item.component';
import InputForm from '../../../../components/input-form/input-form';
import RowContainer from '../../../../container/row-container/row.container';
import Pagination from '../../../../components/pagination/pagination.component';
import { getLoggedUser } from '../../../../utilities/local-storage/get-logged-user';

const SuperadminPlayers = () => {
  const formValidation = new AddPlayerValidation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [userResponse, setUserResponse] = useState<{
    users: PlayerDto[];
    count: number;
  }>(null as any);
  const [paginationOffset, setPaginationOffset] = useState<number>(0);
  const [listItems, setListItems] = useState<{ [playerId: string]: boolean }>(
    {}
  );
  const [state, setState] = useState({
    name: '',
    email: '',
  });
  const [msg, setMsg] = useState('');
  const [responseMsg, setResponseMsg] = useState<{
    responseType: string;
    message: (string | undefined)[] | undefined;
    showMessage: boolean;
  }>();

  useEffect(() => {
    const pagination = { take: 10, offset: paginationOffset };
    const userFetch = async () => {
      setIsLoading(true);
      const usersResponse = await Server.user.getAllWithPage(pagination);
      setUserResponse(usersResponse);
      setIsLoading(false);
    };

    userFetch();
  }, [paginationOffset, reload]);

  const onListItem = (playerId: string, isOpen: boolean) => {
    setListItems({ [playerId]: isOpen });
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const user = {
      name: state.name,
      email: state.email,
      adminName: getLoggedUser().name,
    };

    const response = await Server.user.createUserSuperadmin(user);
    if (response.statusCode === 409) {
      setMsg(t('validation.user_exists'));
      return false;
    }

    setIsLoading(false);
    setResponseMsg({
      responseType: 'Success',
      message: [`User ${user.name}(${user.email}) created.`],
      showMessage: true,
    });
  };

  return (
    <div className="settings-tab-container">
      <h2 className="sidebar-link regular-font text-center pt-2 mt-3">
        {t('input_fields.add_player')}
      </h2>
      <RowContainer>
        <InputForm
          inputFields={addPlayerInputFields}
          validation={formValidation}
          onSubmit={onSubmit}
          setState={setState}
          button={addPlayerSubmitButton}
          buttonDisabled={isLoading}
        />
        <div className="error-message text-center mt-4">{msg}</div>
        {responseMsg?.responseType === 'Error' && (
          <div className="col-md-12">
            {responseMsg?.message?.map((x, i) => (
              <p key={i} className="text-danger">
                {x}
              </p>
            ))}
          </div>
        )}
      </RowContainer>
      <h2 className="sidebar-link regular-font text-center pt-2 mt-3">
        {t('titles.all_players')}
      </h2>
      <RowContainer>
        {!isLoading ? (
          <div className="mt-3">
            {userResponse?.count > 0 ? (
              userResponse.users.map((x) => {
                return (
                  <PlayerItem
                    key={x.id}
                    player={x}
                    listItems={listItems}
                    onListItem={onListItem}
                    reload={reload}
                    setReload={setReload}
                  />
                );
              })
            ) : (
              <div className="text-center mt-3">
                <h1>
                  <i className="bi bi-emoji-neutral-fill"></i>
                </h1>
                <p>{t('input_fields.no_players')}</p>
              </div>
            )}
            {!isLoading && userResponse?.count >= 10 && (
              <Pagination
                gameCount={userResponse.count}
                paginationOffset={paginationOffset}
                setPaginationOffset={setPaginationOffset}
              />
            )}
          </div>
        ) : (
          <Spinner />
        )}
      </RowContainer>
    </div>
  );
};

export default SuperadminPlayers;
