export const dateFormatter = (dateAsString: string) => {
  const date = new Date(dateAsString);
  const formattedDate = {
    dd: '',
    mm: '',
    yyyy: '',
    hh: '',
    min: '',
  };

  const day = date.getDate();
  const month = date.getMonth() + 1;

  const hour = date.getHours();
  const minutes = date.getMinutes();

  formattedDate.yyyy = date.getFullYear().toString();
  if (day <= 9) {
    formattedDate.dd = '0' + day.toString();
  }
  if (day > 9) {
    formattedDate.dd = day.toString();
  }
  if (month <= 9) {
    formattedDate.mm = '0' + month.toString();
  }
  if (month > 9) {
    formattedDate.mm = month.toString();
  }
  if (hour <= 9) {
    formattedDate.hh = '0' + hour.toString();
  }
  if (hour > 9) {
    formattedDate.hh = hour.toString();
  }
  if (minutes <= 9) {
    formattedDate.min = '0' + minutes.toString();
  }
  if (minutes > 9) {
    formattedDate.min = minutes.toString();
  }

  return formattedDate;
};
