const SideBarHeader = () => {
  return (
    <div className="px-2">
      <img
        src=" /images/logo1.png"
        height="70px"
        className="img-fluid mt-3"
        alt="logo"
      ></img>
    </div>
  );
};

export default SideBarHeader;
