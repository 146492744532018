import { t } from 'i18next';
import { NavLink } from 'react-router-dom';
import { SideBarLinks } from '../../constants/routes/routes.constants';
import FluidContainer from '../../container/fluid-container/fluid.container';
import { useCheckAdmin, useCheckSuperadmin } from '../../hooks/useCheckAdmin';
import { useContext } from 'react';
import { GroupContext } from '../../App';

const MobileNavigation = () => {
  const { groupId } = useContext(GroupContext);
  const isAdmin = useCheckAdmin(groupId);
  const isSuperadmin = useCheckSuperadmin();
  return (
    <FluidContainer className="fixed-bottom bg-component-background d-md-none  border-top shadow-lg border-dark mobile-nav">
      <header className="py-2">
        <ul className="nav nav-pills d-flex justify-content-around">
          <>
            {SideBarLinks.map((item, index) => {
              if (
                (!item.authorise && !item.superadmin) ||
                (isAdmin && item.authorise) ||
                (isSuperadmin && item.superadmin)
              ) {
                return (
                  (!item.authorise || isAdmin === item.authorise) && (
                    <NavLink
                      to={item.path}
                      className={`sidebar-anchor`}
                      key={index}
                    >
                      <div className="d-flex flex-row flex-column align-items-center menu-wrap ">
                        <span className="mx-auto">
                          <i className={item.icon}></i>
                        </span>
                        <div className="sidebar-link">
                          {t(`menu_items.${item.title}`)}
                        </div>
                      </div>
                    </NavLink>
                  )
                );
              }
            })}
          </>
        </ul>
      </header>
    </FluidContainer>
  );
};

export default MobileNavigation;
