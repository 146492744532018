import { t } from 'i18next';
import { MouseEventHandler, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LoggedInContext } from '../../../App';
import { bearerToken } from '../../../constants/variables/local-storage.constant';
import { getLoggedUser } from '../../../utilities/local-storage/get-logged-user';
import { removeLoggedUser } from '../../../utilities/local-storage/remove-logged-user';
import Backdrop from '../../backdrop/backdrop.component';
import Circle from '../../circle-with-initials/circle.component';
import './button-dropdown.component.css';

const ButtonDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const { setLoggedIn } = useContext(LoggedInContext);
  const user = getLoggedUser();
  const initials = user.name.split(' ')[0][0] + user.name.split(' ')[1][0];

  const logOutHandler: MouseEventHandler<HTMLButtonElement> = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let confirmAction = window.confirm(t('alerts.logout'));
    if (confirmAction) {
      localStorage.removeItem(bearerToken);
      setLoggedIn(false);
      removeLoggedUser();
    } else {
      e.preventDefault();
    }
  };

  return (
    <>
      {isOpen && <Backdrop onClick={setIsOpen} />}
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-dropdown"
          data-bs-toggle="dropdown"
          onClick={toggleDropdown}
          tabIndex={0}
        >
          {isOpen ? (
            <div className="d-flex align-items-center">
              <Circle initials={initials} />{' '}
              <i className="bi ps-1 bi-caret-up-fill dropdown-caret"></i>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <Circle initials={initials} />{' '}
              <i className="bi ps-1 bi-caret-down-fill dropdown-caret"></i>
            </div>
          )}
        </button>
        {isOpen && (
          <ul className={`dropdown-menu settings show shadow`}>
            <li>
              <a className={'dropdown-item'} href={'/profile'}>
                <i className="bi bi-person-circle"> </i>{' '}
                {t(`menu_items.profile`)}
              </a>
            </li>
            <li>
              <a className={'dropdown-item'} href={'/settings'}>
                <i className="bi bi-gear-fill"> </i>
                {t(`menu_items.settings`)}
              </a>
            </li>
            <li>
              <a className={'dropdown-item'} href={'/rulebook'}>
                <i className="bi bi-book-fill"> </i>
                {t(`menu_items.rulebook`)}
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <NavLink to={'/login'} className="logout-drop">
                <button className="dropdown-item" onClick={logOutHandler}>
                  <i className="bi bi-box-arrow-left"> </i>
                  {t('login.logout_button')}
                </button>
              </NavLink>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default ButtonDropdown;
