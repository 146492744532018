import Ball from './ball.svg';
import './card.component.css';

interface DataCardProps {
  title: string;
  subtitle: string;
  icon: string;
  className?: string;
  content: string;
  contentLoss?: string;
  contentDraw?: string;
}

const DataCard = (props: DataCardProps) => {
  return (
    <div
      className={`card bg-component-background border shadow-sm border-dark ${props.className}`}
    >
      {props.icon ? (
        <div className="position-absolute top-50 end-0 translate-middle-y">
          <img
            src={`${Ball}`}
            alt="My Happy SVG"
            className={`card-icon bg-${props.icon}`}
          />
        </div>
      ) : (
        <></>
      )}
      <span className="push-up">
        <div className="card-header data-card-title">{props.title}</div>
        <div className="card-body">
          <h6 className="card-title data-card-subtitle text-center">
            <small>{props.subtitle}</small>
          </h6>
          <div className="d-flex">
            <div className="c-text data-card-content py-1 px-2 rounded">
              {props.content}
            </div>
            {props.contentLoss ? (
              <div className="c-text data-card-content-loss py-1 px-2 rounded">
                {props.contentLoss}
              </div>
            ) : (
              <></>
            )}
            {props.contentDraw ? (
              <div className="c-text data-card-content-draw py-1 px-2 rounded">
                {props.contentDraw}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </span>
    </div>
  );
};

export default DataCard;
