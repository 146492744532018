import { FETCHER_PATCH } from '../../constants/variables/fetcher.constants';
import { PlayerType, Teams } from '../../types/enums.types';
import { fetcherMethod } from '../fetch-method';

export class UserGamesServer {
  async updateUserGame(userGame: {
    userId?: string;
    gameId: string;
    team?: Teams;
    role?: PlayerType;
  }) {
    const path = 'usergame/update';
    const body = JSON.stringify(userGame);
    const response = await fetcherMethod(path, FETCHER_PATCH, body);
    return response;
  }
}
