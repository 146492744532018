import './profile.page.css';
import ButtonSecondary from '../../components/buttons/button-secondary.component';
import DataCard from '../../components/card/data-card.component';
import UserName from '../../components/profile-user-name/user-name.component';
import ProgressBar from '../../components/progress-bar/progress-bar.component';
import Rewards from '../../components/rewards/rewards.component';
import Spinner from '../../components/spinner/spinner-component';
import FluidContainer from '../../container/fluid-container/fluid.container';
import RowContainer from '../../container/row-container/row.container';
import { UserProfileDto } from '../../types/dtos.types';
import { Server } from '../../server/server';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState<UserProfileDto>();
  const params = useParams();
  const id = params.id;
  useEffect(() => {
    const fetchingProfileData = async () => {
      setLoading(true);
      const usersResponse = await Server.user.getProfileStatistics(id);
      setUser(usersResponse);
      setLoading(false);
    };
    fetchingProfileData();
  }, [id]);

  return (
    <FluidContainer className={''}>
      {!isLoading ? (
        <>
          {id ? (
            <ButtonSecondary
              icon="arrow-left-circle-fill"
              className="regular-font mb-2"
              text={t('profile.return_button')}
              onClick={() => navigate(-1)}
            />
          ) : (
            ''
          )}
          <UserName
            name={user?.name}
            isMvpInLastGames={user?.isMvpInLastGames!}
          />
          <RowContainer className="py-2">
            <div className="col-md-12 mb-4">
              <ProgressBar
                total={user?.gamesPlayed || 0}
                dataPoint1={user?.gamesPlayedFutsal || 0}
                dataPoint2={user?.gamesPlayedCageBall || 0}
              />
            </div>
            <div className="col-md-6 profile-card-wrapper">
              <DataCard
                title={t('players.futsal')}
                subtitle={`${t('profile.wins')}: ${user?.gamesWonFutsal} | ${t(
                  'profile.losses'
                )}: ${user?.gamesLostFutsal} | ${t('profile.draws')}: ${
                  user?.gamesDrawFutsal
                }`}
                icon="hypnotize"
                content={`${
                  user?.gamesWonFutsal && user?.gamesPlayedFutsal
                    ? (
                        (user.gamesWonFutsal / user.gamesPlayedFutsal) *
                        100
                      ).toFixed(1) + `%  ${t('profile.win')}`
                    : `${t('profile.no_data')}`
                }`}
                contentLoss={`${
                  user?.gamesLostFutsal && user?.gamesPlayedFutsal
                    ? (
                        (user.gamesLostFutsal / user.gamesPlayedFutsal) *
                        100
                      ).toFixed(1) + `% ${t('profile.loss')}`
                    : `${t('profile.no_data')}`
                }`}
                contentDraw={`${
                  user?.gamesDrawFutsal && user?.gamesPlayedFutsal
                    ? (
                        (user.gamesDrawFutsal / user.gamesPlayedFutsal) *
                        100
                      ).toFixed(1) + `%  ${t('profile.draw')}`
                    : `${t('profile.no_data')}`
                }`}
              />
            </div>
            <div className="col-md-6 profile-card-wrapper">
              <DataCard
                title={t('players.cageball')}
                subtitle={`${t('profile.wins')}: ${
                  user?.gamesWonCageBall
                } | ${t('profile.losses')}: ${user?.gamesLostCageBall} | ${t(
                  'profile.draws'
                )}: ${user?.gamesDrawCageBall}`}
                icon="globe"
                content={`${
                  user?.gamesWonCageBall && user?.gamesPlayedCageBall
                    ? (
                        (user.gamesWonCageBall / user.gamesPlayedCageBall) *
                        100
                      ).toFixed(1) + `%  ${t('profile.win')}`
                    : `${t('profile.no_data')}`
                }`}
                contentLoss={`${
                  user?.gamesLostCageBall && user?.gamesPlayedCageBall
                    ? (
                        (user.gamesLostCageBall / user.gamesPlayedCageBall) *
                        100
                      ).toFixed(1) + `% ${t('profile.loss')}`
                    : `${t('profile.no_data')}`
                }`}
                contentDraw={`${
                  user?.gamesDrawCageBall && user?.gamesPlayedCageBall
                    ? (
                        (user.gamesDrawCageBall / user.gamesPlayedCageBall) *
                        100
                      ).toFixed(1) + `%  ${t('profile.draw')}`
                    : `${t('profile.no_data')}`
                }`}
              />
            </div>
          </RowContainer>
          <div className="card bg-component-background d-flex border shadow-sm border-dark rounded-3">
            <div className="card-header data-card-title">
              {t('tab_items.groups')}
            </div>
            <div className="d-flex flex-column justify-content-center mx-4 my-2">
              {user?.groups.map((group) => (
                <div className="d-flex gap-3 p-1 align-items-center">
                  <div className="mb-1">{group.group?.name}</div>
                  <div>
                    {group.isBanned ? (
                      <div>
                        <i className={`bi bi-slash-circle text-danger`}></i>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Rewards
            dataPoint1={
              (user?.gamesWonFutsal || 0) + (user?.gamesWonCageBall || 0)
            }
            dataPoint2={
              (user?.gamesLostFutsal || 0) + (user?.gamesLostCageBall || 0)
            }
          />
        </>
      ) : (
        <Spinner />
      )}
    </FluidContainer>
  );
};

export default Profile;
