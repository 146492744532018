import { bearerToken } from '../../constants/variables/local-storage.constant';

export const getToken = () => {
  const token = localStorage.getItem(bearerToken);
  if (token) {
    return token;
  } else {
    return false;
  }
};
