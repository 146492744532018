import { FC } from 'react';
import { ButtonProps } from '../../types/props.types';

const ButtonSecondary: FC<ButtonProps> = ({
  className,
  text,
  icon,
  onClick,
}) => {
  return (
    <button
      className={`btn sidebar-link btn-secondary ${className}`}
      onClick={onClick}
    >
      {icon ? <i className={`bi bi-${icon}`}></i> : <></>}
      {text ? <span className={`button-text`}>{text}</span> : <></>}
    </button>
  );
};

export default ButtonSecondary;
