import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddGroupValidation } from '../../../../utilities/validators/add-group.validator';
import {
  addGroupInputFields,
  addGroupSubmitButton,
} from '../../add-new-player/constants/add-group-input-form.constants';
import { Server } from '../../../../server/server';
import { SelectOptionsProps } from '../../../../types/props.types';
import { GroupDto, SelectFieldDto } from '../../../../types/dtos.types';
import Spinner from '../../../../components/spinner/spinner-component';
import GroupItem from './group-item/group-item.component';
import RowContainer from '../../../../container/row-container/row.container';
import InputForm from '../../../../components/input-form/input-form';
import ActionMessage from '../../../../components/action-message/action-message.component';
import ChoosePossiblePlayers from '../../../../components/possible-players/ChoosePossiblePlayers';

const Groups = () => {
  const formValidation = new AddGroupValidation();
  const { t } = useTranslation();
  const [state, setState] = useState({
    name: '',
    groupRules: '',
    players: [],
  });

  const [userList, setUserList] = useState<SelectOptionsProps[]>([]);
  const [players, setPlayers] = useState<SelectFieldDto[]>([]);

  const [groupResponse, setGroupResponse] = useState<GroupDto[]>([]);
  const [listItems, setListItems] = useState<{ [groupId: string]: boolean }>(
    {}
  );

  const [msg, setMsg] = useState('');
  const [reload, setReload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [responseMsg, setResponseMsg] = useState<{
    responseType: string;
    message: (string | undefined)[] | undefined;
    showMessage: boolean;
  }>();

  useEffect(() => {
    const groupFetch = async () => {
      setIsLoading(true);
      const groupsResponse = await Server.group.getAllGroups();
      setGroupResponse(groupsResponse);
      setIsLoading(false);
    };

    const userFetch = async () => {
      setIsLoading(true);
      const userListResponse = await Server.user.getAll();
      const userListOptions = userListResponse.map((x, i) => {
        return { value: i.toString(), text: x.name, id: x.id };
      });
      setUserList(userListOptions);
      setIsLoading(false);
    };

    groupFetch();
    userFetch();
  }, [responseMsg, reload]);

  const onSubmit = async () => {
    const group = {
      name: state.name,
      groupRules: state.groupRules,
    };

    const bodyObj = {
      group,
      players,
    };
    setIsLoading(true);
    const response = await Server.group.createGroup(bodyObj);
    if (response.statusCode === 409) {
      setMsg(t('validation.group_exists'));
      return false;
    }

    setIsLoading(false);
    setResponseMsg({
      responseType: 'Success',
      message: [`Group successfully created.`],
      showMessage: true,
    });
  };

  const onListItem = (groupId: string, isOpen: boolean) => {
    setListItems({ [groupId]: isOpen });
  };

  const handleSelectMembers = (selectedOption: {
    value: string;
    label: string;
    id: string | undefined;
  }) => {
    const allPossibleIds = [...players.map((p) => p.id)];
    if (!allPossibleIds.includes(selectedOption.id)) {
      const option = {
        name: selectedOption.label,
        id: selectedOption.id,
      };
      setPlayers([...players, option]);
    } else {
      const newPlayers = players.filter((p) => p.id !== selectedOption.id);

      setPlayers(newPlayers);
    }
  };

  const isSelectedAsPossible = (id: string | undefined) => {
    const allPossibleIds = [...players.map((p) => p.id)];
    if (allPossibleIds.includes(id)) {
      return true;
    } else return false;
  };

  return (
    <>
      {responseMsg?.showMessage ? (
        <ActionMessage
          message={t('input_fields.response_success_add_group')}
          type={
            responseMsg?.responseType
              ? responseMsg.responseType.toLowerCase()
              : 'success'
          }
          onClick={() => {
            setResponseMsg((prevState) => ({
              ...prevState!,
              showMessage: false,
            }));
          }}
        />
      ) : (
        <div className="settings-tab-container">
          <h2 className="sidebar-link regular-font text-center pt-2">
            {t('titles.add_group')}
          </h2>
          <RowContainer>
            <div className="col-sm-8 mx-auto my-3">
              <ChoosePossiblePlayers
                title={t('teams.team')}
                userList={userList}
                handleSelectPossiblePlayers={handleSelectMembers}
                isSelectedAsPossible={isSelectedAsPossible}
              />
            </div>
            <InputForm
              inputFields={addGroupInputFields}
              validation={formValidation}
              onSubmit={onSubmit}
              setState={setState}
              button={addGroupSubmitButton}
              buttonDisabled={isLoading}
            />
            <div className="error-message text-center mt-4">{msg}</div>

            {responseMsg?.responseType === 'Error' && (
              <div className="col-md-12">
                {responseMsg?.message?.map((x, i) => (
                  <p key={i} className="text-danger">
                    {x}
                  </p>
                ))}
              </div>
            )}
          </RowContainer>
          <RowContainer>
            {!isLoading ? (
              <>
                <h2 className="sidebar-link regular-font text-center pt-2 mt-5 mb-3">
                  {t('titles.all_groups')}
                </h2>
                {groupResponse?.length > 0 ? (
                  groupResponse?.map((x) => {
                    return (
                      <GroupItem
                        key={x.id}
                        group={x}
                        listItems={listItems}
                        onListItem={onListItem}
                        userList={userList}
                        reload={reload}
                        setReload={setReload}
                      />
                    );
                  })
                ) : (
                  <div className="text-center mt-3">
                    <h1>
                      <i className="bi bi-emoji-neutral-fill"></i>
                    </h1>
                    <p>{t('input_fields.no_groups')}</p>
                  </div>
                )}
              </>
            ) : (
              <Spinner />
            )}
          </RowContainer>
        </div>
      )}
    </>
  );
};

export default Groups;
