import { FC } from 'react';

const RewardsContainer: FC = ({ children }) => {
  return (
    <div className="row bg-component-background py-5 px-2 mx-1 my-3 d-flex align-items-center border shadow-sm border-dark rounded-3">
      {children}
    </div>
  );
};

export default RewardsContainer;
