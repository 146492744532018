import { useEffect, useState } from 'react';
import { PlayerDto } from '../../../types/dtos.types';
import { getLoggedUser } from '../../../utilities/local-storage/get-logged-user';
import { Server } from '../../../server/server';
import {
  editUserInfoInputFields,
  editUserInfoSelectFields,
  editUserInfoSubmitButton,
} from '../../update-user-info/set-user-info/constants/set-user-info-input-fields';
import { EditUserInfoFormValidation } from '../../../utilities/validators/edit-user-info.validator';
import ActionMessage from '../../../components/action-message/action-message.component';
import { t } from 'i18next';
import InputForm from '../../../components/input-form/input-form';
import RowContainer from '../../../container/row-container/row.container';

const formValidation = new EditUserInfoFormValidation();
interface PersonalInfoProps {
  user?: PlayerDto;
}

const PersonalInfo = (props: PersonalInfoProps) => {
  const [user, setUser] = useState<PlayerDto>();
  const [userInfo, setUserInfo] = useState<{
    adress?: string;
    country?: string;
    city?: string;
    dateOfBirth?: string;
    gender?: string;
    phone?: string;
  }>({});

  const [responseMsg, setResponseMsg] = useState<{
    responseType: string;
    message: (string | undefined)[] | undefined;
    showMessage: boolean;
  }>();

  useEffect(() => {
    const fetchUser = async () => {
      if (!props.user) {
        setUser(getLoggedUser());
      } else {
        setUser(props.user);
      }
    };
    fetchUser();
  }, [props.user]);

  const onSubmitHandler = async () => {
    await Server.user.updateUserInfo(userInfo);
    setResponseMsg({
      responseType: 'Success',
      message: [''],
      showMessage: true,
    });
    setUserInfo({});
  };

  return (
    <>
      {user && (
        <div className="settings-tab-container">
          <RowContainer className="pt-2 pb-1">
            <div className="col-12 text-center border border-dark bg-main-backround py-2 rounded shadow">
              <h2 className="settings-username mb-0">{user.name}</h2>
              <p className="settings-email">{user.email}</p>
            </div>
            {!props.user && (
              <>
                {!responseMsg?.showMessage ? (
                  <InputForm
                    inputFields={editUserInfoInputFields}
                    selectFields={editUserInfoSelectFields}
                    button={editUserInfoSubmitButton}
                    validation={formValidation}
                    onSubmit={onSubmitHandler}
                    setState={setUserInfo}
                  />
                ) : (
                  <ActionMessage
                    message={`${t('input_fields.response_success_change')}`}
                    type={'success'}
                    onClick={() =>
                      setResponseMsg((prevState) => ({
                        ...prevState!,
                        showMessage: false,
                      }))
                    }
                  />
                )}
              </>
            )}
          </RowContainer>
        </div>
      )}
    </>
  );
};

export default PersonalInfo;
