import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Server } from '../../../server/server';
import * as io from 'socket.io-client';
import { debounce } from 'lodash';
import { GameWithUserGamesDto } from '../../../types/dtos.types';
import { getLoggedUser } from '../../../utilities/local-storage/get-logged-user';
import { BiddingChoice } from '../../../types/enums.types';
import { wsUrl } from '../../../server/constant';

interface BettingComponentProps {
  game: GameWithUserGamesDto;
  gameStatus: string;
}

const BettingComponent = (props: BettingComponentProps) => {
  const { t } = useTranslation();
  const [results, setResults] = useState({
    firstTeam: 0,
    secondTeam: 0,
    draw: 0,
  });
  const [totalBets, setTotalBets] = useState({});
  const [biddedOn, setBiddedOn] = useState<BiddingChoice>();
  const [bidded, setBidded] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const user = getLoggedUser();
  const [activeState, setActiveState] = useState(false);
  const [reload, setReload] = useState<boolean>(false);

  /////////////////////////////////////////////////////////////////////////////////////////
  const didYouBid = useCallback(async () => {
    const userGame = {
      userId: user.id,
      gameId: props.game.id,
    };
    const res = await Server.bidGame.getUserBidGame(userGame);
    setBiddedOn(res.userChoice);
    if (res.userChoice) {
      setBidded(true);
    }
  }, [props.game.id, user.id]);

  const getResults = useCallback(async () => {
    const res = await Server.bidGame.getBidGame(props.game.id);
    const sum = res.firstTeam + res.secondTeam + res.draw;
    setTotalBets(sum);
    if (sum === 0) return;
    setResults((prevState) => ({
      ...prevState,
      firstTeam: parseFloat(((100 * res.firstTeam) / sum).toFixed(1)),
      secondTeam: parseFloat(((100 * res.secondTeam) / sum).toFixed(1)),
      draw: parseFloat(((100 * res.draw) / sum).toFixed(1)),
    }));
  }, [props.game.id]);

  useEffect(() => {
    didYouBid();
    getResults();
    const socket = io.connect(`${wsUrl}`, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: localStorage.getItem('BearerToken'),
          },
        },
      },
    });
    socket.emit('join', '');
    socket.on('refresh', () => {
      didYouBid();
      getResults();
    });
    return () => {
      if (socket.connected) {
        socket.disconnect();
      }
    };
  }, [bidded, props.game, didYouBid, getResults, reload]);

  const handleVote = async (e: React.BaseSyntheticEvent) => {
    if ((new Date().getTime() - Date.parse(props.game.date)) / 1000 / 60 >= 0) {
      let confirmAction = window.confirm(
        `${t('input_fields.response_expired_time')}`
      );
      if (confirmAction) {
        setReload(!reload);
      }
    }
    setSpinner(true);
    const choice: BiddingChoice = e.target.name;
    const bidGame = {
      gameId: String(props.game.id),
      userChoice: choice,
      userId: user.id,
    };

    let confirmAction = window.confirm(t('alerts.bet'));
    if (confirmAction) {
      await Server.bidGame.createBidGame(bidGame);
      setBidded(true);
    }
    setSpinner(false);
  };

  const optimizedHandleVote = debounce(handleVote, 100);

  return (
    <div className="mt-2">
      <h2 className="accordion-header" id="headingOne">
        <button
          className={`accordion-button ${
            !activeState ? 'collapsed' : ''
          } rounded px-2`}
          style={{
            backgroundColor: 'var(--main-background)',
            color: 'white',
          }}
          disabled={false}
          onClick={() => setActiveState(!activeState)}
        >
          <div className="game-type px-2 py-2">{t('dashboard.bet')}</div>
        </button>
      </h2>
      {activeState ? (
        <div>
          {bidded ? (
            <div className="d-flex flex-column">
              <div className="mt-3 d-flex flex-row justify-content-between">
                <div className="d-flex flex-column align-items-center">
                  <button
                    className={`btn btn-bet ${
                      biddedOn === BiddingChoice.FIRST && 'selected'
                    }`}
                    disabled
                  >
                    {results.firstTeam}%
                  </button>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <button
                    className={`btn btn-bet ${
                      biddedOn === BiddingChoice.DRAW && 'selected'
                    }`}
                    disabled
                  >
                    {results.draw}%
                  </button>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <button
                    className={`btn btn-bet ${
                      biddedOn === BiddingChoice.SECOND && 'selected'
                    }`}
                    disabled
                  >
                    {results.secondTeam}%
                  </button>
                </div>
              </div>
              <div className="text-center p-2 mt-1 rounded border border-dark shadow bg-main-backround">
                {`${t('dashboard.totalBets')}${totalBets}`}
              </div>
            </div>
          ) : !spinner ? (
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column align-items-center">
                <button
                  name={BiddingChoice.FIRST}
                  className="btn btn-bet opt"
                  onClick={(e) => optimizedHandleVote(e)}
                >
                  {t('dashboard.betOption1')}
                </button>
              </div>
              <div className="d-flex flex-column align-items-center">
                <button
                  name={BiddingChoice.DRAW}
                  className="btn btn-bet opt"
                  onClick={(e) => optimizedHandleVote(e)}
                >
                  {t('dashboard.betOption2')}
                </button>
              </div>
              <div className="d-flex flex-column align-items-center">
                <button
                  name={BiddingChoice.SECOND}
                  className="btn btn-bet opt"
                  onClick={(e) => optimizedHandleVote(e)}
                >
                  {t('dashboard.betOption3')}
                </button>
              </div>
            </div>
          ) : (
            <div className="lds-ripple start-50 translate-middle top-50  width: 40px height: 40px ">
              <div></div>
              <div></div>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default BettingComponent;
