import { FC } from 'react';
import FluidContainer from '../fluid-container/fluid.container';
import RowContainer from '../row-container/row.container';

const ApplicationContainer: FC = ({ children }) => {
  return (
    <FluidContainer className="min-vh-100 position-relative">
      <RowContainer>{children}</RowContainer>
    </FluidContainer>
  );
};

export default ApplicationContainer;
