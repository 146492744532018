const LoginHeader = () => {
  return (
    <div className="position-absolute top-0 start-50 translate-middle px-5 bg-main-background login-head border-bottom  rounded-bottom py-2">
      <img
        src=" /images/logo-v.png"
        height="70px"
        className="img-fluid mt-3"
        alt="logo"
      ></img>
    </div>
  );
};

export default LoginHeader;
