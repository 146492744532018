import { BaseSyntheticEvent, useEffect, useState } from 'react';
import FluidContainer from '../../../container/fluid-container/fluid.container';
import { dateFormatter } from '../../../utilities/data/date-formatter/date-formatter';
import { SetTeamResultColorScheme } from '../../../utilities/data/set-color-scheme/set-team-result-color-scheme';
import { EditGameDto, GameWithUserGamesDto } from '../../../types/dtos.types';
import './enter-result.component.css';
import { getLoggedUser } from '../../../utilities/local-storage/get-logged-user';
import InputField from '../../../components/inputs/input-field.component';
import { t } from 'i18next';
import RowContainer from '../../../container/row-container/row.container';
import { validate, validateOrReject } from 'class-validator';
import { Server } from '../../../server/server';
import { GameValidation } from '../../../utilities/validators/game.validator';
import { gameStatus } from '../../../constants/variables/game-status';
import { SetScoreValidation } from '../../../utilities/validators/set-score.validator';
import EnterResultPlayerComponent from './player-component/player-component';
import { Teams } from '../../../types/enums.types';

const formValidation = new SetScoreValidation();
interface ResultEditingComponentProps {
  game: GameWithUserGamesDto;
  reload: () => void;
}

const ResultEditingComponent = (props: ResultEditingComponentProps) => {
  const [ableToEdit, setAbleToEdit] = useState(false);

  const [classNames, setClassNames] = useState<{
    team1Goals?: string;
    team1GoalsErrMsg?: string[];
    team2Goals?: string;
    team2GoalsErrMsg?: string[];
  }>({
    team1Goals: 'team1Goals',
    team2Goals: 'team2Goals',
  });

  const [game, setGame] = useState(props.game);

  const statusVoting = props.game.statusVoting;
  const date = dateFormatter(game?.date);
  const gameResultColorState = SetTeamResultColorScheme(game);

  /////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const user = getLoggedUser();
    const checkIfAbleToEdit = () => {
      game.userGames.forEach((x) => {
        if (x.userId === user?.id && game.status !== gameStatus.FINISHED) {
          setAbleToEdit(true);
        }
      });
    };
    checkIfAbleToEdit();
  }, [game, statusVoting]);

  const handleChange = async (e: BaseSyntheticEvent) => {
    const name: keyof SetScoreValidation = e.target.name;
    let value: number;
    value = parseInt(e.target.value);

    formValidation[name] = value;
    const validationResult = await validate(formValidation, {
      groups: [name],
    });
    if (validationResult.length) {
      setClassNames((prevVal) => ({
        ...prevVal,
        [name]: `${name} error`,
        [`${name}ErrMsg`]: validationResult.map((x) =>
          Object.values(x.constraints!)
        ),
      }));
    } else {
      setClassNames((prevVal) => ({
        ...prevVal,
        [name]: `${name}`,
        [`${name}ErrMsg`]: [''],
      }));
    }
    setGame((prevVal) => ({ ...prevVal, [name]: value }));
  };

  const submitEditScore = async () => {
    validateOrReject(formValidation);
    let confirmAction = window.confirm(
      `${t('dashboard.result_entered')}
      ${t('teams.team_1_color')} ${game.team1Goals} : ${game.team2Goals} ${t(
        'teams.team_2_color'
      )}
      ${t('dashboard.are_you_sure')}
      ${t('dashboard.watching_you')}`
    );
    if (confirmAction) {
      const gameEdit: EditGameDto = {
        id: game?.id,
        date: game?.date,
        type: game?.type,
        team1Goals: game.team1Goals,
        team2Goals: game.team2Goals,
        groupId: game?.groupId,
        gameMVP: game?.gameMVP,
        status: game?.status!,
        statusVoting: game?.statusVoting!,
      };
      const gameValidation = new GameValidation(gameEdit);
      const validationResult = await validate(gameValidation);
      if (validationResult.length) {
        alert(`${t('dashboard.try_again')}`);
      } else {
        await Server.game.setScore(gameEdit);
        setAbleToEdit(false);
        props.reload();
      }
    } else {
      alert(`${t('dashboard.try_again')}`);
    }
  };

  return (
    <>
      {game && (
        <div className="bg-vote-background vote-wrapper rounded border border-dark shadow">
          <div className="col-lg-12 mb-3">
            {statusVoting && (
              <h2 className="fw-bold text-uppercase text-center fs-5">
                {t('titles.enter_result')}
              </h2>
            )}
            <p></p>
            <div className="d-flex justify-content-between">
              <div className="game-date px-2 py-1 rounded border border-dark shadow bg-main-backround">
                <span className="date">{`${date.dd}/${date.mm}/${date.yyyy}`}</span>
                <span className="hours">{`${date.hh}:${date.min}`}</span>
              </div>
              <div
                className={`game-type px-2 py-1 rounded border border-dark shadow game-type game-${game?.type}`}
              >
                {game?.type}
              </div>
            </div>
          </div>

          <FluidContainer className="d-flex g-0 align-items-center justify-content-center py-2">
            <ul className="list-group flex-fill vote-list ">
              {game?.userGames
                .filter((x) => x.team === Teams.TEAM1)
                .map((x) => (
                  <EnterResultPlayerComponent
                    key={x.userId}
                    user={x}
                    team={+x.team}
                  />
                ))}
            </ul>
            <div
              className={`result flex-fill text-center result-${gameResultColorState?.team1State}`}
            >
              {game?.team1Goals >= 0 ? game.team1Goals : ''}
            </div>
            <div className="result-separator">-</div>
            <div
              className={`result flex-fill text-center result-${gameResultColorState?.team2State}`}
            >
              {game?.team2Goals >= 0 ? game.team2Goals : ''}
            </div>
            <ul className="list-group  flex-fill">
              {game?.userGames
                .filter((x) => x.team === Teams.TEAM2)
                .map((x) => (
                  <EnterResultPlayerComponent
                    key={x.userId}
                    user={x}
                    team={+x.team}
                  />
                ))}
            </ul>
          </FluidContainer>
          {ableToEdit ? (
            <RowContainer className="justify-content-center">
              <div className="d-flex justify-content-between">
                <InputField
                  type="number"
                  name="team1Goals"
                  errorMsg={classNames.team1GoalsErrMsg}
                  labelClass="text-black fw-bold fw-bold text-uppercase"
                  defaultValue={game.team1Goals || ''}
                  className={`score-input si-black ${classNames.team1Goals}`}
                  label={`${t('teams.team')} ${t('teams.team_1_color')} ${t(
                    'teams.team_result'
                  )}`}
                  onChange={(e) => handleChange(e)}
                />
                <InputField
                  type="number"
                  name="team2Goals"
                  errorMsg={classNames.team2GoalsErrMsg}
                  labelClass="w-100 text-end fw-bold text-uppercase"
                  defaultValue={game.team2Goals || ''}
                  className={`score-input si-white ms-auto ${classNames.team2Goals}`}
                  label={`${t('teams.team')} ${t('teams.team_2_color')} ${t(
                    'teams.team_result'
                  )}`}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <button
                className="btn btn-confirm shadow-lg"
                onClick={() => submitEditScore()}
              >
                {t('input_fields.button_submit')}
              </button>
            </RowContainer>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default ResultEditingComponent;
