import { useTranslation } from 'react-i18next';

interface ProgressBarProps {
  total: number;
  dataPoint1?: number;
  dataPoint2?: number;
  dataPoint3?: number;
}

const ProgressBar = (props: ProgressBarProps) => {
  const { t } = useTranslation();
  const calcPercentage = (dataPoint: number, total: number) => {
    return ((dataPoint / total) * 100).toFixed(0);
  };
  return (
    <>
      <h5>
        {t('profile.sum_games')}: {props.total}
      </h5>

      <div className="progress profile-progress">
        <div
          className="progress-bar bg-warning progress-bar-striped progress-bar-animated progress-data-point-1"
          role="progressbar"
          aria-valuemin={0}
          aria-valuemax={10}
          style={{
            width: `${calcPercentage(props.dataPoint1!, props.total)}%`,
          }}
        >
          <span className="sr-only">
            {calcPercentage(props.dataPoint1!, props.total)}% (
            {props.dataPoint1}) {t('players.futsal')}
          </span>
        </div>
        <div
          className="progress-bar bg-success progress-bar-striped progress-bar-animated  progress-data-point-2"
          role="progressbar"
          aria-valuemin={0}
          aria-valuemax={10}
          style={{
            width: `${calcPercentage(props.dataPoint2!, props.total)}%`,
          }}
        >
          <span className="sr-only">
            {calcPercentage(props.dataPoint2!, props.total)}% (
            {props.dataPoint2}) {t('players.cageball')}
          </span>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
