import { FC } from 'react';
import { ButtonProps } from '../../types/props.types';
import './button.component.css';

const DisabledButton: FC<ButtonProps> = ({ className, text, icon }) => {
  return (
    <button className={`btn disabled`}>
      {icon ? <i className={`bi bi-${icon} ${className}`}></i> : <></>}
      {text ? <span className="d-none d-md-block">{text}</span> : <></>}
    </button>
  );
};

export default DisabledButton;
