import { serverUrl } from './constant';
import { getToken } from '../utilities/local-storage/get-authorisation-token';

export const fetcherMethod = async (
  path: string,
  method: string,
  body?: any,
  init?: RequestInit
) => {
  const token = getToken();
  //const timeOut = 5000;
  const abortController = new AbortController();
  const fetchWithTimeout = async () => {
    const response = await window.fetch(`${serverUrl}${path}`, {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: body,
      signal: abortController.signal,
    });
    return response;
  };
  try {
    const response = await fetchWithTimeout();
    return await response.json();
  } catch (err) {
    return err;
  }
};
