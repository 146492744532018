import { GameWithUserGamesDto } from '../../../../../types/dtos.types';
import { t } from 'i18next';

interface AccordionGameDataProps {
  game: GameWithUserGamesDto;
  team1Color: string;
  team2Color: string;
}

const AccordionGameData = (props: AccordionGameDataProps) => {
  const game = props.game;
  const { team1Color, team2Color } = props;

  return (
    <div className="container-fluid header-result container-fluid d-flex  align-items-center">
      <div className="p-2 flex-grow-1 breadcrumb-title">
        <span className="text-dark">{t('teams.team_1_color')}</span>{' '}
        <span className={`result-${team1Color}`}>{game.team1Goals}</span> -{' '}
        <span className={`result-${team2Color}`}>{game.team2Goals}</span>{' '}
        <span className="text-white game-item-white">
          {t('teams.team_2_color')}
        </span>
      </div>
      <div className="d-flex gap-3 align-items-center">
        <div className="sport-type">{game.type}</div>
      </div>
    </div>
  );
};

export default AccordionGameData;
