import {
  FETCHER_POST,
  FETCHER_GET,
} from '../../constants/variables/fetcher.constants';
import {
  BidGameDto,
  BidGameDtoRes,
  UserBidGameDto,
} from '../../types/dtos.types';
import { fetcherMethod } from '../fetch-method';

export class BidGameServer {
  async createBidGame(bidGameDto: BidGameDto) {
    const path = 'bid-game/create';
    const body = JSON.stringify(bidGameDto);
    const response = await fetcherMethod(path, FETCHER_POST, body);
    return response;
  }

  async getBidGame(gameId: string): Promise<BidGameDtoRes> {
    const path = `bid-game/get?gameId=${gameId}`;
    const response = await fetcherMethod(path, FETCHER_GET);
    return response;
  }

  async getUserBidGame(userBidGameDto: UserBidGameDto): Promise<BidGameDto> {
    const path = `bid-game/getUserBid?userId=${userBidGameDto.userId}&gameId=${userBidGameDto.gameId}`;
    const response = await fetcherMethod(path, FETCHER_GET);
    return response;
  }
}
