import { t } from 'i18next';

export const gameTypeDefault = { value: '-1', text: t('input_fields.all') };

export const selectInputDefaultValue = {
  value: '-1',
  text: t('input_fields.select'),
};

export const selectGroupDefaultValue = {
  value: '-1',
  text: t('input_fields.select'),
};
