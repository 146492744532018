import { t } from 'i18next';
import { SelectFieldDto } from '../../../types/dtos.types';
import { Teams } from '../../../types/enums.types';

export const validateSelectedPlayers = (players: SelectFieldDto[]) => {
  const team1NotEmpty = players.some((p) => p.team === Teams.TEAM1);
  const team2NotEmpty = players.some((p) => p.team === Teams.TEAM2);

  if (!team1NotEmpty || !team2NotEmpty) {
    alert(t('alerts.no_teams_empty'));
    return false;
  } else return true;
};
