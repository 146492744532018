import GroupItemWrapper from '../../../../container/group-item-container/group-item-container';
import { LocationDto } from '../../../../types/dtos.types';
import AccordionLocationData from './accordion-header/accordion-location-data.component';
import AccordionList from './accordion-list/accordion-list-component';
import './location-item.component.css';

interface LocationItemProps {
  location: LocationDto;
  isDisabledCollapse?: boolean;
  listItems?: { [locationId: string]: boolean };
  onListItem?: (locationId: string, isOpen: boolean) => void;
  setResponseMsg?: React.Dispatch<
    React.SetStateAction<{
      responseType: string;
      message?: string;
      showMessage: boolean;
    }>
  >;
}

const LocationItem = (props: LocationItemProps) => {
  const location = props.location;
  const className = props.listItems?.[location.id] ? `show` : `hide`;
  const activeState =
    (props.listItems && props.listItems?.[location.id]) || false;

  const accordionHandler = () => {
    if (!activeState && props.onListItem) {
      props.onListItem(location.id, true);
    }
    if (activeState && props.onListItem) {
      props.onListItem(location.id, false);
    }
  };

  return (
    <GroupItemWrapper>
      <h2 className="accordion-header mt-1" id="headingOne">
        <button
          className={`accordion-button px-2 ${!activeState ? 'collapsed' : ''}`}
          disabled={false}
          onClick={accordionHandler}
        >
          <AccordionLocationData location={location} />
        </button>
      </h2>
      <>
        <AccordionList
          className={className}
          location={location}
          setResponseMsg={props.setResponseMsg}
        />
      </>
    </GroupItemWrapper>
  );
};

export default LocationItem;
