import { GameFilterDto } from '../../../types/dtos.types';

export const gameFilterPathCalculation = (gameFilter: GameFilterDto) => {
  const { date, dateTo, gameType, playerIds, playerRelation } = gameFilter;
  let reqUrlAddition = '&';
  const playerIdsStr = playerIds
    ?.filter((x) => typeof x === 'string')
    .map((x) => `playerIds[]=${x}&`)
    .join('');
  if (date) {
    const dateStr = `date=${date}&dateTo=${dateTo}&`;
    reqUrlAddition += dateStr;
  }
  if (gameType) {
    const typeStr = `type=${gameType}&`;
    reqUrlAddition += typeStr;
  }
  if (playerRelation) {
    const playerRelationStr = `playerRelation=${playerRelation}&`;
    reqUrlAddition += playerRelationStr;
  }

  const path = reqUrlAddition + playerIdsStr;
  return path.slice(0, -1);
};
