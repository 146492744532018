import './admin-panel.page.css';
import 'react-quill/dist/quill.snow.css'; // ES6
import FluidContainer from '../../container/fluid-container/fluid.container';
import RowContainer from '../../container/row-container/row.container';
import TabsNavigation from '../../components/tabs-navigation/tabs-navigation.component';
import { AdminPanelData } from '../../constants/routes/routes.constants';
import { Outlet } from 'react-router-dom';

const Administration = () => {
  return (
    <>
      <FluidContainer>
        <TabsNavigation tabs={AdminPanelData} />
        <RowContainer>
          <Outlet />
        </RowContainer>
      </FluidContainer>
    </>
  );
};

export default Administration;
