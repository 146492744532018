import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Server } from '../../../../../server/server';
import ButtonMain from '../../../../../components/buttons/button.component';
import { GroupContext } from '../../../../../App';
import { getFormattedDate } from '../../../../../utilities/data/date-formatter/get-formatted-date';
import { UserType } from '../../../../../types/enums.types';
import { PlayerDto, UserGroup } from '../../../../../types/dtos.types';

interface PlayerInGroupAccordionListProps {
  player: PlayerDto;
  className: string;
  userGroups: [UserGroup];
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccordionList = (props: PlayerInGroupAccordionListProps) => {
  const { groupId } = useContext(GroupContext);
  const [isLoading, setIsLoading] = useState(false);

  const userGroup = props.userGroups.filter(
    (group) => group.groupId === groupId
  );

  const isBannedFromGroup = userGroup[0].isBanned;
  const groupBanExpiration = userGroup[0].banExpiration;

  const { t } = useTranslation();

  const handleBanPlayerFromGroup = useCallback(async () => {
    setIsLoading(true);
    if (isBannedFromGroup) {
      const confirmAction = window.confirm(
        'Jeste li sigurni da želite poništiti zabranu za igrača u ovoj grupi?'
      );

      if (confirmAction) {
        await Server.userGroup.handleBanFromGroup(
          props.player.id,
          groupId,
          false
        );
        setIsLoading(false);
        props.setReload(!props.reload);
      } else {
        setIsLoading(false);
        return;
      }
    } else {
      const confirmAction = window.confirm(
        'Jeste li sigurni da želite zabraniti igrača u ovoj grupi na 7 dana?'
      );

      if (confirmAction) {
        await Server.userGroup.handleBanFromGroup(
          props.player.id,
          groupId,
          true
        );
        setIsLoading(false);
        props.setReload(!props.reload);
      } else {
        setIsLoading(false);
        return;
      }
    }
  }, [groupId, isBannedFromGroup, props]);

  useEffect(() => {}, [isBannedFromGroup]);

  const resendInvitation = async () => {
    setIsLoading(true);
    const confirmAction = window.confirm(
      'Jeste li sigurni da želite ponovno pozvati igrača?'
    );
    if (confirmAction) {
      const user = {
        name: props.player.name,
        email: props.player.email,
        groupId: groupId,
      };
      try {
        await Server.email.inviteUserInGroup(user);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      return;
    }
  };

  return (
    <div
      id="collapseOne"
      className={`accordion-collapse collapse ${props.className}`}
    >
      <div className="accordion-body">
        <div className="container-fluid py-small-1 d-flex flex-column justify-content-center gap-2">
          <div className="game-type mx-1 mb-1">{t('tab_items.groups')}</div>
          {props.userGroups.map((group) => {
            return (
              <div className="col-md-8 p-2 border rounded border-dark bg-main-backround d-flex justify-content-between align-items-center">
                <div>{group.group?.name}</div>
                {group.role === UserType.ADMIN ? (
                  <div className="game-type px-2">ADMIN</div>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
          {isBannedFromGroup ? (
            <div className="col-md-5 p-2 mx-1 border rounded border-danger bg-main-backround">
              {t('players.ban_until')}: {getFormattedDate(groupBanExpiration)}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="mt-4 mb-2 mx-1 d-flex gap-3">
          <NavLink
            to={`/players/${props.player.id}`}
            className="sidebar-anchor /players"
          >
            <ButtonMain
              className="btn btn-primary d-flex gap-2"
              icon="eye-fill"
              text={t('players.profile_button')}
            ></ButtonMain>
          </NavLink>
          {isBannedFromGroup ? (
            <ButtonMain
              className="btn btn-info d-flex gap-2"
              icon="arrow-counterclockwise"
              text={t('players.cancel_ban')}
              onClick={() => handleBanPlayerFromGroup()}
              disabled={isLoading}
            ></ButtonMain>
          ) : (
            <ButtonMain
              className="btn btn-warning d-flex gap-2"
              icon="slash-circle"
              text={t('players.ban')}
              onClick={() => handleBanPlayerFromGroup()}
              disabled={isLoading}
            ></ButtonMain>
          )}
          <ButtonMain
            className="btn btn-light d-flex gap-2"
            icon="send-fill"
            text={t('players.resend')}
            onClick={() => resendInvitation()}
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AccordionList;
