import { FC } from 'react';
import { InputFieldProps } from '../../types/props.types';
import './input.component.css';

const InputField: FC<InputFieldProps> = ({
  type,
  label,
  errorMsg,
  name,
  className,
  icon,
  onChange,
  value,
  labelClass,
  defaultValue,
}) => {
  return (
    <div className={`mb-3`}>
      <label className={`form-label ${labelClass}`}>
        <small>{label}</small>
      </label>
      <div className="input-group mb-3">
        {icon ? (
          <span className={`input-group-text ${className}`}>
            <i className={`bi bi-${icon}`}></i>
          </span>
        ) : (
          <></>
        )}
        <input
          type={type}
          name={name}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          className={`form-control ${className}`}
        />
      </div>
      {errorMsg?.map((x, i) => (
        <div key={i}>
          <label key={i} className={`form-label ${className}`}>
            {x}
          </label>
        </div>
      ))}
    </div>
  );
};

export default InputField;
