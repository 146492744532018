import './global-error-message.component.css';
import { GlobalErrorDto } from '../../types/dtos.types';

interface GlobalErrorProps {
  errorState: GlobalErrorDto;
  setState: (e: any) => any;
}

const GlobalErrorMessage = (props: GlobalErrorProps) => {
  return (
    <div
      className={`p-2 rounded position-absolute end-0 notification-window ${props.errorState.className}`}
    >
      <div>
        <div className="text-end">
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => props.setState({ display: false })}
          ></button>
        </div>
        <h2 className="message-title">{props.errorState.title}</h2>
      </div>
      <div className="message-content">{props.errorState.content}</div>
    </div>
  );
};

export default GlobalErrorMessage;
