import { useState, useEffect, useCallback } from 'react';
import { TrophyCardProps } from '../../../types/props.types';
import './past-game.component.css';
import { useTranslation } from 'react-i18next';
import { dateFormatter } from '../../../utilities/data/date-formatter/date-formatter';
import { SetTeamResultColorScheme } from '../../../utilities/data/set-color-scheme/set-team-result-color-scheme';
import AccordionList from '../../../pages/games/game-item/accordion-list/accordion-list.component';
import BettingResults from './past-game-bidding-result.component';
import { Server } from '../../../server/server';
import { BidGameDtoRes } from '../../../types/dtos.types';
import { GameType } from '../../../types/enums.types';

const PastGame = (props: TrophyCardProps) => {
  const { t } = useTranslation();
  const [accordionState, setAccordionState] = useState(false);
  const { userGames, type, date } = props.game;
  const dateFormatted = dateFormatter(date);
  const [bettingResults, setBettingResults] = useState<BidGameDtoRes>();
  const [totalBets, setTotalBets] = useState(0);
  const [scoreState, setScoreState] = useState<{
    team1State: string;
    team2State: string;
  }>({ team1State: '', team2State: '' });

  const icon =
    props.game.type === 'Futsal'
      ? '/images/futsal.png'
      : '/images/cageball.png';
  const getResults = useCallback(async () => {
    const res = await Server.bidGame.getBidGame(props.game.id);
    if (!res) {
      return;
    }
    const sum = res.firstTeam + res.secondTeam + res.draw;
    setTotalBets(sum);
    if (sum === 0) return;
    setBettingResults((prevState) => ({
      ...prevState,
      firstTeam: parseFloat(((100 * res.firstTeam) / sum).toFixed(1)),
      secondTeam: parseFloat(((100 * res.secondTeam) / sum).toFixed(1)),
      draw: parseFloat(((100 * res.draw) / sum).toFixed(1)),
    }));
  }, [props.game.id]);

  useEffect(() => {
    const colorScheme = () => {
      const state = SetTeamResultColorScheme(props.game);
      state && setScoreState(state);
    };
    getResults();
    colorScheme();
  }, [props.game, getResults]);
  const toggleAccordion = () => {
    setAccordionState(!accordionState);
  };

  return (
    <>
      <div
        className={`col-12 py-small my-2 ${
          accordionState ? 'bg-component-background' : ''
        }`}
      >
        <div className="container p-0 trophy-container py-2">
          <div className="d-flex  align-items-center justify-content-between p-1">
            <div className="d-flex align-items-center">
              <div className="trophy-icon flex-shrink-1 pe-1">
                <img
                  src={icon}
                  className="mx-auto img-fluid award"
                  alt="logo"
                ></img>
              </div>
              <div>
                <p className="m-0 fw-bold no-votes">{type}</p>
                <p className="p-0 m-0 game-type-tag">
                  {`${dateFormatted.dd}.${dateFormatted.mm}.${dateFormatted.yyyy}`}
                </p>
                <p className="p-0 m-0 game-type-tag">
                  {dateFormatted.hh}:{dateFormatted.min}
                </p>
              </div>
            </div>
            {!accordionState && (
              <div className="d-flex">
                <div className="p-2  breadcrumb-title bg-score-background">
                  <span className="text-black">{t('teams.team_1_color')}</span>{' '}
                  <span className={`result-${scoreState.team1State}`}>
                    {props.game.team1Goals}
                  </span>
                  <span className="text-black"> - </span>
                  <span className={`result-${scoreState.team2State}`}>
                    {props.game.team2Goals}
                  </span>{' '}
                  <span className="text-white game-item-white">
                    {t('teams.team_2_color')}
                  </span>
                </div>
              </div>
            )}
            <div className="collapse-button ">
              <button className="btn btn-primary" onClick={toggleAccordion}>
                {t('dashboard.details')}
              </button>
            </div>
          </div>
          <div className="">
            <div className="accordion shadow-sm" id="accordionItem">
              <div className="accordion-item">
                <AccordionList
                  team1Color={scoreState.team1State}
                  team2Color={scoreState.team2State}
                  className={`mvp-card-accordion ${
                    accordionState ? 'show' : ''
                  }`}
                  score={[props.game.team1Goals, props.game.team2Goals]}
                  players={userGames}
                  gameId={props.game.id}
                  gameDate={props.game.date}
                  gameType={
                    props.game.type === GameType.FUTSAL
                      ? GameType.FUTSAL
                      : GameType.CAGEBALL
                  }
                >
                  {bettingResults && (
                    <BettingResults
                      game={props.game}
                      results={bettingResults}
                      sum={totalBets}
                    />
                  )}
                </AccordionList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PastGame;
