import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Server } from '../../server/server';
import { parseJwt } from '../../utilities/authentication/login';
import { RegistrationFormValidation } from '../../utilities/validators/registration-form-validation';
import {
  registerInputFields,
  registerSubmitButton,
} from './constants/input-fields';
import { t } from 'i18next';
import InputForm from '../../components/input-form/input-form';
import { CredentialType } from '../../types/enums.types';
const formValidation = new RegistrationFormValidation();

const Register = () => {
  const params = useParams();
  const navigate = useNavigate();
  const token = params.token;
  const [validUrl, setValidUrl] = useState<boolean>(false);

  const [userData, setUserData] = useState<{
    name: string;
    email: string;
    repeatEmail: string;
    password: string;
    repeatPassword: string;
    dateOfBirth: string;
    phone: string;
    adress: string;
    city: string;
    country: string;
    gender: string;
  }>();

  useEffect(() => {
    const parseToken = async () => {
      try {
        const tokenParsed = parseJwt(token!);
        const miliSeconds =
          new Date().getTime() - new Date(tokenParsed.exp * 1000).getTime();
        if (miliSeconds >= 0) {
          setValidUrl(false);
          throw new Error('Expired token');
        }
        setValidUrl(true);
      } catch (err) {
        setValidUrl(false);
      }
    };
    parseToken();
  }, [token]);

  const submitRegistration = async (e: any) => {
    if (token) {
      const tokenParsed = parseJwt(token);

      const userId = tokenParsed.userId;
      await Server.email.changeUserCredentials(
        CredentialType.PASSWORD,
        userData?.password,
        userId
      );
      navigate('/login');
    }
  };
  return validUrl ? (
    <div
      className="container-fluid min-vh-100 d-flex justify-content-center align-items-center"
      style={{ backgroundImage: 'url(/images/pattern.png)' }}
    >
      <div className="login-wrapper bg-component-background p-5 border shadow-sm border-dark rounded position-relative">
        <h1 className="text-center login-title">{t('titles.register')}</h1>
        <form>
          <InputForm
            inputFields={registerInputFields}
            setState={setUserData}
            button={registerSubmitButton}
            validation={formValidation}
            onSubmit={submitRegistration}
          />
          <div className="mt-3 pt-3"></div>
        </form>
      </div>
    </div>
  ) : (
    <div>Bad request</div>
  );
};

export default Register;
