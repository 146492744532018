import { useState } from 'react';
import SelectField from '../../../../../../components/inputs/select.component';
import ButtonMain from '../../../../../../components/buttons/button.component';
import { t } from 'i18next';
import { PlayerDto } from '../../../../../../types/dtos.types';
import { SelectOptionsProps } from '../../../../../../types/props.types';

interface PlayerInGroupProps {
  player: PlayerDto;
  groupId: string;
  roles: SelectOptionsProps[];
  isAdmin: (player: PlayerDto, groupId: string) => boolean;
  handleAdmin: (player: PlayerDto, role: string) => void;
  handleRemoveUser: (userId: string, groupId: string) => void;
  buttonDisabled?: boolean;
}

const GroupPlayerItem = (props: PlayerInGroupProps) => {
  const [editRole, setEditRole] = useState<boolean>(false);

  return (
    <div className="px-2 py-1 m-1 border rounded border-dark bg-main-backround d-flex justify-content-between align-items-center">
      {props.player.name}
      <div className="d-flex justify-content-center align-items-center gap-2">
        {!editRole ? (
          <div className="game-type my-1 px-2 py-1 rounded border border-dark shadow d-flex align-items-center gap-2">
            {props.isAdmin(props.player, props.groupId)
              ? t('players.admin')
              : t('players.user')}
            <button
              onClick={() => setEditRole(true)}
              className="btn reset-btn p-0"
            >
              <i className={`bi bi-pencil-square`}></i>
            </button>
          </div>
        ) : (
          <SelectField
            className=""
            defaultValue={props.isAdmin(props.player, props.groupId) ? 0 : 1}
            label={''}
            options={props.roles}
            eventHandler={(e: any) => {
              props.handleAdmin(
                props.player,
                props.roles[e.target.options.selectedIndex].text
              );
            }}
          ></SelectField>
        )}
        <ButtonMain
          className="btn btn-primary bg-danger text-white px-2.5 d-flex justify-content-center align-items-center"
          icon="bi bi-person-dash-fill"
          onClick={() => props.handleRemoveUser(props.player.id, props.groupId)}
          disabled={props.buttonDisabled}
        />
      </div>
    </div>
  );
};

export default GroupPlayerItem;
