import {
  FETCHER_DELETE,
  FETCHER_GET,
  FETCHER_PATCH,
  FETCHER_POST,
} from '../../constants/variables/fetcher.constants';
import { PlayerDto, UserWithStatsDto } from '../../types/dtos.types';
import { getLoggedUser } from '../../utilities/local-storage/get-logged-user';
import { fetcherMethod } from '../fetch-method';

export class UserServer {
  private readonly basePath = 'user';

  async getUserById(userId: string): Promise<PlayerDto> {
    return await fetcherMethod(
      `${this.basePath}/userid?id=${userId}`,
      FETCHER_GET
    );
  }

  async getAll(): Promise<PlayerDto[]> {
    return await fetcherMethod(this.basePath, FETCHER_GET);
  }

  async getAllWithPage(pagination: { take: number; offset: number }) {
    const path = `${this.basePath}/allwithpage`;
    const paginationPath = `?take=${pagination?.take}&offset=${pagination?.offset}`;
    const response = await fetcherMethod(path + paginationPath, FETCHER_GET);
    return response;
  }

  async getUsersFromGroup(
    groupId: string | undefined | null
  ): Promise<PlayerDto[]> {
    const path = `${this.basePath}/usersfromgroup?id=${groupId}`;
    const response = await fetcherMethod(path, FETCHER_GET);
    return response;
  }

  async getUsersFromGroupWithPage(
    groupId: string | undefined | null,
    pagination: { take: number; offset: number }
  ) {
    const path = `${this.basePath}/usersfromgroupwithpage?id=${groupId}`;
    const paginationPath = `&take=${pagination?.take}&offset=${pagination?.offset}`;
    const response = await fetcherMethod(path + paginationPath, FETCHER_GET);
    return response;
  }

  async removeUserFromGroup(body: { userId: string; groupId: string }) {
    const path = `${this.basePath}/removeuserfromgroup`;
    const pathAddition = `?userId=${body.userId}&groupId=${body.groupId}`;
    return await fetcherMethod(path + pathAddition, FETCHER_DELETE);
  }

  async handleBan(userId: string, banStatus: boolean) {
    const path = `${this.basePath}/ban?id=${userId}&status=${banStatus}`;
    return await fetcherMethod(path, FETCHER_PATCH);
  }

  async getUsersWithStatistics(
    playerFilter: {
      date: string;
      dateTo: string;
    },
    groupId: string
  ): Promise<UserWithStatsDto[]> {
    const path = `${this.basePath}/userwithstats`;
    let pathAddition = '';
    if (playerFilter.date) {
      pathAddition = `?date=${playerFilter.date}&dateTo=${playerFilter.dateTo}&groupId=${groupId}`;
    }
    return await fetcherMethod(path + pathAddition, FETCHER_GET);
  }

  async getProfileStatistics(userId?: string) {
    const basePath = `${this.basePath}/userprofile`;
    let idUrl = '';
    if (userId) {
      idUrl = `?id=${userId}`;
    }
    const path = basePath + idUrl;
    return await fetcherMethod(path, FETCHER_GET);
  }

  async createUserAdmin(user: {
    name: string;
    email: string;
    groupId: string | undefined | null;
  }): Promise<any> {
    const path = `sign-up`;
    const bodyObj = JSON.stringify(user);
    const response = await fetcherMethod(path, FETCHER_POST, bodyObj);
    return response;
  }

  async createUserSuperadmin(user: {
    name: string;
    email: string;
  }): Promise<any> {
    const path = `sign-up`;
    const bodyObj = JSON.stringify(user);
    const response = await fetcherMethod(path, FETCHER_POST, bodyObj);
    return response;
  }

  async updateUserInfo(userInfo: {
    adress?: string;
    country?: string;
    city?: string;
    dateOfBirth?: string;
    gender?: string;
    phone?: string;
  }) {
    const path = `${this.basePath}/update-info`;
    const userId = getLoggedUser().id;
    const user = { id: userId, ...userInfo };
    const body = JSON.stringify(user);
    try {
      const response = await fetcherMethod(path, FETCHER_PATCH, body);
      return response;
    } catch (err) {
      return err;
    }
  }
}
