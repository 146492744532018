import { UserGameDto } from '../../../../types/dtos.types';

interface EnterResultPlayerComponentProps {
  user: UserGameDto;
  team: number;
}

const EnterResultPlayerComponent = (props: EnterResultPlayerComponentProps) => {
  const user = props.user;

  return (
    <>
      {props.team === 1 ? (
        <li className="list-group-item bg-black px-1 bg-team-black shadow mb-2">
          <div className="d-flex align-items-center">
            <div className="flex-fill player-name">{user.name}</div>
          </div>
        </li>
      ) : (
        <li className="list-group-item bg-black px-1 bg-team-white text-dark text-end shadow mb-2">
          <div className="d-flex align-items-center">
            <div className="flex-fill player-name">{user.name}</div>
          </div>
        </li>
      )}
    </>
  );
};

export default EnterResultPlayerComponent;
