import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import DataCard from '../../../components/card/data-card.component';
import Spinner from '../../../components/spinner/spinner-component';
import RowContainer from '../../../container/row-container/row.container';
import { Server } from '../../../server/server';
import { GroupContext } from '../../../App';
import { getLoggedUser } from '../../../utilities/local-storage/get-logged-user';
import Banned from '../../login/banned.component';
import { GameType } from '../../../types/enums.types';

interface GeneralGroupStatisticsProps {
  isLoading: boolean;
  setLoading: (e: boolean) => void;
}
interface CardProps {
  averageScoreBlackCageBall?: number;
  averageScoreDiffCageBall?: number;
  averageScoreDiffFutsal?: number;
  averageScoreWhiteCageBall?: number;
  averageScoresBlackFutsal?: number;
  averageScoresWhiteFutsal?: number;
  gamesCageBall?: number;
  gamesFutsal?: number;
  gamesPlayed?: number;
  percentageWonBlack?: number;
  percentageWonWhite?: number;
}

const GeneralGroupStatistics = (props: GeneralGroupStatisticsProps) => {
  const [groupStatistics, setGroupStatistics] = useState<CardProps>();
  const { setLoading } = props;
  const { groupId } = useContext(GroupContext);
  const user = getLoggedUser();
  const [isBannedFromGroup, setIsBannedFromGroup] = useState<boolean>();
  const [banExp, setBanExp] = useState<Date | null | undefined>();
  const [activeState, setActiveState] = useState(false);
  const [showNotFound, setShowNotFound] = useState<boolean>(false);

  useEffect(() => {
    const getGroupStatistics = async () => {
      setLoading(true);
      const response = await Server.group.getGroupStatistics(groupId);
      if (response.statusCode === 404) {
        setShowNotFound(true);
      } else {
        setShowNotFound(false);
        setGroupStatistics(response);
        setLoading(false);
      }
    };

    const fetchingBanStatus = async (userId: string, groupId: string) => {
      const userGroup = await Server.userGroup.getUserGroupByGroupId(
        userId,
        groupId
      );
      if (userGroup.isBanned) {
        const now = new Date();
        if (userGroup.banExpiration) {
          const expiration = new Date(userGroup.banExpiration);
          if (now > expiration) {
            await Server.userGroup.handleBanFromGroup(user.id, groupId, false);
            setIsBannedFromGroup(false);
            setBanExp(null);
          }
        }
      }
      setIsBannedFromGroup(userGroup.isBanned);
      setBanExp(userGroup.banExpiration);
    };

    if (groupId) {
      getGroupStatistics();
      fetchingBanStatus(user.id, groupId);
    }
  }, [setLoading, groupId, user.id]);

  return groupId ? (
    !isBannedFromGroup ? (
      !showNotFound ? (
        !props.isLoading ? (
          <>
            <h2 className="accordion-header" id="headingOne">
              <button
                className={`accordion-button ${
                  !activeState ? 'collapsed' : ''
                } rounded px-2`}
                style={{
                  backgroundColor: 'var(--component-background)',
                  color: 'white',
                }}
                disabled={false}
                onClick={() => setActiveState(!activeState)}
              >
                <div className="game-type px-2 py-2">
                  {t('titles.general_stats')}
                </div>
              </button>
            </h2>
            {activeState ? (
              <RowContainer className="py-2 mb-2">
                <div className="col-md-4">
                  <DataCard
                    title={t('dashboard.general_stats')}
                    className="data-dashboard-card"
                    subtitle={`${GameType.FUTSAL}: ${groupStatistics?.gamesFutsal} | ${GameType.CAGEBALL}: ${groupStatistics?.gamesCageBall}`}
                    icon="regular"
                    content={`${t('dashboard.played_games')}: ${
                      groupStatistics?.gamesPlayed
                    }`}
                  />
                </div>
                <div className="col-md-4">
                  <DataCard
                    title={`${t('dashboard.average_goals')}: ${
                      GameType.FUTSAL
                    }`}
                    className="data-dashboard-card"
                    subtitle={`${t('teams.team_1_color')}: ${
                      groupStatistics?.averageScoresBlackFutsal
                    } | ${t('teams.team_2_color')}: ${
                      groupStatistics?.averageScoresWhiteFutsal
                    }`}
                    icon="regular"
                    content={`${t('dashboard.average_score_diff')}: ${
                      groupStatistics?.averageScoreDiffFutsal
                    }`}
                  />
                </div>
                <div className="col-md-4">
                  <DataCard
                    title={`${t('dashboard.average_wins')}: ${
                      GameType.CAGEBALL
                    }`}
                    className="data-dashboard-card"
                    subtitle={`${t('teams.team_1_color')}: ${
                      groupStatistics?.averageScoreBlackCageBall
                    } | ${t('teams.team_2_color')}: ${
                      groupStatistics?.averageScoreWhiteCageBall
                    }`}
                    icon="regular"
                    content={`${t('dashboard.average_games_diff')}: ${
                      groupStatistics?.averageScoreDiffCageBall
                    }`}
                  />
                </div>
              </RowContainer>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Spinner />
        )
      ) : (
        <div className="text-center">
          <h1>
            <i className="bi bi-emoji-neutral-fill"></i>
          </h1>
          <p>{t('games.no_games_found')}!</p>
        </div>
      )
    ) : (
      <Banned banExp={banExp} buttonShow={false} />
    )
  ) : (
    <div className="container-fluid d-flex justify-content-center align-items-center">
      <div className="login-wrapper bg-component-background p-5 px-5 border shadow-sm border-dark rounded position-relative">
        <h1 className="text-center login-title mb-4">
          {t('players.no_groups')}
        </h1>
        <div className="text-center mb-4">
          <h4>{t('players.no_groups_text')}</h4>
        </div>
      </div>
    </div>
  );
};

export default GeneralGroupStatistics;
