import {
  FETCHER_GET,
  FETCHER_PATCH,
} from '../../constants/variables/fetcher.constants';
import { UserGroup } from '../../types/dtos.types';
import { fetcherMethod } from '../fetch-method';

export class UserGroupServer {
  async changeRole(bodyObj: { userId: string; groupId: string; role: string }) {
    const path = 'user-group/change-role';
    const body = JSON.stringify(bodyObj);
    const response = await fetcherMethod(path, FETCHER_PATCH, body);
    return response;
  }

  async getAll(userId: string): Promise<UserGroup[]> {
    const path = `user-group?userId=${userId}`;
    const response = await fetcherMethod(path, FETCHER_GET);
    return response;
  }

  async getUserGroupByGroupId(
    userId: string,
    groupId: string
  ): Promise<UserGroup> {
    const path = `user-group/bygroupid?userId=${userId}&groupId=${groupId}`;
    const response = await fetcherMethod(path, FETCHER_GET);
    return response;
  }

  async handleBanFromGroup(
    userId: string,
    groupId: string,
    banStatus: boolean
  ) {
    const path = `user-group/banfromgroup?userId=${userId}&groupId=${groupId}&status=${banStatus}`;
    return await fetcherMethod(path, FETCHER_PATCH);
  }
}
