import { useState } from 'react';
import { SelectOptionsProps } from '../../types/props.types';
import { t } from 'i18next';
import { Teams } from '../../types/enums.types';

interface PossiblePlayersProps {
  title: string;
  userList: SelectOptionsProps[];
  team?: Teams.TEAM1 | Teams.TEAM2;
  handleSelectPossiblePlayers?: (selectedOption: {
    value: string;
    label: string;
    id: string | undefined;
  }) => void;
  handleSelectByTeam?: (
    selectedOption: {
      value: string;
      label: string;
      id: string | undefined;
    },
    team: Teams.TEAM1 | Teams.TEAM2
  ) => void;
  isSelectedAsPossible?: (id: string | undefined) => boolean;
  isSelectedInTeam?: (
    id?: string,
    team?: Teams.TEAM1 | Teams.TEAM2
  ) => boolean | undefined;
  setShowPopup?: (value: React.SetStateAction<boolean>) => void;
}

const ChoosePossiblePlayers = (props: PossiblePlayersProps) => {
  const [searchPhrase, setSearchPhrase] = useState('');

  return (
    <div className="bg-main-backround px-3 py-2 rounded">
      <p className="mb-1">{props.title}</p>
      <input
        className="input-group mb-2 border rounded game-type p-2 italic"
        type="text"
        placeholder={t('input_fields.search')}
        value={searchPhrase}
        onChange={(e) => setSearchPhrase(e.target.value)}
      />
      <div className="d-flex flex-column scrollable_content_sm rounded mb-2">
        {props.userList.map((option) => {
          return option.text
            .toLowerCase()
            .includes(searchPhrase.toLowerCase()) ? (
            <button
              className={`text-start bg-white game-type italic border-0 py-1 px-2`}
              onClick={() => {
                props.handleSelectPossiblePlayers &&
                  props.handleSelectPossiblePlayers({
                    value: option.text,
                    label: option.text,
                    id: option.id,
                  });
                props.handleSelectByTeam &&
                  props.team &&
                  props.handleSelectByTeam(
                    {
                      value: option.text,
                      label: option.text,
                      id: option.id,
                    },
                    props.team
                  );
                if (props.setShowPopup) props.setShowPopup(true);
              }}
            >
              {option.text}
              {props.isSelectedAsPossible &&
              props.isSelectedAsPossible(option.id) ? (
                <i className="bi bi-check-circle-fill px-2 text-success"></i>
              ) : (
                <></>
              )}
              {props.isSelectedInTeam &&
              props.isSelectedInTeam(option.id, props.team) ? (
                <i className="bi bi-check-circle-fill px-2 text-success"></i>
              ) : (
                <></>
              )}
            </button>
          ) : (
            <></>
          );
        })}
      </div>
    </div>
  );
};

export default ChoosePossiblePlayers;
