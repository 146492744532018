import RewardsContainer from '../../container/rewards-container/rewards.container';
import { useTranslation } from 'react-i18next';

interface RewardsProps {
  dataPoint1?: number;
  dataPoint2?: number;
}

const Rewards = (props: RewardsProps) => {
  const { t } = useTranslation();
  const liters = (props.dataPoint1! * 0.5).toFixed(1) as unknown as number;

  const calcLiter = () => {
    if (liters > 50) {
      const remainingLiters: number = liters % 50;
      return remainingLiters;
    } else {
      return liters;
    }
  };
  const calcBarrels = () => {
    if (liters > 50) {
      const barrels: number = Math.ceil(liters / 50);
      return barrels;
    } else {
      return 1;
    }
  };
  return (
    <RewardsContainer>
      <div className="col-md-4 position-relative text-center">
        <img
          src="/images/barrel.png"
          className="img-fluid mt-4 mb-2 barrel-img "
          alt="logo"
        ></img>
        <span className="position-absolute top-0 start-50 p-2 translate-middle badge rounded-pill bg-danger">
          {calcBarrels()}.{' '}
          <span className="">{`${t('profile.barrel_and_capacity')}`}</span>
        </span>
        <div className="barrel-litre position-absolute top-50 start-50 translate-middle">
          {calcLiter()} l
        </div>
      </div>
      <div className="col-md-8 mt-3">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="barrel-info">
            <h3 className="text-uppercase fw-bold">{t('profile.won_beer')}</h3>
            {t('profile.number_of_wins')} ({props.dataPoint1}) x{' '}
            {t('profile.pints_0_5l')}
          </div>
          <span className="result result-win">{props.dataPoint1! * 0.5} l</span>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="barrel-info">
            <h3 className="text-uppercase fw-bold">{t('profile.lost_beer')}</h3>
            {t('profile.number_of_losses')} ({props.dataPoint2}) x{' '}
            {t('profile.pints_0_5l')}
          </div>
          <div className="result result-loss"> {props.dataPoint2! * 0.5} l</div>
        </div>
      </div>
    </RewardsContainer>
  );
};

export default Rewards;
