export class AddLocationValidation {
  // @IsNotEmpty({
  //   message: () => {
  //     return t('validation.is_not_empty');
  //   },
  //   groups: ['name'],
  // })
  // @IsString({
  //   message: () => {
  //     return t('validation.is_string');
  //   },
  //   groups: ['name'],
  // })
  // public name: string | undefined;
}
