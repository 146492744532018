import { PlayerItemProps } from '../../../../../types/props.types';
import GroupItemWrapper from '../../../../../container/group-item-container/group-item-container';
import AccordionPlayerData from './accordion-header/player-data/accordion-player-data.component';
import AccordionList from './accordion-list/accordion-list.component';

const PlayerItem = (props: PlayerItemProps) => {
  const player = props.player;
  const className = props.listItems?.[player.id] ? `show` : `hide`;
  const activeState =
    (props.listItems && props.listItems?.[player.id]) || false;

  const accordionHandler = () => {
    if (!activeState && props.onListItem) {
      props.onListItem(player.id, true);
    }
    if (activeState && props.onListItem) {
      props.onListItem(player.id, false);
    }
  };

  return (
    <GroupItemWrapper>
      <h2 className="accordion-header mt-1" id="headingOne">
        <button
          className={`accordion-button px-2 ${!activeState ? 'collapsed' : ''}`}
          disabled={false}
          onClick={accordionHandler}
        >
          <AccordionPlayerData player={player} />
        </button>
      </h2>
      <>
        <AccordionList
          className={className}
          playerId={player.id}
          userGroups={player.userGroups}
          isBanned={player.isBanned}
          banExpiration={player.banExpiration}
          reload={props.reload}
          setReload={props.setReload}
        />
      </>
    </GroupItemWrapper>
  );
};

export default PlayerItem;
