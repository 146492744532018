import { selectInputDefaultValue } from '../../../../constants/variables/game-filter-defaults.constant';
import { genderOptions } from '../../../../constants/variables/gender-select-options.constants';

export const editUserInfoInputFields: {
  label: string;
  errorMsg: string[] | undefined;
  name: string;
  type: string;
  icon: string;
  className: string;
  onChange: () => void;
}[] = [
  {
    label: 'phone',
    errorMsg: undefined,
    name: 'phone',
    type: 'text',
    icon: 'bi bi-phone-vibrate-fill',
    className: 'phone',
    onChange: () => {},
  },
  {
    label: 'dateOfBirth',
    errorMsg: undefined,
    name: 'dateOfBirth',
    type: 'date',
    icon: 'bi bi-calendar-fill',
    className: 'dateOfBirth',
    onChange: () => {},
  },
  {
    label: 'adress',
    errorMsg: undefined,
    name: 'adress',
    type: 'text',
    icon: '',
    className: 'adress',
    onChange: () => {},
  },
  {
    label: 'city',
    errorMsg: undefined,
    name: 'city',
    type: 'text',
    icon: '',
    className: 'city',
    onChange: () => {},
  },
  {
    label: 'country',
    errorMsg: undefined,
    name: 'country',
    type: 'text',
    icon: '',
    className: 'country',
    onChange: () => {},
  },
];

export const editUserInfoSelectFields = [
  {
    label: 'gender',
    name: 'gender',
    placeholder: 'Please Select',
    options: [selectInputDefaultValue, ...genderOptions],
  },
];

export const editUserInfoSubmitButton = [
  {
    text: 'Submit',
    className: 'btn btn-primary w-100',
    icon: '',
    onClick: (e: any) => {},
  },
];
