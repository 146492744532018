import { t } from 'i18next';
import React, { FC, useState } from 'react';
import ButtonSecondary from '../../../components/buttons/button-secondary.component';
import ButtonMain from '../../../components/buttons/button.component';
import InputField from '../../../components/inputs/input-field.component';
import FluidContainer from '../../../container/fluid-container/fluid.container';
import RowContainer from '../../../container/row-container/row.container';
import { todayMinusMonth } from '../../../utilities/data/date-formatter/today-minus-month';

const PlayersFilter: FC<{
  playerFilter: { date: string; dateTo: string };
  setPlayerFilter: React.Dispatch<
    React.SetStateAction<{
      date: string;
      dateTo: string;
    }>
  >;
  buttonDisabled?: boolean;
}> = ({ playerFilter, setPlayerFilter, buttonDisabled }) => {
  const [filterState, setFilterState] = useState<{
    isLoading: boolean;
    display: boolean;
  }>({ isLoading: false, display: true });

  const [playerFilterState, setPlayerFilterState] = useState<{
    date: string;
    dateTo: string;
  }>({
    date: todayMinusMonth(),
    dateTo: new Date().toISOString().split('T')[0],
  });

  /////////////////////////////////////////////////////////////////////////////////////////
  const displayFilter = async () => {
    setFilterState({ isLoading: false, display: !filterState.display });
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const resetInputs = () => {
    setPlayerFilterState({
      date: new Date(
        new Date().getFullYear() - 3,
        new Date().getMonth(),
        new Date().getDate()
      )
        .toISOString()
        .split('T')[0],
      dateTo: new Date().toISOString().split('T')[0],
    });
    setPlayerFilter({
      date: new Date(
        new Date().getFullYear() - 3,
        new Date().getMonth(),
        new Date().getDate()
      )
        .toISOString()
        .split('T')[0],
      dateTo: new Date().toISOString().split('T')[0],
    });
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const filterHandler = (e: any) => {
    e.preventDefault();
    setPlayerFilter!({
      date: playerFilterState.date,
      dateTo: playerFilterState.dateTo,
    });
    setFilterState((prevState) => ({ ...prevState, display: false }));
  };

  return (
    <div>
      <FluidContainer>
        <div className="col-md-12 ">
          <ButtonSecondary
            className="d-flex gap-2 my-2 regular-font"
            text={t('games.filter_title')}
            icon="funnel-fill"
            onClick={displayFilter}
          ></ButtonSecondary>
        </div>
        <RowContainer
          className={`bg-component-background filters px-2 py-3 mb-3 ${
            filterState.display ? 'd-flex' : 'd-none '
          }`}
        >
          <form onReset={resetInputs} onSubmit={filterHandler} className="row">
            <div className="col-md-4">
              <InputField
                label={t('input_fields.from')}
                type="date"
                name="date-from"
                defaultValue={playerFilter.date}
                onChange={(e) =>
                  setPlayerFilterState((prevState) => ({
                    ...prevState,
                    date: e.target.value,
                  }))
                }
              />
            </div>
            <div className="col-md-4">
              <InputField
                label={t('input_fields.to')}
                type="date"
                name="date-to"
                defaultValue={playerFilter.dateTo}
                onChange={(e) =>
                  setPlayerFilterState((prevState) => ({
                    ...prevState,
                    dateTo: e.target.value,
                  }))
                }
              />
            </div>
            <div className="filter_buttons col-md-12 d-flex gap-2">
              <ButtonMain
                icon="funnel-fill"
                text={t('games.filter')}
                className="btn btn-primary p-2 d-flex gap-2"
                type="submit"
                disabled={buttonDisabled}
              />
              <ButtonMain
                icon="x-circle-fill"
                text={t('games.remove_filters')}
                className="btn btn-secondary border p-2 d-flex gap-2"
                type="reset"
                disabled={buttonDisabled}
              />
            </div>
          </form>
        </RowContainer>
      </FluidContainer>
    </div>
  );
};

export default PlayersFilter;
