import { IsEmail, IsNotEmpty } from 'class-validator';
import { t } from 'i18next';
import { IsEqual } from './decorators/is-equal-to.decorator';

export class ChangeEmailValidation {
  @IsNotEmpty({
    message: () => {
      return t('validation.is_not_empty');
    },
    groups: ['email'],
  })
  @IsEmail(undefined, {
    message: () => {
      return t('validation.is_email');
    },
    groups: ['newEmail'],
  })
  public newEmail: string | undefined;

  @IsNotEmpty({
    message: () => {
      return t('validation.is_not_empty');
    },
    groups: ['repeatedNewEmail'],
  })
  @IsEqual('newEmail', {
    message: () => {
      return t('validation.is_equal');
    },
    groups: ['repeatedNewEmail'],
  })
  public repeatedNewEmail: string | undefined;
}
