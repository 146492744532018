import { Navigate, Route, Routes } from 'react-router-dom';
import { RoutesDefinitions } from '../constants/routes/routes.constants';
import { useCheckAdmin, useCheckSuperadmin } from '../hooks/useCheckAdmin';
import { checkIfLoggedUserExist } from '../utilities/local-storage/get-logged-user';
import { useContext } from 'react';
import { GroupContext } from '../App';
import Profile from '../pages/profile/profile.page';

const PagesRouter = () => {
  const { groupId } = useContext(GroupContext);
  const isAdmin = useCheckAdmin(groupId);
  const isSuperadmin = useCheckSuperadmin();

  return !checkIfLoggedUserExist() ? (
    <Navigate to="/login" />
  ) : (
    <Routes>
      {RoutesDefinitions.map((x) => {
        if (
          (!x.authorise && !x.superadmin) ||
          (isAdmin && x.authorise) ||
          (isSuperadmin && x.superadmin)
        ) {
          if (x.subroutes) {
            return (
              <Route key={x.path} path={x.path} element={x.element}>
                {x.subroutes.map((subroute, index) => {
                  return index === 0 ? (
                    <Route
                      key={subroute.path}
                      index
                      element={subroute.element}
                    />
                  ) : (
                    <Route
                      key={subroute.path}
                      path={subroute.path}
                      element={subroute.element}
                    />
                  );
                })}
              </Route>
            );
          } else {
            return <Route key={x.path} path={x.path} element={x.element} />;
          }
        } else {
          return <></>;
        }
      })}
      <Route path="/players/:id" element={<Profile />} />
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
};

export default PagesRouter;
