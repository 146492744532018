import { useCallback, useContext, useEffect, useState } from 'react';
import { GameWithUserGamesDto } from '../../../types/dtos.types';
import { SelectOptionsProps } from '../../../types/props.types';
import { Server } from '../../../server/server';
import { gameStatus } from '../../../constants/variables/game-status';
import { GroupContext } from '../../../App';
import Spinner from '../../../components/spinner/spinner-component';
import EditGameComponent from '../../../components/edit-game-component/edit-game-component';

const EditGame = () => {
  const { groupId } = useContext(GroupContext);
  const [games, setGames] = useState<GameWithUserGamesDto[]>();
  const [userList, setUserList] = useState<SelectOptionsProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadPendingGames = useCallback(async () => {
    setLoading(true);
    const gamesResponse = await Server.game.getAllWhere(
      groupId,
      gameStatus.PENDING,
      true
    );
    setGames(gamesResponse);
    setLoading(false);
  }, [groupId]);

  useEffect(() => {
    const userListFetch = async () => {
      await loadPendingGames();
      const userListResponse = await Server.user.getAll();
      const userListOptions = userListResponse.map((x, i) => {
        return { value: i.toString(), text: x.name, id: x.id };
      });
      setUserList([...userListOptions]);
    };
    userListFetch();
  }, [loadPendingGames]);

  const handleSubmitEditGame = (gameId?: string) => {
    setGames(games?.filter((x) => x.id !== gameId));
  };

  return !loading ? (
    <>
      {games?.map((game) => {
        return (
          <>
            <EditGameComponent
              key={game.id}
              game={game}
              userList={userList}
              handleSubmitEditGame={handleSubmitEditGame}
              reload={loadPendingGames}
            />
            <hr />
          </>
        );
      })}
    </>
  ) : (
    <Spinner />
  );
};

export default EditGame;
