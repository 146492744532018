import { getLoggedUser } from './get-logged-user';

export const getGroupId = () => {
  const user = getLoggedUser();
  if (user?.userGroups?.length < 1) {
    return null;
  } else {
    if (user?.userGroups?.length === 1) {
      return user.userGroups[0].groupId;
    } else {
      if (window.localStorage.getItem('selectedGroup')) {
        const groupId = window.localStorage.getItem('selectedGroup');
        if (groupId) {
          return JSON.parse(groupId);
        }
      } else {
        return user?.userGroups?.[0].groupId;
      }
    }
  }
};
