import { dateFormatter } from '../../../../../utilities/data/date-formatter/date-formatter';
import '../../game-item.component.css';

interface AccordionDateProps {
  date: string;
}

const AccordionDate = (props: AccordionDateProps) => {
  //Date format: MM/DD/YYYY
  const { dd, mm, yyyy } = dateFormatter(props.date);

  return (
    <div
      className="date-wrapper p-1 rounded-1 shadow"
      style={{ width: '100px' }}
    >
      <div className="date-day">
        {dd}.{mm}
      </div>
      <div className="month-year d-flex justify-content-center">
        <div className="date-year">{yyyy}</div>
      </div>
    </div>
  );
};

export default AccordionDate;
