import { useEffect, useState } from 'react';
import ActionMessage from '../../../components/action-message/action-message.component';
import RowContainer from '../../../container/row-container/row.container';
import { Server } from '../../../server/server';
import { useTranslation } from 'react-i18next';
import InputForm from '../../../components/input-form/input-form';
import {
  changeCredentialsSubmitButton,
  changeEmailInputFields,
  changePassWordInputFields,
} from '../constants/settings-input-form.constants';
import { ChangeEmailValidation } from '../../../utilities/validators/change-email.validator';
import { ChangePasswordValidation } from '../../../utilities/validators/change-password.validator';
import { CredentialType } from '../../../types/enums.types';

const changeEmailFormValidation = new ChangeEmailValidation();
const changePassWordFormValidation = new ChangePasswordValidation();

interface ChangeCredentialsProps {
  typeOfChange: CredentialType;
  id?: string;
}

const ChangeCredentials = (props: ChangeCredentialsProps) => {
  const { t } = useTranslation();
  const typeOfChange = props.typeOfChange;

  const [state, setState] = useState<{
    newEmail: string;
    repeatedNewEmail: string;
    newPassword: string;
    repeatedNewPassword: string;
  }>();

  const [responseMsg, setResponseMsg] = useState<{
    responseType: string;
    message: (string | undefined)[] | undefined;
    showMessage: boolean;
  }>();

  useEffect(() => {
    const refresh = () => {
      setResponseMsg({ responseType: '', message: [''], showMessage: false });
    };
    refresh();
  }, [typeOfChange]);

  const HandleSubmit = async () => {
    let newValue;
    if (typeOfChange === CredentialType.EMAIL) {
      newValue = state?.newEmail;
    }
    if (typeOfChange === CredentialType.PASSWORD) {
      newValue = state?.newPassword;
    }
    await Server.email.changeUserCredentials(typeOfChange, newValue, props.id);
    setResponseMsg({
      responseType: 'Success',
      message: [''],
      showMessage: true,
    });
    return true;
  };

  return (
    <>
      {responseMsg?.showMessage ? (
        <ActionMessage
          message={`${t(
            'input_fields.response_success_change'
          )} ${typeOfChange}!`}
          type={'success'}
          onClick={() =>
            setResponseMsg((prevState) => ({
              ...prevState!,
              showMessage: false,
            }))
          }
        />
      ) : (
        <div className="settings-tab-container">
          <RowContainer className="pb-3">
            <h6 className="text-center">
              {typeOfChange === CredentialType.EMAIL
                ? t('titles.changeEmail')
                : t('titles.changePass')}
            </h6>
            <InputForm
              inputFields={
                typeOfChange === CredentialType.EMAIL
                  ? changeEmailInputFields
                  : changePassWordInputFields
              }
              validation={
                typeOfChange === CredentialType.EMAIL
                  ? changeEmailFormValidation
                  : changePassWordFormValidation
              }
              onSubmit={HandleSubmit}
              button={changeCredentialsSubmitButton}
              setState={setState}
            />
          </RowContainer>
        </div>
      )}
    </>
  );
};

export default ChangeCredentials;
