import SideBarBody from './body/sidebar-body';
import SideBarHeader from './header/sidebar-header.component';
import './sidebar.component.css';
import SideBarLinkWrapper from './wrapper/sidebar-link-wrapper.component';
import SidebarWrapper from './wrapper/sidebar-wrapper.component';

const Sidebar = () => {
  return (
    <SidebarWrapper>
      <SideBarHeader />
      <SideBarLinkWrapper>
        <SideBarBody />
      </SideBarLinkWrapper>
    </SidebarWrapper>
  );
};

export default Sidebar;
