import { useCallback, useContext, useEffect, useState } from 'react';
import { DashBoardDataDto, VotingGameDto } from '../../types/dtos.types';
import { Server } from '../../server/server';
import { t } from 'i18next';
import { GroupContext } from '../../App';
import { getLoggedUser } from '../../utilities/local-storage/get-logged-user';
import GameComponent from './last-game/pending-game.component';
import VoteMVP from './vote-mvp/vote-mvp.component';
import TrophyCard from '../../components/card/trophy-card/trophy-card.component';
import PastGame from '../../components/card/past-game-element/past-game.component';
import ResultEditingComponent from './enter-result/enter-result.component';
import Banned from '../login/banned.component';
import Spinner from '../../components/spinner/spinner-component';
import RowContainer from '../../container/row-container/row.container';
import './dashboard.page.css';

const Dashboard = () => {
  const { groupId } = useContext(GroupContext);
  const user = getLoggedUser();
  const [isBannedFromGroup, setIsBannedFromGroup] = useState<boolean>();
  const [banExp, setBanExp] = useState<Date | null | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dashboardData, setDashboardData] = useState<DashBoardDataDto>();
  const [votingGames, setVotingGames] = useState<VotingGameDto[]>([]);
  const [dashboardChange, setDashboardChange] = useState<boolean>(false);

  const loadDashBoardData = useCallback(async () => {
    setIsLoading(true);
    if (!groupId) {
      setIsLoading(false);
      return null;
    }
    const response = await Server.dashboard.getDashboardData(groupId);
    setDashboardData(response);
    const games = response.games.votingGames.filter(
      (x) => x.votingMessage?.status
    );
    const votingFinishedGames = response.games.votingGames.filter((x) => {
      return (
        x.game.statusVoting &&
        (x.votingMessage?.message === 'Voting is finished' ||
          x.mvpEntries.length === x.game.userGames.length)
      );
    });
    setVotingGames(games);
    setIsLoading(false);
    return votingFinishedGames;
  }, [groupId]);

  /////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    async function calculateMvp(votingFinishedGames?: VotingGameDto[]) {
      if (votingFinishedGames?.length) {
        for (const x of votingFinishedGames) {
          if (x.game.gameMVP === 'Pending') {
            await Server.mvp.calculateMvp(x.game.id);
            setDashboardChange(!dashboardChange);
            return;
          }
        }
      }
    }
    const fetchingCardData = async () => {
      const votingFinishedGames = await loadDashBoardData();
      if (votingFinishedGames?.length) {
        calculateMvp(votingFinishedGames);
      }
    };
    const fetchingBanStatus = async (userId: string, groupId: string) => {
      const userGroup = await Server.userGroup.getUserGroupByGroupId(
        userId,
        groupId
      );
      if (userGroup.isBanned) {
        const now = new Date();
        if (userGroup.banExpiration) {
          const expiration = new Date(userGroup.banExpiration);
          if (now > expiration) {
            await Server.userGroup.handleBanFromGroup(user.id, groupId, false);
            setIsBannedFromGroup(false);
            setBanExp(null);
          }
        }
      }
      setIsBannedFromGroup(userGroup.isBanned);
      setBanExp(userGroup.banExpiration);
    };
    if (groupId) {
      fetchingBanStatus(user.id, groupId);
    }
    fetchingCardData();
  }, [dashboardChange, groupId, loadDashBoardData, user.id]);

  /////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {groupId ? (
        !isBannedFromGroup ? (
          !isLoading ? (
            <>
              {dashboardData?.games?.resultEditingGames?.map((game) => (
                <ResultEditingComponent
                  key={game.id}
                  game={game}
                  reload={loadDashBoardData}
                />
              ))}
              {votingGames?.map((votingGame, i) => {
                return <VoteMVP key={i} votingGame={votingGame} />;
              })}

              {dashboardData?.games.pendingGames.map((x) => (
                <GameComponent key={x.id} game={x} gameStatus="pending" />
              ))}
              <RowContainer className="py-2">
                {dashboardData?.games.lastGames.length !== 0 ? (
                  <h2 className="fw-bold text-uppercase text-center fs-5">
                    {t('dashboard.lastGames')}
                  </h2>
                ) : (
                  <></>
                )}
                {dashboardData?.games?.lastGames?.map((game) => {
                  if (game.gameMVP === 'Pending') {
                    return <></>;
                  } else if (game.statusVoting) {
                    return <TrophyCard key={game.id} game={game} />;
                  } else {
                    return <PastGame key={game.id} game={game} />;
                  }
                })}
              </RowContainer>
            </>
          ) : (
            <Spinner />
          )
        ) : (
          <Banned banExp={banExp} buttonShow={false} />
        )
      ) : (
        <div className="container-fluid d-flex justify-content-center align-items-center">
          <div className="login-wrapper bg-component-background p-5 px-5 border shadow-sm border-dark rounded position-relative">
            <h1 className="text-center login-title mb-4">
              {t('players.no_groups')}
            </h1>
            <div className="text-center mb-4">
              <h4>{t('players.no_groups_text')}</h4>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
