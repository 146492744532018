import { useContext } from 'react';
import { AccordionListProps } from '../../../../types/props.types';
import ButtonMain from '../../../../components/buttons/button.component';
import { EditGameContext } from '../../game-panel';
import { Teams } from '../../../../types/enums.types';
import Team1List from '../../../../components/list-components/team1-list.component';
import Team2List from '../../../../components/list-components/team2-list.component';
import { t } from 'i18next';

const AccordionList = (props: AccordionListProps) => {
  const { setEditGame } = useContext(EditGameContext);

  const playersTeam1 = props.players?.filter(
    (player) => player.team === Teams.TEAM1
  );
  const playersTeam2 = props.players?.filter(
    (player) => player.team === Teams.TEAM2
  );

  return (
    <div
      id="collapseOne"
      className={`accordion-collapse collapse ${props.className} p-2`}
    >
      <div className="accordion-body">
        {props?.children}
        <div className="container-fluid py-small-1 d-flex align-items-center justify-content-center">
          <Team1List players={playersTeam1} gameMvp={props.gameMvp} />

          <div
            className={`result flex-fill text-center result-${props.team1Color}`}
          >
            {props.score[0]}
          </div>
          <div className="result-separator">-</div>
          <div
            className={`result flex-fill text-center result-${props.team2Color}`}
          >
            {props.score[1]}
          </div>

          <Team2List players={playersTeam2} gameMvp={props.gameMvp} />
        </div>
      </div>
      {props.editOption ? (
        <ButtonMain
          className="btn btn-primary d-flex mx-auto gap-1"
          icon="bi bi-pencil-square"
          text={t('input_fields.button_edit')}
          onClick={(e) =>
            setEditGame({ displayEditGame: true, editGameId: props.gameId })
          }
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AccordionList;
