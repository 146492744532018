import { NavLink } from 'react-router-dom';
import { SideBarLinks } from '../../../constants/routes/routes.constants';
import {
  useCheckAdmin,
  useCheckSuperadmin,
} from '../../../hooks/useCheckAdmin';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { GroupContext } from '../../../App';
const SideBarBody = () => {
  const { groupId } = useContext(GroupContext);
  const isAdmin = useCheckAdmin(groupId);
  const isSuperadmin = useCheckSuperadmin();
  const { t } = useTranslation();

  return (
    <>
      {SideBarLinks.map((item, index) => {
        if (
          (!item.authorise && !item.superadmin) ||
          (isAdmin && item.authorise) ||
          (isSuperadmin && item.superadmin)
        ) {
          return (
            <NavLink to={item.path} className={`sidebar-anchor`} key={index}>
              <div className="d-flex flex-row align-items-center py-2 ps-3 menu-wrap ">
                <span className="pe-2 fs-3">
                  <i className={item.icon}></i>
                </span>
                <div className="sidebar-link">
                  {t(`menu_items.${item.title}`)}
                </div>
              </div>
            </NavLink>
          );
        } else {
          return <></>;
        }
      })}
    </>
  );
};

export default SideBarBody;
