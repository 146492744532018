import { BaseSyntheticEvent, FC, useContext, useEffect, useState } from 'react';
import ActionMessage from '../action-message/action-message.component';
import InputField from '../inputs/input-field.component';
import SelectField from '../inputs/select.component';
import RowContainer from '../../container/row-container/row.container';
import { Server } from '../../server/server';
import {
  EditGameDto,
  GameWithUserGamesDto,
  ResetBettingDto,
  SelectFieldDto,
  UserGameRawDto,
} from '../../types/dtos.types';
import { SelectOptionsProps } from '../../types/props.types';
import { dateFormatter } from '../../utilities/data/date-formatter/date-formatter';
import { getPlayersFromGame } from '../../utilities/data/filter-user-by-teams/get-players-from-game.constant';
import { useTranslation } from 'react-i18next';
import ButtonMain from '../buttons/button.component';
import { EditGameValidation } from '../../utilities/validators/edit-game.validator';
import { validate } from 'class-validator';
import { GameValidation } from '../../utilities/validators/game.validator';
import { locationCreateDefault } from '../../constants/variables/create-game-defaults.constant';
import { GroupContext } from '../../App';
import { PlayerType, Teams } from '../../types/enums.types';
import Team1List from '../list-components/team1-list.component';
import Team2List from '../list-components/team2-list.component';
import { validateSelectedPlayers } from '../../utilities/data/validate-new-game-players/validate-selected-players';
import ChoosePossiblePlayers from '../possible-players/ChoosePossiblePlayers';

const formValidation = new EditGameValidation();

const EditGameComponent: FC<{
  game: GameWithUserGamesDto;
  userList: SelectOptionsProps[];
  handleSubmitEditGame: (gameId?: string) => void;
  cancelButton?: { display: boolean; handleClick: () => void };
  reload?: () => void;
}> = ({ game, userList, handleSubmitEditGame, cancelButton, reload }) => {
  const { groupId } = useContext(GroupContext);
  const { t } = useTranslation();
  const { dd, mm, yyyy, hh, min } = dateFormatter(game.date);

  const [resetBetting, setResetBetting] = useState<boolean>(false);
  const [locations, setLocations] = useState<SelectOptionsProps[]>([]);
  const [userGames, setUserGames] = useState<UserGameRawDto[]>([]);
  // players with assigned positions
  const [players, setPlayers] = useState<SelectFieldDto[]>([]);
  // players available to play
  const [statusVoting, setStatusVoting] = useState<boolean>(
    game.statusVoting ?? false
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [responseMsg, setResponseMsg] = useState<{
    responseType: string;
    message: (string | undefined) | undefined;
    showMessage: boolean;
  }>();

  const [classNames, setClassNames] = useState<{
    team1Goals?: string;
    team2Goals?: string;
  }>({
    team1Goals: 'team1Goals',
    team2Goals: 'team2Goals',
  });

  const [edit, setEdit] = useState<{
    date: boolean;
    location: boolean;
  }>({ date: false, location: false });

  const playersTeam1 = players.filter((player) => player.team === Teams.TEAM1);
  const playersTeam2 = players.filter((player) => player.team === Teams.TEAM2);

  //////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const setPlayersFunction = () => {
      const players = getPlayersFromGame(game);
      setPlayers(players);
    };

    const getLocations = async () => {
      setIsLoading(true);
      const locationResponse = await Server.location.getLocations(groupId);
      const locationOptions = locationResponse.map((x, i) => {
        return { value: i.toString(), text: x.name };
      });
      setLocations([locationCreateDefault, ...locationOptions]);
      setIsLoading(false);
    };

    setPlayersFunction();
    getLocations();
  }, [game, groupId, userList]);

  ////////////////////////////////////////////////////////////////////////////////////////
  const handleChange = async (e: BaseSyntheticEvent) => {
    const name: keyof EditGameValidation = e.target.name;
    let value: number;
    value = parseInt(e.target.value);

    formValidation[name] = value;
    const validationResult = await validate(formValidation, {
      groups: [name],
    });
    if (validationResult.length) {
      setClassNames((prevVal) => ({ ...prevVal, [name]: `${name} error` }));
    } else {
      setClassNames((prevVal) => ({ ...prevVal, [name]: `${name}` }));
    }
    game[name] = value;
  };

  ///////////////////////////////////////////////////////////////////////////////////////
  const handleVoting = () => {
    setStatusVoting(!statusVoting);
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  const userGameHandler = (
    id: string | undefined,
    action: string,
    playerTeam?: Teams,
    playerRole?: PlayerType
  ) => {
    const userGameRaw: UserGameRawDto = {
      userId: id,
      gameId: game?.id,
      team: playerTeam,
      role: playerRole,
      action: action,
    };

    setUserGames([...userGames, userGameRaw]);
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  const handleEditGame = async () => {
    setIsLoading(true);
    if (validateSelectedPlayers(players)) {
      const currentUserGames = players.map((player) => {
        return {
          userId: player.id,
          gameId: game.id,
          team: player.team,
          role: player.role,
        };
      });

      const gameEdit: EditGameDto = {
        id: game?.id,
        date: game?.date,
        type: game?.type,
        team1Goals: game.team1Goals,
        team2Goals: game.team2Goals,
        userGames: userGames,
        groupId: game?.groupId,
        gameMVP: game?.gameMVP,
        status: game?.status!,
        statusVoting: statusVoting ?? false,
        resetBetting: resetBetting,
        locationName: game?.locationName,
      };

      const gameValidation = new GameValidation(gameEdit);
      const validationResult = await validate(gameValidation);
      if (validationResult.length) {
        alert(t('alerts.fill_all_fields'));
      } else {
        await Server.game.editGame(gameEdit);
        currentUserGames.map(
          async (userGame) => await Server.userGames.updateUserGame(userGame)
        );
        setResponseMsg({
          responseType: 'Success',
          message: t('input_fields.response_success_edit_game'),
          showMessage: true,
        });
        setIsLoading(false);
      }
    }
  };

  const handleDeleteGame = async () => {
    setIsLoading(true);
    const confirmAction = window.confirm(t('alerts.delete_game'));
    if (confirmAction) {
      const gameId = game.id;
      const response = await Server.game.deleteGame(gameId);
      setResponseMsg({
        responseType: 'Success',
        message: t('input_fields.response_success_delete_game'),
        showMessage: true,
      });
      setIsLoading(false);
      return response;
    } else {
      return;
    }
  };

  const handleResetBetting = async () => {
    setResetBetting(true);
    setIsLoading(true);
    const resetEdit: ResetBettingDto = {
      id: game?.id,
      groupId: game?.groupId,
      resetBetting: true,
    };
    const confirmAction = window.confirm(t('alerts.reset_betting'));
    resetEdit.resetBetting = confirmAction;
    await Server.game.resetBetting(resetEdit);
    setResponseMsg({
      responseType: 'Success',
      message: t('input_fields.response_success_edit_game'),
      showMessage: true,
    });
    setIsLoading(false);
  };

  const removePlayer = (id: string) => {
    userGameHandler(id, 'delete');
    const newTeam = players.filter((x) => x.id !== id);
    setPlayers(newTeam);
  };

  const handleEditClick = (id: string) => {
    removePlayer(id);
  };

  const handleSelectTeam = (
    selectedOption: {
      value: string;
      label: string;
      id: string | undefined;
    },
    team: Teams
  ) => {
    let allTeamIds;
    let allOtherIds;
    if (team === Teams.TEAM1) {
      allTeamIds = playersTeam1.map((item) => item.id);
      allOtherIds = playersTeam2.map((item) => item.id);
    } else {
      allTeamIds = playersTeam2.map((item) => item.id);
      allOtherIds = playersTeam1.map((item) => item.id);
    }
    if (!allTeamIds.includes(selectedOption.id)) {
      if (allOtherIds.includes(selectedOption.id)) {
        return;
      }
      const option = {
        name: selectedOption.label,
        id: selectedOption.id,
        team: team,
        role: PlayerType.PLAYER,
      };
      setPlayers([...players, option]);
      userGameHandler(option.id, 'create', option.team, option.role);
    } else {
      const newSelectedPlayers = players.filter(
        (p) => p.id !== selectedOption.id
      );
      selectedOption.id && removePlayer(selectedOption.id);
      setPlayers(newSelectedPlayers);
    }
  };

  const isSelectedInTeam = (id?: string, team?: Teams.TEAM1 | Teams.TEAM2) => {
    if (team) {
      let allSelectedIds;
      if (team === Teams.TEAM1) {
        allSelectedIds = playersTeam1.map((item) => item.id);
      } else {
        allSelectedIds = playersTeam2.map((item) => item.id);
      }
      if (id && allSelectedIds.includes(id)) {
        return true;
      } else return false;
    }
  };

  return (
    <>
      {!responseMsg?.showMessage ? (
        <div className="settings-tab-container">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1 className="sidebar-link regular-font pt-2">
              {t('titles.conclude')}
            </h1>
            <div className="d-flex">
              <ButtonMain
                className="btn btn-primary mt-2 bg-danger text-white"
                icon="bi bi-trash-fill"
                onClick={() => handleDeleteGame()}
                disabled={isLoading}
              />
            </div>
          </div>
          <RowContainer>
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                {!edit.date ? (
                  <div className="bg-main-backround game-date px-2 py-1 rounded border border-dark shadow">
                    <i className="bi bi-clock px-2"></i>
                    {dd}.{mm}.{yyyy} {hh}:{min}
                    <button
                      onClick={() => setEdit({ date: true, location: false })}
                      className="btn btn-sm reset-btn"
                    >
                      <i className={`bi bi-pencil-square`}></i>
                    </button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    <InputField
                      label={''}
                      defaultValue={game.date}
                      type="datetime-local"
                      name="date"
                      onChange={(e) => (game.date = e.target.value)}
                    />
                  </div>
                )}
                <div
                  className={`px-2 rounded border border-dark shadow game-type game-${game.type} d-flex align-items-center`}
                >
                  {game?.type}
                </div>
              </div>
              {!edit.location ? (
                <div className="bg-main-backround my-1 px-3 py-1 rounded border border-dark shadow">
                  <i className="bi bi-house"></i>
                  <span className="game-type mx-2">
                    {game?.location?.name}
                  </span>{' '}
                  {game?.location?.address}
                  <button
                    onClick={() => setEdit({ date: false, location: true })}
                    className="btn btn-sm reset-btn"
                  >
                    <i className={`bi bi-pencil-square`}></i>
                  </button>
                </div>
              ) : (
                <div className="d-flex justify-content-between">
                  <SelectField
                    label={''}
                    options={locations}
                    eventHandler={(e: any) => {
                      game.locationName =
                        locations[e.target.options.selectedIndex].text;
                    }}
                  ></SelectField>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-md-5">
                {playersTeam1.length ? (
                  <div className="d-flex">
                    <h2 className="sidebar-link regular-font text-center pt-2">
                      {t('teams.team')} {t('teams.team_1_color')}
                    </h2>
                  </div>
                ) : (
                  ''
                )}
                <Team1List
                  players={playersTeam1}
                  icon={'bi-pencil-square'}
                  onClick={(e) => handleEditClick(e)}
                />
              </div>
              <div className="col-md-5">
                {playersTeam2.length ? (
                  <div className="d-flex">
                    <h2 className="sidebar-link regular-font text-center pt-2">
                      {t('teams.team')} {t('teams.team_2_color')}
                    </h2>
                  </div>
                ) : (
                  ''
                )}
                <Team2List
                  players={playersTeam2}
                  icon={'bi-pencil-square'}
                  onClick={(e) => handleEditClick(e)}
                />
              </div>
            </div>
            <div className="player_select my-3">
              <div className="col-md-5 mb-2">
                <ChoosePossiblePlayers
                  title={`${t('teams.team')} ${t('teams.team_1_color')}`}
                  userList={userList}
                  handleSelectByTeam={handleSelectTeam}
                  team={Teams.TEAM1}
                  isSelectedInTeam={isSelectedInTeam}
                />
              </div>
              <div className="col-md-5">
                <ChoosePossiblePlayers
                  title={`${t('teams.team')} ${t('teams.team_2_color')}`}
                  userList={userList}
                  handleSelectByTeam={handleSelectTeam}
                  team={Teams.TEAM2}
                  isSelectedInTeam={isSelectedInTeam}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between gap-4 mt-2">
              <div className="col-md-2">
                <InputField
                  type="number"
                  name="team1Goals"
                  className={classNames.team1Goals}
                  defaultValue={game.team1Goals?.toString()}
                  label={`${t('teams.team_result')} ${t('teams.team_1_color')}`}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-md-2">
                <InputField
                  type="number"
                  name="team2Goals"
                  className={classNames.team2Goals}
                  defaultValue={game.team2Goals?.toString()}
                  label={`${t('teams.team_result')} ${t('teams.team_2_color')}`}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="col-md-12 d-flex flex-column">
              <div className="d-flex flex-column bg-main-backround p-3 rounded">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={statusVoting}
                    onChange={handleVoting}
                  />
                  <label className="form-check-label">
                    {t('input_fields.voting')} (MVP)
                  </label>
                </div>
                <div className="mt-2">
                  <ButtonMain
                    className="border-1 border-white bg-component-background text-white mt-2"
                    icon=""
                    text={t('input_fields.resetBets')}
                    onClick={handleResetBetting}
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="">
                  {cancelButton?.display && (
                    <div className="p-2">
                      <ButtonMain
                        className="btn btn-primary d-flex mt-4"
                        icon=""
                        text={t('input_fields.button_cancel')}
                        onClick={() => cancelButton.handleClick()}
                      />
                    </div>
                  )}
                </div>
                <div className="">
                  <ButtonMain
                    className="btn btn-primary d-flex mt-4"
                    icon=""
                    text={t('input_fields.button_submit')}
                    onClick={handleEditGame}
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
          </RowContainer>
        </div>
      ) : (
        <ActionMessage
          message={responseMsg.message}
          type={'success'}
          onClick={() => {
            setResponseMsg((prevState) => ({
              ...prevState!,
              showMessage: false,
            }));
            if (game.team1Goals !== null && game.team2Goals !== null) {
              handleSubmitEditGame(game.id);
            }
            if (reload) {
              reload();
            }
          }}
        />
      )}
    </>
  );
};

export default EditGameComponent;
