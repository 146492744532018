import { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import ButtonMain from '../../../components/buttons/button.component';
import ActionMessage from '../../../components/action-message/action-message.component';
import RowContainer from '../../../container/row-container/row.container';
import { GroupDto } from '../../../types/dtos.types';
import { Server } from '../../../server/server';
import { GroupContext } from '../../../App';
import { modules } from '../../../constants/variables/quill-modules.constant';
import { t } from 'i18next';

const AddRuleBook = () => {
  const [group, setGroup] = useState<GroupDto>();
  const [isLoading, setIsLoading] = useState(true);
  const { groupId } = useContext(GroupContext);

  const [responseMsg, setResponseMsg] = useState<{
    responseType: string;
    message: (string | undefined)[] | undefined;
    showMessage: boolean;
  }>();

  /////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const ruleBookFetch = async () => {
      setIsLoading(true);
      const groupResponse = await Server.group.getGroup(groupId);
      setGroup(groupResponse);
      setIsLoading(false);
    };
    ruleBookFetch();
  }, [groupId]);

  /////////////////////////////////////////////////////////////////////////////////////////
  const saveRuleBook = async () => {
    setIsLoading(true);
    if (group) {
      const setGroupRulesResponse = await Server.group.setGroupRules(
        group.id,
        group.groupRules
      );
      setResponseMsg({
        responseType: 'success',
        message: [''],
        showMessage: true,
      });
      return setGroupRulesResponse;
    }
    setIsLoading(false);
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const handleResponseMsgSubmit = () => {
    setResponseMsg((prevState) => ({
      ...prevState!,
      showMessage: false,
    }));
  };

  return (
    <>
      {!isLoading && (
        <div className="settings-tab-container">
          {!responseMsg?.showMessage ? (
            <RowContainer>
              <div className="col-md-12">
                <h2 className="sidebar-link regular-font text-center p-3">
                  {t('titles.edit_rulebook')}
                </h2>
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  defaultValue={group?.groupRules}
                  onChange={(e) =>
                    setGroup((prevState) => ({ ...prevState!, groupRules: e }))
                  }
                />
                <ButtonMain
                  className="btn btn-primary d-flex mt-3"
                  text={t('input_fields.button_submit')}
                  onClick={(e) => saveRuleBook()}
                  disabled={isLoading}
                />
              </div>
            </RowContainer>
          ) : (
            <ActionMessage
              message={t('input_fields.response_success_change')}
              type={'success'}
              onClick={() => handleResponseMsgSubmit()}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AddRuleBook;
