import { useContext, useEffect, useState } from 'react';
import FluidContainer from '../../container/fluid-container/fluid.container';
import ButtonDropdown from '../buttons/dropdown/button-dropdown.component';
import SelectField from '../inputs/select.component';
import { Server } from '../../server/server';
import { getLoggedUser } from '../../utilities/local-storage/get-logged-user';
import { SelectGroupOptionsProps } from '../../types/props.types';
import { GroupContext } from '../../App';
import RowContainer from '../../container/row-container/row.container';
import './header-menu.component.css';

const HeaderMenu = () => {
  const { setGroupId } = useContext(GroupContext);
  const user = getLoggedUser();
  const [groupsFromUser, setGroupsFromUser] = useState<
    SelectGroupOptionsProps[]
  >([]);
  const [groupCount, setGroupCount] = useState(0);

  useEffect(() => {
    const fetchGroups = async () => {
      const groupsResponse = await Server.userGroup.getAll(user.id);
      const groupOptions = groupsResponse.map((x, i) => {
        return { value: i.toString(), text: x.group?.name, id: x.groupId };
      });
      setGroupsFromUser([...groupOptions]);
      setGroupCount(groupOptions.length);
    };
    fetchGroups();
  }, [user.id]);

  const selectGroup = (e: number) => {
    const groupId = groupsFromUser[e].id;
    localStorage.setItem('selectedGroup', JSON.stringify(groupId));
    setGroupId(groupId);
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      {groupCount === 0 ? (
        <></>
      ) : groupCount === 1 ? (
        <RowContainer>
          <div className="game-type mx-3 my-2 py-2 px-3 rounded border border-dark shadow game-type">
            {groupsFromUser[0].text}
          </div>
        </RowContainer>
      ) : (
        <SelectField
          className="mt-2 w-50"
          label={''}
          options={groupsFromUser}
          multiple={false}
          eventHandler={(e) => selectGroup(e.target.options.selectedIndex)}
        ></SelectField>
      )}

      <FluidContainer className="d-flex p-0 align-items-center gap-2">
        <div className="p-2 flex-grow-1">{/*  <LanguageSwitcher /> */}</div>
        <ButtonDropdown />
      </FluidContainer>
    </div>
  );
};

export default HeaderMenu;
