import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Server } from '../../../server/server';
import { BidGameDtoRes, GameWithUserGamesDto } from '../../../types/dtos.types';
import { getLoggedUser } from '../../../utilities/local-storage/get-logged-user';
import { BiddingChoice } from '../../../types/enums.types';

interface BettingResultsProps {
  game: GameWithUserGamesDto;
  results: BidGameDtoRes;
  sum: number;
}

const BettingResults = (props: BettingResultsProps) => {
  const { t } = useTranslation();
  const { game, results, sum } = props;
  const [biddedOn, setBiddedOn] = useState<BiddingChoice>();
  const user = getLoggedUser();
  /////////////////////////////////////////////////////////////////////////////////////////
  const didYouBid = useCallback(async () => {
    const userGame = {
      userId: user.id,
      gameId: game.id,
    };
    const res = await Server.bidGame.getUserBidGame(userGame);
    setBiddedOn(res.userChoice);
  }, [game.id, user.id]);

  useEffect(() => {
    didYouBid();
  }, [game, didYouBid]);

  return (
    <div>
      <div className="d-flex flex-column justify-content-between ">
        <div className="d-flex flex-row justify-content-between ">
          <div className="d-flex flex-column align-items-center">
            <button
              className={`btn btn-bet ${
                biddedOn === BiddingChoice.FIRST && 'selected'
              }`}
              disabled
            >
              <span>{results.firstTeam}%</span>
            </button>
          </div>
          <div className="d-flex flex-column align-items-center">
            <button
              className={`btn btn-bet ${
                biddedOn === BiddingChoice.DRAW && 'selected'
              }`}
              disabled
            >
              <span>{results.draw}%</span>
            </button>
          </div>
          <div className="d-flex flex-column align-items-center">
            <button
              className={`btn btn-bet ${
                biddedOn === BiddingChoice.SECOND && 'selected'
              }`}
              disabled
            >
              <span>{results.secondTeam}%</span>
            </button>
          </div>
        </div>
        <div className="text-center p-2 mt-1 mb-4 rounded border border-dark shadow bg-main-backround">
          {`${t('dashboard.totalBets')}${sum}`}
        </div>
      </div>
    </div>
  );
};
export default BettingResults;
