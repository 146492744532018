import { useState } from 'react';
import { UserWithStatsDto } from '../../../../types/dtos.types';
import ButtonSecondary from '../../../../components/buttons/button-secondary.component';
import { useTranslation } from 'react-i18next';

interface TableHeaderProps {
  setUsers: (e: any) => void;
  users: UserWithStatsDto[];
}

const TableHeader = (props: TableHeaderProps) => {
  const { t } = useTranslation();
  const { setUsers, users } = props;
  const [toggler, setToggler] = useState<{ state: Boolean; type: string }>({
    state: true,
    type: 'gamesWon-false',
  });

  const sortGames = (
    type: keyof Pick<
      UserWithStatsDto,
      'gamesWon' | 'gamesCageBall' | 'gamesFutsal' | 'name' | 'betting'
    >
  ) => {
    const usersSorted = [
      ...users.sort((a, b) => {
        //Toggler switching sorting desc-asc
        if (!toggler.state) {
          const temp = a;
          a = b;
          b = temp;
        }
        ///////////////////////////////
        if (type !== 'name') {
          return a[type] - b[type];
        }
        if (type === 'name') {
          return a[type].localeCompare(b[type]);
        }
        return 0;
      }),
    ];
    setToggler({ state: !toggler.state, type: `${type}-${toggler.state}` });
    setUsers(usersSorted);
  };

  return (
    <thead>
      <tr>
        <th scope="col">
          <ButtonSecondary
            onClick={() => sortGames('name')}
            text={t('players.player')}
            className="regular-font"
            icon={
              toggler.type.includes('name')
                ? toggler.type === 'name-true'
                  ? 'arrow-down'
                  : 'arrow-up'
                : 'sort-alpha-down'
            }
          />
        </th>
        <th scope="col">
          <ButtonSecondary
            onClick={() => sortGames('gamesWon')}
            text={t('players.all_games')}
            className="regular-font"
            icon={
              toggler.type.includes('gamesWon')
                ? toggler.type === 'gamesWon-true'
                  ? 'arrow-up'
                  : 'arrow-down'
                : 'sort-numeric-down-alt'
            }
          />
        </th>
        <th scope="col">
          <ButtonSecondary
            onClick={() => sortGames('gamesFutsal')}
            text={t('players.futsal')}
            className="regular-font"
            icon={
              toggler.type.includes('gamesFutsal')
                ? toggler.type === 'gamesFutsal-true'
                  ? 'arrow-up'
                  : 'arrow-down'
                : 'sort-numeric-down-alt'
            }
          />
        </th>
        <th scope="col">
          <ButtonSecondary
            onClick={() => sortGames('gamesCageBall')}
            text={t('players.cageball')}
            className="regular-font"
            icon={
              toggler.type.includes('gamesCageBall')
                ? toggler.type === 'gamesCageBall-true'
                  ? 'arrow-up'
                  : 'arrow-down'
                : 'sort-numeric-down-alt'
            }
          />
        </th>
        <th scope="col">
          <ButtonSecondary
            onClick={() => sortGames('betting')}
            text={t('players.betting')}
            className="regular-font"
            icon={
              toggler.type.includes('betting')
                ? toggler.type === 'betting-true'
                  ? 'arrow-up'
                  : 'arrow-down'
                : 'sort-numeric-down-alt'
            }
          />
        </th>
        <th></th>
      </tr>
    </thead>
  );
};

export default TableHeader;
