import { useState } from 'react';
import { gameTypeDefault } from '../../../constants/variables/game-filter-defaults.constant';
import { OPTIONS_COLLAB } from '../../../constants/variables/options_collab.constant';
import FluidContainer from '../../../container/fluid-container/fluid.container';
import RowContainer from '../../../container/row-container/row.container';
import { GameFilterDto, gameFilterStateDto } from '../../../types/dtos.types';
import ButtonSecondary from '../../../components/buttons/button-secondary.component';
import ButtonMain from '../../../components/buttons/button.component';
import InputField from '../../../components/inputs/input-field.component';
import SelectField from '../../../components/inputs/select.component';
import { SelectOptionsProps } from '../../../types/props.types';
import { Server } from '../../../server/server';
import { gameFilterDefault } from '../../../constants/variables/game-filter-default';
import { gameFilterCalc } from '../../../utilities/data/game-filter-variable/game-filter-calc';
import { useTranslation } from 'react-i18next';
import ChoosePossiblePlayers from '../../../components/possible-players/ChoosePossiblePlayers';
import { Teams } from '../../../types/enums.types';

interface GameFilterProps {
  gamesLoading: boolean;
  setGamesLoading: (e: any) => void;
  gameFilter: GameFilterDto;
  setGameFilter: (e: GameFilterDto) => void;
}

const GameFilter = (props: GameFilterProps) => {
  const { t } = useTranslation();

  const [filterState, setFilterState] = useState<{
    isLoading: boolean;
    display: boolean;
  }>({ isLoading: false, display: false });

  const [filteringOptions, setFilteringOptions] = useState<{
    userList: SelectOptionsProps[];
    gameTypes: SelectOptionsProps[];
  }>();

  const [gameFilterState, setGameFilterState] =
    useState<gameFilterStateDto>(gameFilterDefault);

  /////////////////////////////////////////////////////////////////////////////////////////
  const displayFilter = async () => {
    setFilterState({ isLoading: true, display: false });
    try {
      const gameTypeResponse = await Server.game.getGameTypes();
      const userListResponse = await Server.user.getAll();
      const gameTypeOptions = gameTypeResponse.map((x, i) => {
        return { value: i.toString(), text: x.type };
      });
      const userListOptions = userListResponse.map((x, i) => {
        return { value: i.toString(), text: x.name, id: x.id };
      });
      setFilteringOptions({
        userList: [...userListOptions],
        gameTypes: [gameTypeDefault, ...gameTypeOptions],
      });
      setFilterState({ isLoading: false, display: !filterState.display });
    } catch (err) {
      alert(t('alerts.fetch_data_error'));
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const resetInputs = () => {
    props.setGameFilter({});
    setGameFilterState(gameFilterDefault);
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const filterHandler = (e: any) => {
    e.preventDefault();

    const gameFilter = gameFilterCalc(gameFilterState, filteringOptions);
    props.setGameFilter(gameFilter);
    setFilterState((prevState) => ({ ...prevState, display: false }));
  };

  const handleSelectByTeam = (
    selectedOption: { value: string; label: string; id?: string },
    team?: Teams.TEAM1 | Teams.TEAM2
  ) => {
    if (team && team === Teams.TEAM1) {
      setGameFilterState((prevState) => ({
        ...prevState!,
        player1Id: selectedOption.id,
      }));
    } else if (team && team === Teams.TEAM2) {
      setGameFilterState((prevState) => ({
        ...prevState!,
        player2Id: selectedOption.id,
      }));
    }
  };

  const isSelectedInTeam = (id?: string, team?: Teams.TEAM1 | Teams.TEAM2) => {
    if (team && team === Teams.TEAM1) {
      if (id && id === gameFilterState.player1Id) {
        return true;
      } else return false;
    } else if (team && team === Teams.TEAM2) {
      if (id && id === gameFilterState.player2Id) {
        return true;
      } else return false;
    }
  };

  return (
    <div>
      <FluidContainer>
        <div className="col-md-12 ">
          <ButtonSecondary
            className="d-flex  my-2 regular-font"
            text={t('games.filter_title')}
            icon="funnel-fill"
            onClick={displayFilter}
          ></ButtonSecondary>
        </div>
        <RowContainer
          className={`bg-component-background filters p-3 mb-3 ${
            filterState.display ? 'd-flex' : 'd-none '
          }`}
        >
          <div className="col-md-6 mb-3">
            {filteringOptions?.userList && (
              <ChoosePossiblePlayers
                title={t('input_fields.player_1')}
                userList={filteringOptions.userList}
                handleSelectByTeam={handleSelectByTeam}
                isSelectedInTeam={isSelectedInTeam}
                team={Teams.TEAM1}
              />
            )}
          </div>
          <div className="col-md-6 mb-3">
            {filteringOptions?.userList && (
              <ChoosePossiblePlayers
                title={t('input_fields.player_2')}
                userList={filteringOptions.userList}
                handleSelectByTeam={handleSelectByTeam}
                isSelectedInTeam={isSelectedInTeam}
                team={Teams.TEAM2}
              />
            )}
          </div>
          <form onReset={resetInputs} onSubmit={filterHandler} className="row">
            <div className="col-md-4">
              <InputField
                label={t('input_fields.from')}
                type="date"
                name="date-from"
                defaultValue={
                  new Date(
                    new Date().getFullYear() - 3,
                    new Date().getMonth(),
                    new Date().getDate()
                  )
                    .toISOString()
                    .split('T')[0]
                }
                onChange={(e) =>
                  setGameFilterState((prevState) => ({
                    ...prevState,
                    date: e.target.value,
                  }))
                }
              />
            </div>
            <div className="col-md-4">
              <InputField
                label={t('input_fields.to')}
                type="date"
                name="date-to"
                defaultValue={new Date().toISOString().split('T')[0]}
                onChange={(e) =>
                  setGameFilterState((prevState) => ({
                    ...prevState,
                    dateTo: e.target.value,
                  }))
                }
              />
            </div>
            <div className="col-md-4">
              {filteringOptions?.gameTypes && (
                <SelectField
                  label={t('input_fields.game_type')}
                  placeholder="Please Select"
                  options={filteringOptions.gameTypes}
                  eventHandler={(e) =>
                    setGameFilterState((prevState) => ({
                      ...prevState,
                      typeEvent: e.target.options.selectedIndex,
                    }))
                  }
                ></SelectField>
              )}
            </div>
            <div className="col-md-4">
              <SelectField
                label={t('input_fields.played_as')}
                options={OPTIONS_COLLAB}
                eventHandler={(e) =>
                  setGameFilterState((prevState) => ({
                    ...prevState!,
                    playerRelation: e.target.options.selectedIndex,
                  }))
                }
              ></SelectField>
            </div>
            <div className="filter_buttons col-md-12 d-flex gap-2">
              <ButtonMain
                icon="funnel-fill"
                text={t('games.filter')}
                className="btn btn-primary p-2 d-flex gap-2"
                type="submit"
                disabled={props.gamesLoading}
              />
              <ButtonMain
                icon="x-circle-fill"
                text={t('games.remove_filters')}
                className="btn btn-secondary border p-2 d-flex gap-2"
                type="reset"
                disabled={props.gamesLoading}
              />
            </div>
          </form>
        </RowContainer>
      </FluidContainer>
    </div>
  );
};
export default GameFilter;
