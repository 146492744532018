import { useMemo } from 'react';
import { ROLE_ADMIN } from '../constants/variables/user-roles.constants';
import { getLoggedUser } from '../utilities/local-storage/get-logged-user';

export const useCheckAdmin = (groupId: string) => {
  const user = getLoggedUser();
  const isAdmin = useMemo(() => {
    return user?.userGroups?.some((x) => {
      if (x.groupId === groupId && x.role === ROLE_ADMIN) {
        return true;
      } else {
        return false;
      }
    });
  }, [groupId, user]);
  return isAdmin;
};

export const useCheckSuperadmin = () => {
  const user = getLoggedUser();
  const isSuperadmin = user?.isSuperadmin;
  return isSuperadmin;
};
