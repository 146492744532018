import { BaseSyntheticEvent } from 'react';
import InputField from '../../../components/inputs/input-field.component';
import { useTranslation } from 'react-i18next';

interface LoginFormProps {
  setState: (e: any) => void;
}

const LoginForm = (props: LoginFormProps) => {
  const { t } = useTranslation();
  const onChange = (e: BaseSyntheticEvent) => {
    const name = e.target.name;
    const value = e.target.value;
    props.setState((x: any) => {
      return { ...x, [name]: value };
    });
  };

  return (
    <>
      <InputField
        type="text"
        name="email"
        label={t('login.email_label')}
        onChange={onChange}
        icon="envelope-fill"
      />
      <InputField
        type="password"
        name="password"
        label={t('login.password_label')}
        onChange={onChange}
        icon="shield-lock-fill"
      />
    </>
  );
};

export default LoginForm;
