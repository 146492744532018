import { FC } from 'react';
import { FCProps } from '../../types/props.types';

const RowContainer: FC<FCProps> = ({ children, ...props }) => {
  const { className } = props;

  return <div className={`row ${className}`}>{children}</div>;
};

export default RowContainer;
